<template>
    <div>
        <md-dialog :md-active.sync="InactivaItem" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                <!-- <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="deleteCuenta(item.id)"> -->
                    <md-icon style="background-color: #cd2838; color: white; width: 35px; height: 35px; border-radius: 4px;">warning</md-icon>
                <!-- </md-button> -->
            </md-dialog-title>
            <md-dialog-content>
                    <h5>{{ labels.texto }}</h5>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="confirmDelete">{{ labels.aceptar }}</md-button>
                    <md-button class="md-accent" @click="cancelDelete">{{ labels.cancelar }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'DialogConfirm',
    props: ['InactivaItem','borraReg'],
    data: () => ({
      
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Inactivar Registro",
                    texto : "¿Seguro desea eliminar este registro?",
                    aceptar : "Aceptar",
                    cancelar : "Cancelar"
                }
            }else{
                return {
                    title: "Inactivate Row",
                    texto : "¿Surely you want to delete this record?",
                    aceptar : "Accept",
                    cancelar : "Cancel"
                }
            }
        }
    },
    methods: {
        cancelDelete(){
            this.$emit('cierraModal', false);
        },
        confirmDelete(){
            this.$emit('confirmDelete');
        }
    }
}
</script>
