import Api from '@/services/Api'

export default {
    getAllSubMenu(){
        return Api().get('submenu')
    },
    subMenuById(id){
        return Api().get('submenu/subMenuById/'+id)
    },
    subMenuByIdMenu(id){
        return Api().get('submenu/subMenuByIdMenu/'+id)
    },
    subMenuByMenuByRol(idMenu,idRol){
        return Api().get('submenu/subMenuByMenuByRol/'+idMenu+'/'+idRol)
    },
    createSubmenu(dataForm){
        return Api().post('submenu/create',dataForm)
    },
    updateSubmenu(dataForm){
        return Api().put('submenu/update',dataForm)
    },
    inactivaSubmenu(dataForm){
        return Api().put('submenu/inactiva',dataForm)
    }
}