<template>
    <div class="container">
        <md-dialog :md-active.sync="showNewSegundoLev" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <h5>{{ tituloNivUno(levelUnoSel) }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="number"
                                name="name"
                                v-model="levelSecond"
                                :placeholder="labels.levelSecond"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="name"
                                v-model="name"
                                :placeholder="labels.name"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="newLevelSecond">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>

import SegundoNivel from "../../services/SegundoNivel"
import PrimerNivel from "../../services/PrimerNivel"

export default {
    name:"NewSecondLevel",
    props: ['showNewSegundoLev','levelUnoSel','CodigoReport'],

    data: () => ({
        name:"",
        levelSecond:"",
        nameFirstLevel:""
    }),

    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Crear nivel",
                    save : "Guardar",
                    close : "Cerrar",
                    name : "Nombre",
                    levelSecond : "Segundo nivel (Números*)"
                }
            }else{
                return {
                    title : "Create level",
                    save : "Save",
                    close : "Close",
                    name : "Name",
                    levelSecond : "levelSecond (Number*)"
                }
            }
        }
    },

    methods: {
        clicked(){
            this.$emit('clickedSegundo',this.levelUnoSel,this.CodigoReport,false)
            this.name = ""
            this.levelSecond = ""
            this.levelThird = ""
        },
        newLevelSecond(){
            // console.log(this.levelUnoSel)
            if(this.name == ""){
                this.$toast.error("El campo Nombre debe estar diligenciado")
            
            }else if(this.levelSecond == ""){
                this.$toast.error("El campo Segundo nivel debe estar diligenciado")

            }else{

                let form = {
                    'primer_nivel'  : this.levelUnoSel,
                    'segundo_nivel' : this.levelSecond,
                    'descripcion'   : this.name,
                    'codReporte'    : this.CodigoReport,
                    'idUser'        : this.$store.state.userId,
                }
                // console.log(form)

                SegundoNivel.createSecondLevel(form)
                .then((res) => {
                    // console.log(res)
                    this.$toast.success(res.data.message)
                    this.clicked()
                })
                .catch((err) => {
                    this.$toast.error(err.message)
                    console.log(err)
                })
            }
        },
	tituloNivUno(nivUno){

		if	(nivUno.length) {
			PrimerNivel.getFirstLevelById(nivUno)
			.then((res) => {
				this.nameFirstLevel = res.data.firstLevelById[0].descripcion
			})
			.catch((error) => {
				console.log(error)
			})
		} else {
			this.nameFirstLevel = ""
		}
		return this.nameFirstLevel
	}
    }
}

</script>
<style src="../main.css"></style>
