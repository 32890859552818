<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center">
                    {{labels.title}}
                </h2>
            </b-container>
        </b-row>
        <b-row v-if="!isLoading">
            <b-container>
                <div class="col-md-12 d-flex justify-content-end">
                    <td>
                        <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="crearMenu()">
                            <md-icon>add</md-icon>
                        </md-button>
                    </td>
                </div>
                <div class="col-md-12">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <th scope="col">{{ labels.nombre }}</th>
                            <th scope="col">{{ labels.ruta }}</th>
                            <th scope="col">{{ labels.icono }}</th>
                            <th scope="col">{{ labels.orden }}</th>
                            <th scope="col">{{ labels.edit }}</th>
                            <th scope="col">{{ labels.rol }}</th>
                        </thead>
                        <tbody>
                            <tr v-for="menu in listMenus" :key="menu.id">
                                <td>{{ menu.nombre }}</td>
                                <td>{{ menu.ruta }}</td>
                                <td> {{ menu.icono }}</td>
                                <td> {{ menu.orden }}</td>
                                <td>
                                    <md-button @click="editMenu(menu.id)" style="width: 30px; height: 30px;" class = "md-raised md-primary btn-editt">
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                </td>
                                <td>
                                    <md-button style="background-color: #912710; width: 30px; height: 30px;" class="md-fab md-primary" @click="listarRoles(menu.id)">
                                        <md-icon><font-awesome-icon :icon="['fas', 'user']" /></md-icon>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-container>
        </b-row>
        <Crear :modCrearMenu="modCrearMenu" @closeModal="closeModal"/>
        <Editar :modEditarMenu="modEditarMenu" :infoMenu="datosMenu" @closeModal="closeModal"></Editar>
        <AsociaDesasociaRol :modAsociaDesasociaRol="modalRoles" :infoMenu="datosMenu" @closeModal="closeModal"/>
    </div>
</template>
<script>
import Menu from '../../../services/Menu';
import Crear from './Crear.vue';
import Editar from './EditarMenu.vue';
import AsociaDesasociaRol from './AsociaDesasociaRol.vue';

export default {
    name:'AdministradorMenu',
    components:{
        Crear,
        Editar,
        AsociaDesasociaRol
    },
    data: () => ({
        isLoading: false,
        listMenus : [],
        modCrearMenu: false,
        modEditarMenu: false,
        modalRoles: false,
        datosMenu: []
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Menús",
                    nombre : "Nombre",
                    ruta : "Url",
                    icono : "Icono",
                    edit : "Editar",
                    rol : "Roles",
                    orden : "Orden",
                }
            }else{
                return {
                    title : "Menu",
                    nombre : "Name",
                    ruta : "Url",
                    icono : "Icon",
                    edit : "Edit",
                    rol : "Role",
                    orden : "Order",
                }
            }
        }
    },
    mounted(){
        Menu.getAllMenu()
        .then((res) => {
            // console.log(res.data)
            this.listMenus = res.data.listMenu
        })
        .catch((err) => {
            console.log(err)
        })
    },
    methods:{
        closeModal(val){
            this.modCrearMenu = val
            this.modEditarMenu = val
            this.modalRoles = val
        },
        crearMenu(){
            this.modCrearMenu = true
        },
        editMenu(idMenu){
            // console.log(idMenu)
            Menu.getMenuById(idMenu)
            .then((res) => {
                // console.log(res.data.menu)
                this.datosMenu = res.data.menu
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.modEditarMenu = true
            })
        },
        listarRoles(idMenu){
            // console.log("LISTAR: ",idMenu)
            Menu.getMenuById(idMenu)
            .then((res) => {
                // console.log(res.data.menu)
                this.datosMenu = res.data.menu
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.modalRoles = true
            })
        }
    }
}
</script>

<style>

</style>
