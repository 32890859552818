<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ['barData'],
  mounted() {
    this.renderChart(
      {
        labels: Object.keys(this.barData),
        datasets: [
          {
            label: "Facturas por Aprobador",
            backgroundColor: "#f87979",
            data: Object.values(this.barData)
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false, type: 'horizontalBar'}
    );
  }
};
</script>
