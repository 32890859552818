<template>
    <div class="container">
        <div class="col-md-12">
            <h1>{{ labels.title }}</h1>
        </div>
        <div class="col-md-12">
            <h4><!--{{ labels.levelFirst }}--> {{ nameFirstLevel }}</h4>
        </div>
        <div class="row justify-content-center mt-5" v-if="datosPaginados.length == 0">
            <h3> {{ labels.noData }} </h3>
            <div class="col-md-12 mt-4">
                <b-button @click="goToCreateLevelSecond()" id="create-btn">{{labels.create}}</b-button>
            </div>
        </div>
        <div v-else>
            <div class="col-md-12 mt-4">
                <b-button @click="goToCreateLevelSecond()" id="create-btn">{{labels.create}}</b-button>
            </div>
            <div class="row justify-content-left">
                <div class="col-md-3">
                    <md-field md-inline>
                    <label>{{labels.levelSecond}}</label>
                    <md-input v-model="searchSecondLevel" @input="searchOnTableSecondLevel"></md-input>
                    </md-field>
                </div>
            </div>
            <div class="row col-md-12 justify-content-center">
                <div class="col-md-8 mt-4">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">{{labels.levelFirst}}</th>
                                <th scope="col">{{labels.levelSecond}}</th>
                                <th scope="col">{{labels.desLevelSecond}}</th>
                                <th scope="col">{{labels.editar}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="secondLevel in datosPaginados" :key="secondLevel.id">
                                <td>{{ secondLevel.primer_nivel }}</td>
                                <td>{{ secondLevel.segundo_nivel }}</td>
                                <td>{{ secondLevel.descripcion }}</td>
                            <td>
                                <md-button @click="editNivel2(secondLevel.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">{{ labels.previous }}</a></li>
                            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">{{labels.next}}</a></li>
                        </ul>
                    </nav>
                </div>
                <!-- <div class="col-md-6">
                    <NivelReporte :listaNivelReporte="itemSelected"/>
                </div> -->
            </div>
        </div>
        <md-dialog :md-active.sync="showDialogEdit">
            <md-dialog-title class="text-center"><h3><b><i>{{labels.informacion}}</i></b></h3></md-dialog-title>
            <md-dialog-content>
                <b-form-group @submit.prevent="editaInfoNivel">
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelFirst}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.des_primer_nivel" type="text" readonly ></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelSecond}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.segundo_nivel" type="text" readonly ></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.desLevelSecond}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.descripcion" type="text"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4"></b-col>
                    <b-col cols="8" class="text-left">
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="editaInfoNivel2()">Guardar</md-button>
                            <md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
                        </md-dialog-actions>
                    </b-col>
                </b-row>
                </b-form-group>
            </md-dialog-content>
        </md-dialog>
        <NewLevelSecond :showDialog="showDialog" @clicked="closeModal" :levelSel="levelFirsrt"></NewLevelSecond>
    </div>
</template>
<script>

    import SegundoNivel from "../../services/SegundoNivel"
    import PrimerNivel from "../../services/PrimerNivel"
    import PlanConsolidacion from "../../services/PlanConsolidacion"
    import NewLevelSecond from "./NewLevelSecond.vue"
    // import NivelReporte from "./NivelReporte.vue"

    const toLower = (text) => {
        return text.toString().toLowerCase();
    };

    const searchBySecondLevel = (items, term) => {
        if (term) {
            return items.filter((item) =>
                toLower(item.descripcion).includes(toLower(term))
            );
        }
        return items;
    };

    export default {
        name: "SegundoNivel",
        props: ['First'],
        components: {
            NewLevelSecond
        },

data: () => ({
	listaSegundoNivel : [],
	searchSecondLevel : null,
	elementosPorPagina:10,
	datosPaginados: [],
	paginaActual: 1,
	nameFirstLevel: "",
	itemSelected : [],
	showDialog: false,
	showDialogEdit: false,
	levelFirsrt : "",
	formEdit: {
		id: '',
		primer_nivel: '',
		des_primer_nivel: '',
		segundo_nivel: '',
		descripcion: ''
	},
}),

computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title : "Esquema de Consolidación",
                        informacion : "Modificación",
                        subtitle : "A continuación podra selecionar el primer nivel",
                        levelFirst : "Primer nivel",
                        levelSecond : "Segundo nivel",
                        desLevelSecond : "Descripcion",
                        editar : "Editar",
                        reportingLevel : "Tercer Nivel",
                        noData : "No hay datos para este nivel",
                        create : "Crear",
                        previous: "Ant.",
                        next: "Sig.",
                    }
                }else{
                    return {
                        title : "Consolidation Schedule",
                        informacion : "Update",
                        subtitle : "Next you can select the first level",
                        levelFirst : "First Level",
                        levelSecond : "Second Level",
                        desLevelSecond : "Description",
                        editar : "Update",
                        reportingLevel : "Third Level",
                        noData : "There is not data for this level",
                        create : "Create",
                        previous: "Previous",
                        next: "Next",
                    }
                }
            }
        },
        methods: {
            searchOnTableSecondLevel(){
                this.datosPaginados = searchBySecondLevel(this.listaSegundoNivel, this.searchSecondLevel);
                if(this.searchSecondLevel == ''){
                    this.getDataPagina(1)
                }
            },
            totalPaginas(){
                return Math.ceil(this.listaSegundoNivel.length / this.elementosPorPagina)
            },
            getDataPagina(noPagina){
                this.datosPaginados = [];
                this.paginaActual = noPagina;
                let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
                let fin = (noPagina * this.elementosPorPagina);

                this.datosPaginados = this.listaSegundoNivel.slice(ini,fin);
            },
            getPreviousPage(){
                if(this.paginaActual > 1){
                    this.paginaActual--;
                }
                this.getDataPagina(this.paginaActual);
            },
            getNextPage(){
                if(this.paginaActual < this.totalPaginas()){
                    this.paginaActual++;
                }
                this.getDataPagina(this.paginaActual);
            },
                isActive(noPagina){
                return noPagina == this.paginaActual ? 'active':'';
            },
            infoReportingLevel(id){
                PlanConsolidacion.getConsolidPlanBySecondId(id)
                .then((res) => {
                    // console.log(res.data.levelReport)
                    this.itemSelected = res.data.levelReport
                })
                .catch((error) => {
                    console.log(error)
                })
            },
            goToCreateLevelSecond(){
                this.showDialog = true;
            },
            closeModal(value){
                this.showDialog = value
            },

	editNivel2(id){
		this.showDialogEdit = true
		const currentIdx = this.listaSegundoNivel.findIndex((obj) => obj.id == id);
		this.dialogItem = this.listaSegundoNivel[currentIdx];
		this.formEdit.id = this.dialogItem.id;
		this.formEdit.primer_nivel = this.dialogItem.primer_nivel;
		this.formEdit.des_primer_nivel = this.nameFirstLevel;
		this.formEdit.segundo_nivel = this.dialogItem.segundo_nivel;
		this.formEdit.descripcion = this.dialogItem.descripcion;
	},
	editaInfoNivel2(){
		this.isLoading = true;
		SegundoNivel.updateSegundoNivel(this.formEdit)
		.then(() => {
			// console.log(res)
			this.$toast.success("Nivel editado correctamente")
			location. reload()
			this.isLoading = false;
			this.showDialogEdit = false;
		})
		.catch(() =>{
			this.error = true;
		})

	},
},
mounted(){
            const idFirstLevel = this.$route.params.primer_nivel
            this.levelFirsrt = idFirstLevel 
            SegundoNivel.getAllLevelSecondByLevelFirst(idFirstLevel)
            .then((res) => {
                // console.log(res.data.secondLevByFirstLev)
                this.listaSegundoNivel = res.data.secondLevByFirstLev
                this.getDataPagina(1);
            })
            .catch((err) => {
                console.log(err)
            })


            PrimerNivel.getFirstLevelById(idFirstLevel)
            .then((res) => {
                // console.log(res.data.firstLevelById[0].descripcion)
                this.nameFirstLevel = res.data.firstLevelById[0].descripcion
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
</script>
<style src="../main.css"></style>
