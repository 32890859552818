import Api from '@/services/Api'

export default {
	getAllNotas(){
		return Api().get('/notas/getAllNotas')
	},
	getNotaById(id){
		return Api().get('/notas/getNotaById/'+id)
	},
	createNota(formu){
		return Api().post('/notas/createNota',formu)
	},
	updateNota(info){
		return Api().put('/notas/updateNota',info)
	},
	deleteNota(id,numero){
		return Api().get('/notas/deleteNota/'+id+'/'+numero)
	},
	createParrafo(formData){
		return Api().post('/notasParrafo/create',formData)
	},
	updateParrafo(formData){
		return Api().post('/notasParrafo/update',formData)
	},
	deleteParrafo(numero,parrafo,vigencia){
		return Api().get('/notasParrafo/delete/'+vigencia+'/'+numero+'/'+parrafo)
	},
}
