<template>
  <div class="container">
    <div class="row align-items-start">
        <div class="col-md-3">
            <md-field>
              <Select2 v-model="info.nitProveedor" :options="myOptionsProvider" class="optiSeletProviList" :reduce="nombre => nombre.id" label="nombre" placeholder="Proveedor"></Select2>
            </md-field>
        </div>
        <!-- <div class="col-md-2">
            <md-field>
              <label for="invStatus">{{labels.statusInv}} </label>
              <md-select id="invStatus" v-model="info.statusInvoice" @change="prueba($event)">
                <md-option value="APP">{{labels.approved}}</md-option>
                <md-option value="CHK">{{labels.checked}}</md-option>
                <md-option value="DEC">{{labels.declined}}</md-option>
                <md-option value="PND">{{labels.pending}}</md-option>
              </md-select>
            </md-field>
        </div>
        <div class="col-md-2">
            <md-field>
              <label for="cauStatus">{{labels.causedInv}} </label>
              <md-select id="cauStatus" v-model="info.causedInvoice" @change="prueba($event)">
                <md-option value="1">{{labels.yess}}</md-option>
                <md-option value="0">{{labels.nott}}</md-option>
              </md-select>
            </md-field>
        </div>
        <div class="col-md-2">
            <md-field>
              <label>{{ labels.numInvoice }}</label>
              <md-input
                type="text"
                name="numInvoice"
                v-model="info.numInvoice"
              />
            </md-field>
        </div>
        <div class="col-md-2">
            <md-field>
              <label>{{ labels.comenta }}</label>
              <md-input
                type="text"
                name="comenta"
                v-model="info.comenta"
              />
            </md-field>
        </div> -->
        <div class="col-md-3">
            <md-field class="custom-date-picker">
                <date-picker
                v-model="info.dateFrom"
                :format="datePickerFormat"
                :value-type="datePickerFormat"
                :placeholder="labels.dateFrom"
                ></date-picker>
            </md-field>
        </div>
        <div class="col-md-3">
            <md-field class="custom-date-picker">
                <date-picker
                v-model="info.dateTo"
                :format="datePickerFormat"
                :value-type="datePickerFormat"
                :placeholder="labels.dateTo"
                ></date-picker>
            </md-field>
        </div>
        <div class="col-md-3">
            <md-button @click="filterInvoiceList"> <span><font-awesome-icon :icon="['fas', 'filter']" style="color: #912710;" /></span> </md-button>
            <md-button @click="clearInvoice"> <span><font-awesome-icon :icon="['fas', 'broom']" style="color: #235789;" /></span> </md-button>
        </div>
    </div>
    <div class="col-md-12">
        <div class="divSpinner">
            <b-spinner v-if="spinn == true"></b-spinner>
        </div>
    </div>
  </div>
</template>

<script>
import Select2 from 'v-select2-component';
import Provider from "@/services/Provider";
import Approver from "@/services/Approver";
import Invoice from "@/services/Invoice";
import DatePicker from "vue2-datepicker";

export default {
    name: "FiltersInvoice",
    components: {
        Select2,
        DatePicker
    },

    data: () => ({
        datePickerFormat: "DD/MM/YYYY",
        providers: [],
        providersByUser: [],
        nitProviders: [],
        myOptions: [],
        myOptionsProvider: [],
        info: {
            statusInvoice: "",
            causedInvoice: "",
            nitProveedor: "",
            dateFrom: "",
            dateTo: "",
            numInvoice: "",
            comenta: "",
        },
        clearInv:[],
        isLoading: false,
        spinn: false,
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    statusInv: "Estado",
                    approved: "Aprovada",
                    checked: "Revisada",
                    declined: "Rechazada",
                    pending: "Pendiente",
                    causedInv: "Causada",
                    yess: "Si",
                    nott: "No",
                    dateFrom: "Desde",
                    dateTo: "Hasta",
                    numInvoice: "# Factura",
                    comenta: "Comentario",
                };
            } else {
                return {
                    statusInv: "Status",
                    approved: "Approved",
                    checked: "Checked",
                    declined: "Declined",
                    pending: "Pending",
                    causedInv: "Caused",
                    yess: "Yes",
                    nott: "No",
                    dateFrom: "From",
                    dateTo: "To",
                    numInvoice: "# Invoice",
                    comenta: "Comment",
                };
            }
        }
    },
    methods:{
        getOptionsProvider(data){
            let optProvider = {};
            if(this.$store.state.userRole == "APP"){
                for(let ind=0; ind < data.length; ind++){
                    optProvider = { id: data[ind].nit, text: data[ind].name }
                    this.myOptionsProvider.push(optProvider)
                }
            }else{            
                for(let ind=0; ind < data.length; ind++){
                    optProvider = { id: data[ind].nii, text: data[ind].name }
                    this.myOptionsProvider.push(optProvider)
                }
                // console.log(this.myOptions)
            }
        },
        filterInvoiceList(){
            // console.log(this.info);
            // this.info.userId = this.$store.state.userId
            this.info.userRole = this.$store.state.userRole
            this.info.nitProvidersByUser = this.nitProviders
            this.spinn = true;
            Invoice.invoiceFilter(this.info)
            .then((res) => {
                //console.log(res.data.invoices)
                this.spinn = false;
                this.$emit('escucharHijo', res.data.invoices)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                this.spinn = false;
            })
        },
        clearInvoice(){
            this.info.nitProveedor = ""
            this.info.dateFrom = ""
            this.info.dateTo = ""
            this.$emit('limpiaFiltro', this.clearInv)
        }
    },
    mounted(){
        let idUser = this.$store.state.userId
        Provider.getAll()
        .then((result) => {
            // console.log(result.data)
            this.providers = result.data.data;
            if(this.$store.state.userRole == "ADM"){
                this.getOptionsProvider(result.data.data);
            }
            if (this.providers.length == 0) {
                // this.isEmpty = true;
            }
        })
        .catch(() => {
            // console.log(error);
            // this.error = true;
        })

        Approver.getApproverByUserId(idUser)
        .then((res) => {
            this.providersByUser = res.data.data[0].providers
            // console.log(this.providersByUser)
            if(this.$store.state.userRole == "APP"){
                this.getOptionsProvider(this.providersByUser);
            }
            for(let i=0; i <= this.providersByUser.length; i++){
                // console.log(this.providersByUser[i].nit)
                this.nitProviders.push(this.providersByUser[i].nit)
            }
        })
        .catch(() => {

        })
    }
}
</script>

<style src="../main.css" scoped>
form {
  width: 100%;
}
</style>