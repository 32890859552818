<template>
    <!-- <div class="container"> -->
        <md-dialog :md-active.sync="showDialogInfo" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
               <!-- <h3> {{ labels.listSubsidiary }} </h3> -->
               <h3> {{ companyName.nombre }} </h3>
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <!-- <b-col class="text-right">
                        <h4> {{ labels.matrix}} </h4>
                    </b-col> -->
                    <b-col class="text-center">
                        <!-- <h4> {{ companyName }} </h4> -->
                        <h4> {{ labels.listSubsidiary }} </h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <h6> {{ labels.subsidiary}} </h6>
                    </b-col>
                    <b-col class="text-center">
                        <h6> {{ labels.stake }} </h6>
                    </b-col>
                </b-row>
                <b-row v-for="subsidiary in Item" :key="subsidiary.id">
                    <b-col cols="8" class="ml-1 text-left">
                        <h6> {{ getNameSubsidiary(subsidiary.id_subsidiaria) }} </h6>
                    </b-col>
                    <b-col cols="3" class="mr-2 text-right">
                        <h6> {{ subsidiary.participacion }} %</h6>
                    </b-col>
                </b-row>
                <b-row v-if="showRow == true">
                    <b-col class="ml-3 text-left">
                        <!-- <md-select v-model="selSubsidiary" :placeholder="labels.subsidiary">
                            <md-option v-for="comp in listCompany" :key="comp.id" :value="comp.id"> {{ comp.nombre }}</md-option>
                        </md-select> -->
                        <select v-model="selSubsidiary" class="form-control">
                            <option v-for="comp in listCompany" :key="comp.id" :value="comp.id"> {{ comp.nombre }} </option>
                        </select>
                    </b-col>
                    <b-col class="mr-3 text-right">
                        <md-field>
                            <md-input
                                type="number"
                                v-model="cantPartici"
                                required
                                :placeholder="labels.stake"
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row v-if="showRow == true">
                    <b-col class=" mt-3 text-center">
                        <b-button @click="createSubsidiary()" id="created-btn">{{labels.create}}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center">
                        <md-button style="background-color: #2e507a;" class="md-fab md-primary" @click="newSubsidiary">
                            <md-icon>add</md-icon>
                        </md-button>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    <!-- </div> -->
</template>
<script>

    import Company from "../../services/Company"
    import Subsidiary from "../../services/Subsidiary"

    export default {
        name:"ListSubsidiary",
        props: ['showDialogInfo','Item','companyName'],

        data: () => ({
            listCompany : [],
            listCompanySub : [],
            showRow: false,
            selSubsidiary: "",
            cantPartici:"",
            error: false
        }),

        computed: {
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        listSubsidiary : "Lista de Subsidiarias",
                        subsidiary : "Subsidiaria",
                        stake : "Participación",
                        matrix : "Matriz:",
                        create:"Crear",
                        save : "Guardar",
                        close : "Cerrar"
                    }
                }else{
                    return {
                        listSubsidiary : "List of Subsidiaries",
                        subsidiary : "Subsidiary",
                        stake : "Stake",
                        matrix : "Matrix:",
                        create:"Create",
                        save : "Save",
                        close : "Close"
                    }
                }
            }
        },

        methods : {
            clicked(){
                this.selSubsidiary = ""
                this.cantPartici = ""

                this.$emit('clicked', false);
                this.showRow = false
            },
            getNameSubsidiary(id){
                // console.log(id)
                let objSubsidiary = this.listCompanySub.find((obj) => obj.id === id)
                return objSubsidiary.codigo+" "+objSubsidiary.nombre
            },
            getParticipacion(num){
                let numero = Math.trunc(num)
                return numero
            },
            newSubsidiary(){
                this.showRow = true
                // let newObj = {}
                // console.log(this.listCompany,"AAAA")
                if(this.Item.length != 0){
                    // console.log("AAA")
                    /* QUITA LOS QUE YA TIENE RELACIONADOS Y EL DE LA COMPANIA QUE SE SELECCIONO */
                    this.Item.forEach(element => {
                        this.listCompany = this.listCompany.filter((compId) => compId.id != element.id_subsidiaria && compId.id != this.companyName.id)
                    });
                    // console.log(this.listCompany)
                    return this.listCompany
                }else{
                    // console.log("BBB")
                    /* QUITA LA COMPANIA SELECCIONADA DEL LISTADO DE TODAS LAS COMPANIAS EXISTENTES */
                    this.listCompany = this.listCompany.filter((compId) => compId.id != this.companyName.id)
                    // console.log(this.listCompany)
                    return this.listCompany
                }
            },
            createSubsidiary(){
                // console.log(this.selSubsidiary,this.cantPartici)

                if(this.selSubsidiary == ""){
                    this.$toast.error("El campo Subsidiaria debe ser diligenciado")

                }else if(this.cantPartici == ""){
                    this.$toast.error("El campo Participación debe ser diligenciado")

                }else if(this.cantPartici < 0 || this.cantPartici > 100){
                    this.$toast.error("El campo Participación debe estar entre 0 y 100")

                }else{

                    let form = {
                        'idMatriz'      : this.companyName.id,
                        'idSubsidiaria' : this.selSubsidiary,
                        'participacion' : this.cantPartici,
                        'idUser'        : this.$store.state.userId,
                    }
                    // console.log(form)

                    Subsidiary.createMatrizSubsi(form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        location.reload()
                    })
                    .catch(() => {
                        this.$toast.error("La matriz no puede tener esta compañia como subsidiaria, ya que esta es su matriz principal")
                        this.error = true;
                    })
                }
            }
        },
        mounted(){
            Company.getAllCompanies()
            .then((result) => {
                // console.log(result.data.companies)
                this.listCompany = result.data.companies
                this.listCompanySub = result.data.companies
                // console.log(this.listCompany)
            })
            .catch((error) => {
                console.log(error)
            })
        },
        // watch:{
        //     showRow(val){
        //         console.log(val)
        //     }
        // }
    }
</script>
