<template>
  <div class="py-0" id="sidebar">
    <!-- <b-container-fluid> -->
    <ul>
      <li>
        <a v-if= "icono == 'u-bosque'" href="#">
          <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
          <span>U Bosque</span>
        </a>
        <a v-if= "icono == 'steward'" href="#">
          <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
          <span>Grupo Steward</span>
        </a>
        <a v-if= "icono == 'complement'" href="#">
          <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
          <span>Complement</span>
        </a>
      </li>
      <li
        v-for="(item, idx) in pages"
        :key="idx"
        :class="{ 'active-page': $route.name == item.route }"
      >
        <a :href="item.ref">
          <font-awesome-icon :icon="['fas', item.icon]" />
          <span>{{ item.name }}</span>
        </a>
      </li>
    </ul>
    <!-- </b-container-fluid> -->
  </div>
</template>

<script>
import Menu from '../../services/Menu';
import Authentication from "@/services/Authentication"

export default {
  name: "Sidebar",
  data() {
    return {
      icono: "",
      compania : "",
      menu: "",
      home: "/#home",
      scroll: false,
      // rolPermiso: [1,2],
      listMenu: [],
      newMenu: []
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  methods: {
    scrollFunction(e) {
      this.scroll = window.pageYOffset > 300;
      e.preventDefault();
    },
    logout: function() {
      const obj = {
        token: "",
        username: "",
        role: "",
        expiresIn: "",
        isAuthenticated: false,
      };
      this.$store.commit("setData", obj);
      this.$router.push("/");
    },
    toggleLanguage() {
      if (this.$store.state._language == "es-ES") {
        this.$store.commit("setLang", "en-US");
      } else {
        this.$store.commit("setLang", "es-ES");
      }
    },
  },
  computed: {
    role() {
      // console.log(this.$store.state.userRole)
      return this.$store.state.userRole;
    },
    isAuthenticated() {
      // console.log(this.$store.state.isAuthenticated)
      return this.$store.state.isAuthenticated;
    },
    language() {
      // console.log(this.$store.state._language)
      return this.$store.state._language;
    },
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          pages: [
            {
              name: "Dashboard",
              ref: "/home",
              route: "home",
              roles: [],
              icon: "home",
            },
            {
              name: "Administrador",
              ref: "/administrador",
              route: "administrador",
              roles: [],
              icon: "lock",
            },
            {
              name: "Parametrización",
              ref: "/parametrizacion",
              route: "parametrizacion",
              roles: [],
              icon: "layer-group",
            },
            {
              name: "Cargues",
              ref: "/cargue",
              route: "cargue",
              roles: [],
              icon: "upload",
            },
            {
              name: "Consolidación",
              ref: "/consolidation",
              route: "consolidation",
              roles: [],
              icon: "list",
            },
            // {
            //   name: "Usuarios",
            //   ref: "/users",
            //   route: "users",
            //   roles: [1],
            //   icon: "users",
            // },
            // {
            //   name: "Monedas",
            //   ref: "/currency",
            //   route: "currency",
            //   roles: [1],
            //   icon: "coins",
            // },
            // {
            //   name: "Tasas Cambio",
            //   ref: "/tasaCambio",
            //   route: "tasaCambio",
            //   roles: [1],
            //   icon: "people-arrows",
            // },
            // {
            //   name: "Compañias",
            //   ref: "/company",
            //   route: "company",
            //   roles: [1],
            //   icon: "building",
            // },
            // {
            //   name: "Esquemas",
            //   ref: "/planConsolidacion",
            //   route: "planConsolidacion",
            //   roles: [1],
            //   icon: "list",
            // },
            // {
            //   name: "Niif",
            //   ref: "/niif",
            //   route: "niif",
            //   roles: [1],
            //   icon: "bars",
            // },
            // {
            //   name: "Plan cuentas",
            //   ref: "/planCuentas",
            //   route: "planCuentas",
            //   roles: [1],
            //   icon: "list",
            // },
            /*{
              name: "Balance Niif",
              ref: "/balanceNiif",
              route: "balanceNiif",
              roles: [1],
              icon: "list",
            },
            {
              name: "ESF",
              ref: "/reporteEsf",
              route: "reporteEsf",
              roles: [1],
              icon: "list",
            },
            {
              name: "ERI",
              ref: "/reporteEri",
              route: "reporteEri",
              roles: [1],
              icon: "list",
            },
            {
              name: "EFE",
              ref: "/reporteEfe",
              route: "reporteEfe",
              roles: [1],
              icon: "list",
            },
            {
              name: "ECP",
              ref: "/reporteEcp",
              route: "reporteEcp",
              roles: [1],
              icon: "list",
            },
            {
              name: "Indicadores",
              ref: "/reporteIndicadores",
              route: "reporteIndicadores",
              roles: [1],
              icon: "list",
            },*/
          ],
        };
      } else {
        return {
          pages: [
            {
              name: "Dashboard",
              ref: "/home",
              route: "home",
              roles: [1],
              icon: "home",
            },
            {
              name: "Administrator",
              ref: "/administrador",
              route: "administrador",
              roles: [1],
              icon: "lock",
            },
            {
              name: "Parametrización",
              ref: "/parametrizacion",
              route: "parametrizacion",
              roles: [1],
              icon: "layer-group",
            },
            {
              name: "Charge",
              ref: "/cargue",
              route: "cargue",
              roles: [1],
              icon: "upload",
            },
            {
              name: "Consolidation",
              ref: "/consolidation",
              route: "consolidation",
              roles: [1],
              icon: "list",
            },
            // {
            //   name: "Users",
            //   ref: "/users",
            //   route: "users",
            //   roles: [1],
            //   icon: "users",
            // },
            // {
            //   name: "Currency",
            //   ref: "/currency",
            //   route: "currency",
            //   roles: [1],
            //   icon: "coins",
            // },
            // {
            //   name: "Exchange rate",
            //   ref: "/tasaCambio",
            //   route: "tasaCambio",
            //   roles: [1],
            //   icon: "people-arrows",
            // },
            // {
            //   name: "Company",
            //   ref: "/company",
            //   route: "company",
            //   roles: [1],
            //   icon: "building",
            // },
            // {
            //   name: "Schedule",
            //   ref: "/planConsolidacion",
            //   route: "planConsolidacion",
            //   roles: [1],
            //   icon: "list",
            // },
            // {
            //   name: "Niif",
            //   ref: "/niif",
            //   route: "niif",
            //   roles: [1],
            //   icon: "bars",
            // },
            // {
            //   name: "Chart accounts",
            //   ref: "/planCuentas",
            //   route: "planCuentas",
            //   roles: [1],
            //   icon: "list",
            // },
            // {
            //   name: "NIIF",
            //   ref: "/balanceNiif",
            //   route: "balanceNiif",
            //   roles: [1],
            //   icon: "list",
            // },
            /*{
              name: "ESF",
              ref: "/reporteEsf",
              route: "reporteEsf",
              roles: [1],
              icon: "list",
            },
            {
              name: "ERI",
              ref: "/reporteEri",
              route: "reporteEri",
              roles: [1],
              icon: "list",
            },
            {
              name: "EFE",
              ref: "/reporteEfe",
              route: "reporteEfe",
              roles: [1],
              icon: "list",
            },
            {
              name: "ECP",
              ref: "/reporteEcp",
              route: "reporteEcp",
              roles: [1],
              icon: "list",
            },
            {
              name: "Indicadores",
              ref: "/reporteIndicadores",
              route: "reporteIndicadores",
              roles: [1],
              icon: "list",
            },*/
          ],
        };
      }
    },
    pages() {
//    console.log("State ",this.$store.state)
      // return this.labels.pages.filter((page) => {
      return this.newMenu.filter((page) => {
        return page.roles.find((item) => {
          return item == this.$store.state.userRole;
        });
      });
    },
  },
  mounted(){
	Authentication.leaParametros()
	.then ((result) => {
		this.icono = result.data.parametros.icono
		this.compania = result.data.parametros.compania
	})
    Menu.getAllMenu()
    .then((res) => {
      // console.log(res.data.listMenu)
      this.listMenu = res.data.listMenu
      // console.log(this.listMenu)
      this.listMenu.forEach((elem) => {
        // console.log(elem.roles.split(','))
        let rol = elem.roles.split(',')
        // console.log(rol)
        let asigRol = rol.filter((rol) => rol == this.$store.state.userRole)
        // console.log(asigRol)
        
        let objMenu = {
          'name' : elem.nombre,
          'ref' : elem.referencia,
          'route' : elem.ruta,
          'roles' : asigRol,
          'icon' : elem.icono
        }
        this.newMenu.push(objMenu)
      })
      // console.log(this.newMenu)
    })
    .catch((err) => {
      console.log(err)
    })
  },
};
</script>

<style>
/* d-none d-md-flex */
#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--five);
  /* box-shadow: 2px 0px 20px 0 rgba(31, 38, 135, 0.37); */
  /* backdrop-filter: blur(4px); */
  /* -webkit-backdrop-filter: blur(4px); */
  /* border-radius: 10px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  /* display: block; */
  transition: 0.3s width ease;
  z-index: 5;
  padding: 20px;
  overflow-x: hidden;
}
#sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#sidebar ul li:first-child {
  margin: 40px 0 40px;
}
#sidebar:hover ul li:first-child {
  padding: 12px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  margin: 28px 0 28px;
}
#sidebar ul li:first-child svg {
  background: var(--first);
}
#sidebar ul li {
  width: 100%;
  border-radius: 6px;
  transition: all 0.3s ease;
  margin: 5px 0;
}
#sidebar:hover ul li {
  padding-left: 12px;
}
#sidebar ul li.active-page {
  background: var(--second);
}
#sidebar ul li.active-page svg{
  color: var(--first);
}
#sidebar ul li:hover {
  padding-left: 16px;
  background: rgba(255, 255, 255, 0.5);
}
#sidebar ul li a {
  display: flex;
  align-items: center;
  height: 40px;
  color: var(--first) !important;
  text-decoration: none;
  font-size: 1.1rem;
}

#sidebar ul li a span {
  display: none;
  margin-left: 1rem;
}

#sidebar ul li svg {
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 6px;
  font-size: 2rem;
  color: var(--icon);
}
#sidebar:hover {
  width: 250px;
  
}

#sidebar:hover span {
  display: block;
}

/* Small screens */
@media (max-width: 575.98px) {
  #sidebar {
    top: auto;
    bottom: 0;
    min-width: 100%;
    width: 100%;
    height: 3rem;
    overflow: hidden;
  }
  #sidebar ul {
    flex-direction: row;
    justify-content: center;
  }
  #sidebar ul li:first-child {
    display: none;
  }
  #sidebar:hover ul li {
    padding-left: 0px;
  }
  #sidebar ul li:hover {
    padding-left: 0px;
    background: var(--seven);
  }
  #sidebar:hover {
    min-width: 100%;
  }

  #sidebar:hover span {
    display: none;
  }
  #sidebar ul li a {
    justify-content: center;
  }
}
</style>
