<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: ['pieData'],
  mounted() {
    // this.gradient = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);
    // this.gradient2 = this.$refs.canvas
    //   .getContext("2d")
    //   .createLinearGradient(0, 0, 0, 450);

    // this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    // this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    // this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    // this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    // this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    // this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    // let labels = ["Pending", "Approved", "Denied", "Checked"];
    // if(this.$store.state._language == "es-ES"){
    //   labels = ["Pendiente", "Aprobado", "Rechazado", "Revisado"];
    // }
    let labels = Object.keys(this.pieData)
    this.renderChart(
      {
        labels: labels,
        datasets: [
          {
            backgroundColor: ["#fce094","#96f69e", "#ffa6a6", "#96c5f6"],
            // data: [this.pieData.PND, this.pieData.APP, this.pieData.DEC, this.pieData.CHK]
            data: Object.values(this.pieData)
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>