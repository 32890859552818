<template>
  <div>
    <div class="login-bg">
      <b-row>
        <b-col md="6" lg="4" data-aos="fade-right" class="left-side">
          <b-form @submit.prevent="onSubmit" id="form-login">
            <div class="icon-container">
<!--              <img src="../../public/logo-ub.png" alt="logo" srcset="" /> -->
	<img src="../../public/logoComplement.jpg" alt="" srcset="">
            </div>
            <div class="content">
              <h3>{{ labels.greeting }}</h3>
              <p>{{ labels.info }}</p>
              <p v-if="error" class="invalid-login">{{ labels.err }}</p>
            </div>
            <b-form-group :class="{ formLoading: isLoading }">
              <md-field>
                <label>{{ labels.email }}</label>
                <span><font-awesome-icon :icon="['fas', 'envelope']"/></span>
                <md-input
                  type="text"
                  name="email"
                  v-model="form.email"
                  :placeholder="labels.email"
                  required
                />
              </md-field>
              <md-field>
                <label>{{ labels.password }}</label>
                <span><font-awesome-icon :icon="['fas', 'lock']"/></span>
                <md-input
                  type="password"
                  name="password"
                  v-model="form.password"
                  :placeholder="labels.password"
                  required
                />
              </md-field>
              <b-button type="submit" id="login-btn">{{
                labels.login
              }}</b-button>     
              <div class="forgot-password" @click="showDialog = true">Olvidé mi contraseña</div>
              <div class="more">
                <!-- <a href="#" @click.prevent="changeLanguage()" class="forgot">{{
                labels.forgot
              }}</a> -->
              <a href="#" @click.prevent="changeLanguage()" class="lang">{{
                labels.lang
              }}</a>
              </div>         
            </b-form-group>
            
            <div class="loadingSpinner" :class="{ active: isLoading }">
              <b-spinner></b-spinner>
            </div>
          </b-form>
        </b-col>
        <b-col md="6" lg="8" data-aos="fade-left" class="right-side">
          <img src="../../public/logoComplement.jpg" alt="" srcset="">
          <h3>{{labels.tittleImg}}</h3>
        </b-col>
      </b-row>
    </div>
    <!-- <loader id="pre-loader" object="#21dec7" color1="#884af4" color2="#f28c44" size="5" speed="1.5" bg="#000000" objectbg="#999793" opacity="70" name="circular"></loader> -->
    <md-dialog v-if="showDialog" :md-active.sync="showDialog">
      <md-dialog-title>Restablecer contraseña</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Ingresa tu correo</label>
          <md-input v-model="resetEmail" />
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="resetPassword()"
          >Aceptar</md-button
        >
        <md-button class="md-accent" @click="showDialog = false"
          >Cerrar</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Auth from "@/services/Authentication";

export default {
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    showDialog: false,
    isLoading: false,
    error: false,
    isFetching: true,
    resetEmail: "",
  }),
  methods: {
    onSubmit() {
      this.error = false;
      this.isLoading = true;
      let nextRoute = "";
      Auth.login(this.form)
        .then((user) => {
          user.data.isAuthenticated = true;
          this.$store.commit("setData", user.data);
          
          if(user.data.isAuthenticated){
            nextRoute = {name: 'home'};
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.$router.push(nextRoute);
        });
    },
    changeLanguage() {
      this.isLoading = true;
      if (this.$store.state._language == "en-US") {
        this.$store.commit("setLang", "es-ES");
      } else {
        this.$store.commit("setLang", "en-US");
      }
      this.isLoading = false;
    },
    resetPassword(){
      this.showDialog = false
      this.isLoading = true

      Auth.resetPassword({email: this.resetEmail})
        .then(() => {
          this.$toast.success("Te enviaremos un correo con los pasos a seguir")
        })
        .catch((err) => {
          this.error = true;
          if(err.response.status == 404){
            this.$toast.error(err.response.data.message)
          }else{
          this.$toast.error("Algo pasó, intentalo de nuevo")
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.resetEmail = ""  
        });
    }
  },
  beforeMount(){
    if(this.$store.state.isAuthenticated){
      let nextRoute = "";
    switch(this.$store.state.userRole){
          case 'ADM':
            nextRoute = {name: 'home'};
            break;
          case 'COM':
            nextRoute = {name: 'home'};
            break;
          case 'APP':
            nextRoute = {name: 'list'};
            break;
          case 'CHK':
            nextRoute = {name: 'list'};
            break;
          case 'AGN':
            nextRoute = {name: 'pending'};
            break;
        }
          this.$router.push(nextRoute);

    }
  },
  computed: {
    labels(){
    if (this.$store.state._language == "es-ES") {
      return {
        greeting: "Iniciar Sesión",
        info: "Ingresa los datos que utilizaste para el registro",
        email: "Correo Electrónico",
        password: "Contraseña",
        login: "Ingresar",
        lang: "English Version",
        err: "Credenciales Incorrectas",
        forgot: "Olvidé mi contraseña",
        sentence: "Control de Facturas de Proveedores",
        tittleImg: "Herramienta para la Consolidación de los Estados Financieros de los Grupos Económicos",
      };
    } else {
      return  {
        greeting: "Login",
        info: "Fill the fields with the registration information you provide",
        email: "E-mail Address",
        password: "Password",
        login: "Login",
        lang: "Versión en Español",
        err: "Invalid Credentials",
        forgot: "Forgot my password",
        sentence: "Start to improve your business financial operations",
        tittleImg: "Tool for conciliation of states financiers of an economic group"
      };
    }
    }
  },
};
</script>

<style src="./main.css"></style>
