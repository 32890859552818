<template>
  <div class="container">
    <md-dialog :md-active.sync="showFila" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.newRow }}
        </md-dialog-title>
        <md-dialog-content>
            <b-row>
                <b-col md="8" lg="7" class="mt-2 mx-auto">
                    <md-field>
                        <md-input
                            type="number"
                            name="filaNumeric"
                            v-model="filaNumeric"
                            :placeholder="labels.filaNumeric"                                
                        />
                    </md-field>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="8" lg="7" class="mt-2 mx-auto">
                    <md-field>
                        <md-input
                            type="text"
                            name="fila"
                            v-model="fila"
                            :placeholder="labels.filaName"                                
                        />
                    </md-field>
                </b-col>
            </b-row>
        </md-dialog-content>
        <md-dialog-actions class="mt-3">
            <md-button class="md-primary" @click="saveFila">{{ labels.save }}</md-button>
            <md-button class="md-accent" @click="clickClose">{{ labels.close }}</md-button>
        </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>

import EcpReporte from '../../services/EcpReporte'

export default {
    name:"NewFila",
    props: ['showFila'],
    data: () => ({
        filaNumeric : '',
        fila : ''
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    newRow: "Agregar Fila",
                    filaName: "Nombre",
                    filaNumeric: "Fila (Numeros)",
                    save: "Guardar",
                    close: "Cerrar"
                }
            }else{
                return {
                    newRow: "Add Row",
                    filaName: "Name",
                    filaNumeric: "Row (Numeric)",
                    save: "Save",
                    close: "Close"
                }
            }
        }
    },
    methods:{
        clickClose(){
            this.$emit('closeModal',false)
        },
        saveFila(){
            const dataForm = {
                'fila'          : this.filaNumeric,
                'nombreFila'    : this.fila,
                'idUser'        : this.$store.state.userId
            }
            if(this.fila != "" && this.filaNumeric != ""){
                // console.log(dataForm)
                EcpReporte.insertFila(dataForm)
                .then((res) =>{
                    // console.log(res)
                    this.$toast.success(res.data.message)
                })
                .catch((err) => {
                    console.log(err)
                    this.$toast.error(err)
                })
                .finally(() => {
                    setTimeout(() => {                        
                        this.reloadTblFila()
                        this.filaNumeric = ""
                        this.fila = ""
                    }, 3000);
                })

            }else{
                this.$toast.error("Hay campos pendientes por diligenciar")
            }
        },
        reloadTblFila(){
            this.$emit('reloadTblFila',false)
        }
    }
}
</script>

<style>

</style>