<template>
    <div class="container ">
        <div class="d-flex justify-content-end">
            <!-- <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="editVariasCuentas()">
                <md-icon>edit</md-icon>
            </md-button> -->
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaNivCuatro()">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="headNivUno">{{ labels.levelCuatro }}</div>
        <div class="bodyNivUno">
            <select name="" id="" class="form-control" v-model="optSelCuatro">
                <option value="">{{ labels.select }}</option>
                <option v-for="nivel in listNivelCuatro" :key="nivel.id" :value="nivel.nivel_4">{{ nivel.nivel_4 }}. {{ nivel.descripcion }}</option>
            </select>
        </div>
        <CreaNivelCuatroVue :showCreaNivCuatro="showCreaNivCuatro" :nivelTresSelect="nivelTresSel" :nivelDosSelect="nivelDosSel" :nivelUnoSelect="nivelUnoSel" @closeModal="cierraModal" @refresNivelCuatro="refrescaSel"></CreaNivelCuatroVue>
    </div>
</template>

<script>
import CreaNivelCuatroVue from './CreaNivelCuatro.vue'
import Nivel from '../../services/Nivel'

export default {
    name:"NivelCuatro",
    data:() => ({
        listNivelCuatro : [],
        optSelCuatro : "",
        showCreaNivCuatro: false
    }),
    props:['infoNivCuatro','nivelTresSel','nivelDosSel','nivelUnoSel'],
    components:{
        CreaNivelCuatroVue
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    levelCuatro: "Nivel IV",
                    select: "Seleccione..."
                }
            }else{
                return {
                    levelCuatro: "Level IV",
                    select: "Select..."
                }
            }
        }
    },
methods:{
	creaNivCuatro(){
		this.showCreaNivCuatro = true
	},
	cierraModal(){
		this.showCreaNivCuatro = false
	},
	refrescaSel(valRuta){
		this.nivelTresSel = this.nivelTresSel == '' ? null : this.nivelTresSel
		if(valRuta == "directo"){
			if	(this.nivelTresSel != null) {
				Nivel.getLevFourByLevOneLevTwoLevThree(this.nivelUnoSel,this.nivelDosSel,this.nivelTresSel)
				.then((res) => {
					this.listNivelCuatro = res.data.nivelCuatroEfe
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.showCreaNivCuatro = false
				})
			} else {
				Nivel.getLevFourByLevOneLevTwoLevThreeNull(this.nivelUnoSel,this.nivelDosSel,null)
				.then((res) => {
					this.listNivelCuatro = res.data.nivelCuatroEfe
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.showCreaNivCuatro = false
				})
			}
		} else	{
			if	(this.nivelTresSel != null) {
				Nivel.getLevFourByLevOneLevTwoLevThreeInd(this.nivelUnoSel,this.nivelDosSel,this.nivelTresSel)
				.then((res) => {
					this.listNivelCuatro = res.data.nivelCuatroEfe
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.showCreaNivCuatro = false
				})
			} else {
				Nivel.getLevFourByLevOneLevTwoLevThreeNullInd(this.nivelUnoSel,this.nivelDosSel,null)
				.then((res) => {
					this.listNivelCuatro = res.data.nivelCuatroEfe
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.showCreaNivCuatro = false
				})
			}
		}
	},
},
mounted(){
        
    },
    watch:{
        optSelCuatro(nivCuatro){
            // console.log(nivCuatro)
            this.$emit('envLevCuatro',nivCuatro)
        },
        infoNivCuatro(valNew){
            if(valNew.length >= 0){
                this.listNivelCuatro = this.infoNivCuatro
            }
        }
    }
}
</script>

<style src="../main.css">

</style>
