<template>
<!-- filtersÑ https://codeburst.io/top-vue-packages-for-charts-and-useful-filters-and-directives-51714ce1d0f1 -->
      <b-container>
          <b-col md="8" class="mx-auto"><div id="chartsec">
       <!-- <AreaChart/> -->
    <PieChart :pie-data="status"/>
    <!-- <PieChart :pie-data="" -->
    <BarChart :bar-data="invoicePerApprover"/>
    <PieChart :pie-data="payedCaused"/>

    <!-- <RadarChart/> -->
    <!-- <LineChart/> -->
  </div></b-col>
      </b-container>
</template>

<script>
// import AreaChart from "@/components/charts/Area.vue";
import PieChart from "@/components/charts/Pie.vue";
import BarChart from "@/components/charts/Bar.vue";
// import RadarChart from "@/components/charts/Radar.vue";
// import LineChart from "@/components/charts/Line.vue"

export default {
    name: "Metrics",
    components: {
        // AreaChart,
        PieChart,
        BarChart,
        // RadarChart,
        // LineChart
    },
    props: ['chartdata','status','invoicePerApprover','payedCaused'],
    data(){
        return{
            chartData: {
                Books: 24,
                Magazine: 30,
                Newspapers: 10
            }
        }
    }
}
</script>

<style src="../main.css">

</style>