<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div class="col-md-12 mt-5">
            <md-table md-card md-fixed-header v-model="listNiifNoCont">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell :md-label="labels.cuenta" class="border">{{ item.cuenta }}</md-table-cell>
                    <md-table-cell :md-label="labels.niif" class="border">{{ item.niif }}</md-table-cell>
                    <md-table-cell :md-label="labels.editar" class="border" >
                        <md-button @click="editaNiif(item.id)" style="background-color: #ffda44; width: 35px; height: 35px;" class="md-fab md-primary"><md-icon>edit</md-icon></md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <EditaNiifNoControlante :EditNiifNoContr="editNiifNoCont" :regNiif="idNiif" @closeModal="cierraModal"></EditaNiifNoControlante>
    </div>
</template>

<script>
import NiifNoControlante from '../../services/NiifNoControlante';
import EditaNiifNoControlante from './EditaNiifNoControlante.vue';

export default {
    name: "NiifNoControlante",
    data:() => ({
        listNiifNoCont : [],
        editNiifNoCont : false,
        idNiif : ""
    }),
    components: {EditaNiifNoControlante},
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "NIIF no controlante",
                    cuenta : "Cuenta",
                    niif : "Niif",
                    editar : "Editar",
                }
            }else{
                return {
                    title : "NIIF no controlling",
                    cuenta : "Account",
                    niif : "Niif",
                    editar : "Edit",
                }
            }
        }
    },
    methods:{
        editaNiif(id){
            this.editNiifNoCont = true
            this.idNiif = id
        },
        cierraModal(val){
            this.editNiifNoCont = val
        }
    },
    mounted(){
        NiifNoControlante.getAllNiifNoControlante()
        .then((res) => {
            // console.log(res.data)
            this.listNiifNoCont = res.data.niifNoControlante
        })
        .catch((err) => {console.log(err)})
    }
}
</script>

<style>

</style>