import Api from '@/services/Api'

export default {
    getAllTipoReporte(){
        return Api().get('/tipoReporte/getAllTipoReporte')
    },
    getTipoReporteById(id){
        return Api().get('/tipoReporte/getTipoReporteById/'+id)
    },
    createReporte(info){
        return Api().post('/tipoReporte/create',info)
    },
    updateReporte(info){
        return Api().put('/tipoReporte/update',info)
    },
    deleteReporte(id){
        return Api().get('/tipoReporte/delete/'+id)
    }
}
