<template>
    <div class="container">
        <div class="col-md-12">
            <h2>{{ labels.title }}</h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-4 h5">
                <h5>
                    <select id="selVigencia" name="" v-model="selVigencia" v-on:click="iniciaPlan;fgl_consulta=false">
                        <option value="">{{ labels.vigencia }}</option>
                        <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                    </select>
                </h5>
            </div>
            <div class="col-md-4 h5">
                <h5>
                    <select id="company" name="" v-model="companyId" v-on:click="iniciaPlan;fgl_consulta=false">
                        <option value="">{{ labels.company }}</option>
                        <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+"-"+company.nombre }}</option>
                    </select>
                </h5>
            </div>
            <div class="col-md-4 h5">
                <h5>
                    <select id="selVigencia" name="" v-model="selReporte" v-on:click="iniciaPlan;fgl_consulta=false">
                        <option value="">{{ labels.report }}</option>
                        <option v-for="reporte in listReporte" :key="reporte.id" :value="reporte.codigo"> {{ reporte.nombre }}</option>
                    </select>
                </h5>
            </div>
        </div>
        <div class="row justify-content-center mt-2">
            <div class="col-md-12">
                <b-button @click="consultaCuentas();fgl_consulta=true" id="consult-btn">{{ labels.consultar }}</b-button>
            </div>
            <div class="col-md-12 mt-4" v-if="fgl_consulta">
                <div class="row">
                    <div class="col-md-3 justify-content-left">
                        <md-field md-inline>
                            <label>{{labels.search}}</label>
                            <md-input v-model="searchCuenta" @input="searchOnTableAcount"></md-input>
                        </md-field>
                    </div>
                    <div class="col-md-9 d-flex justify-content-end">
                        <td>
                            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="editVariasCuentas()">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </td>
                    </div>
                </div>
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">{{labels.account}}</th>
                            <th scope="col">{{labels.description}}</th>
                            <!-- <th scope="col">{{labels.levelFirst}}</th>
                            <th scope="col">{{labels.levelSecond}}</th> -->
                            <th scope="col">{{labels.niif}}</th>
                            <th scope="col">{{labels.esf}}</th>
                            <th scope="col">{{labels.eri}}</th>
                            <th scope="col">{{labels.efe}}</th>
                            <th scope="col">{{labels.ecp}}</th>
                            <th scope="col">{{labels.edit}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="planCuent in datosPaginados" :key="planCuent.id">
                            <td>{{planCuent.cuenta}}</td>
                            <td class="text-left">{{planCuent.descripcion}}</td>
                            <!-- <td class="text-left">{{planCuent.nombre_primer_nivel}}</td>
                            <td class="text-left">{{planCuent.nombre_segundo_nivel}}</td> -->
                            <td class="text-left">{{planCuent.nombre_niif}}</td>
                            <td>{{planCuent.esf == "X" ? "SI" : "NO"}}</td>
                            <td>{{planCuent.eri == "X" ? "SI" : "NO"}}</td>
                            <td>{{planCuent.efe == "X" ? "SI" : "NO"}}</td>
                            <td>{{planCuent.ecp == "X" ? "SI" : "NO"}}</td>
                            <td>
                                <md-button @click="editCuenta(planCuent.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" v-on:click="getPageIni()"><a class="page-link" href="#" :disabled="isInFirstPage()">{{ labels.first }}</a></li>
                        <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#" :disabled="isInFirstPage()">{{ labels.previous }}</a></li>
                        <li v-for="pagina in pages()" :key="pagina.id" v-on:click="onClickPage(pagina.name)" class="page-item" v-bind:class="isActive(pagina.name)"><a class="page-link" href="#">{{pagina.name}}</a></li>
                        <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#" :disabled="isInLastPage()">{{labels.next}}</a></li>
                        <li class="page-item" v-on:click="getPageLast()"><a class="page-link" href="#" :disabled="isInLastPage()">{{ labels.last }}</a></li>
                    </ul>
                </nav>
            </div>
            <div v-else class="mt-4 h4">
                No hay datos
            </div>
        </div>
        <div v-if="!isLoading"></div>
        <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
        </div>
        <Edit :showDialogEdit="showDialogEdit" @clicked="closeModal" @reload="refrescaTabla" :Item="itemSelected" :listNiif="listNiif"/>
        <EditaVariasCuentas :showEditMasivo="showEditMasivo" :compVigSelect="selCompVig" :listadoCuentas="listPlanCuentas" :listadoNiif="listNiif" @reload="refrescaTabla" @clicked="closeModal" />
    </div>
</template>
<script>

    import Company from "../../services/Company"
    import PlanCuentas from "../../services/PlanCuentas"
    import PrimerNivel from '../../services/PrimerNivel';
    // import SegundoNivel from '../../services/SegundoNivel'
    import PlanConsolidacion from '../../services/PlanConsolidacion';
    import TipoReporte from '../../services/TipoReporte';
    import Edit from "./Edit.vue";
    import EditaVariasCuentas from "./EditaVariasCuentas.vue";

    const toLower = (text) => {
        return text.toString().toLowerCase();
    };

    const searchByCuenta = (items, term) => {
        if (term) {
            return items.filter((item) =>
                toLower(item.cuenta).startsWith(term)
            );
        }
        return items;
    };

    export default {
        name:"PlanCuentas",
        components: {
            Edit,
            EditaVariasCuentas
        },

        data: () => ({
            fgl_consulta: false,
            searchCuenta : null,
            listPlanCuentas : [],
            listCompany : [],
            listVigencia : [],
            elementosPorPagina:[10],
            datosPaginados: [],
            paginaActual: 1,
            companyId: "",
            selVigencia: "",
            maxVisibleButtons: 5,
            showDialogEdit: false,
            showEditMasivo: false,
            itemSelected: {},
            listFirstLevel: [], 
            listSecondLevel: [],
            listNiif : [],
            isLoading: false,
            selCompVig: {},
            selReporte : "",
            listReporte : []
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Plan de cuentas",
                        search: "Buscar",
                        account: "Cuenta",
                        description : "Descripción",
                        levelFirst : "Primer nivel",
                        levelSecond : "Segundo nivel",
                        niif : "Niif",
                        esf : "esf",
                        eri : "eri",
                        efe : "efe",
                        ecp : "ecp",
                        select : "Seleccione...",
                        vigencia : "Vigencia..",
                        company : "Compañia..",
                        report : "Reporte..",
                        consultar : "Consultar",
                        first: "Ini",
                        previous: "Ant.",
                        next: "Sig.",
                        last: "Ult.",
                        edit: "Editar",
                        cuentaNiif: "Cuenta/Niif",
                    }
                }else{
                    return {
                        title: "Chart of accounts",
                        search: "Search",
                        account: "Account",
                        description : "Description",
                        levelFirst : "Level first",
                        levelSecond : "Level second",
                        niif : "Niif",
                        esf : "esf",
                        eri : "eri",
                        efe : "efe",
                        ecp : "ecp",
                        select : "Select...",
                        vigencia : "Validity: ",
                        report : "Report:",
                        company : "Company: ",
                        consultar : "Consult",
                        first: "Fir",
                        previous: "Previous",
                        next: "Next",
                        last: "Last",
                        edit: "Edit",
                        cuentaNiif: "Account/NIIF",
                    }
                }
            }
        },

        methods: {
            iniciaPlan(){
                    this.listPlanCuentas = []
            },
            consultaCuentas(){
                // console.log(this.selVigencia)
                this.isLoading = true
                
                // PlanCuentas.planCuentPorCompaVig(this.companyId,this.selVigencia)
                PlanCuentas.getCuentasPorCompaVigRepor(this.companyId,this.selVigencia,this.selReporte)
                .then((res) => {
                    // console.log(res)
                    this.listPlanCuentas = res.data.planCuentas
                    this.getDataPagina(1);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                })

		PlanConsolidacion.getPlanConsPorCompVige(this.selVigencia,this.selReporte)
                .then((res) => {
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            editCuenta(id){
		const item = this.listPlanCuentas.find((r_plan) => r_plan.id == id)
		this.itemSelected = item
                this.showDialogEdit = true;
            },
            editVariasCuentas(){
                this.selCompVig.companyId = this.companyId
                this.selCompVig.vigencia = this.selVigencia
                this.selCompVig.codReporte = this.selReporte
                this.showEditMasivo = true
            },
            closeModal(value){
                this.showDialogEdit = value;
                this.showEditMasivo = value
            },
            refrescaTabla(vigencia,idCompany,codReporte){

                this.isLoading = true
                
                // PlanCuentas.planCuentPorCompaVig(idCompany,vigencia)
                PlanCuentas.getCuentasPorCompaVigRepor(idCompany,vigencia,codReporte)
                .then((res) => {
                    // console.log(res)
                    this.listPlanCuentas = res.data.planCuentas
                    this.getDataPagina(1);
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                    this.showDialogEdit = false
                    this.showEditMasivo = false
                })
            },
            searchOnTableAcount(){
                this.datosPaginados = searchByCuenta(this.listPlanCuentas, this.searchCuenta);
                if(this.searchCuenta == ''){
                    this.getDataPagina(1)
                }
            },
            inicioPag(){
                if (this.paginaActual === 1) {
                    return 1;
                }

                if (this.paginaActual === this.totalPaginas()) { 
                    return this.totalPaginas() - this.maxVisibleButtons + 1;
                }

                return this.paginaActual - 1;
            },
            finPag(){
                return Math.min(this.inicioPag() + this.maxVisibleButtons - 1, this.totalPaginas());
            },
            pages() {
                const range = [];

                for (let i = this.inicioPag(); i <= this.finPag(); i+= 1 ) {
                    range.push({
                        name: i,
                        isDisabled: i === this.paginaActual 
                    });
                }

                return range;
            },
            isInFirstPage() {
                return this.paginaActual === 1;
            },
            isInLastPage() {
                return this.paginaActual === this.totalPaginas();
            },
            totalPaginas(){
                return Math.ceil(this.listPlanCuentas.length / this.elementosPorPagina)
            },
            getPageIni(){
                this.paginaActual = 1
                this.getDataPagina(this.paginaActual);
            },
            getPageLast(){
                let lastPage = this.totalPaginas();
                this.getDataPagina(lastPage)
            },
            onClickPage(noPage){
                // console.log(noPage)
                this.getDataPagina(noPage);
            },
            getDataPagina(noPagina){
                this.datosPaginados = [];
                this.paginaActual = noPagina;
                let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
                let fin = (noPagina * this.elementosPorPagina);

                this.datosPaginados = this.listPlanCuentas.slice(ini,fin);
            },
            getPreviousPage(){
                if(this.paginaActual > 1){
                    this.paginaActual--;
                }
                this.getDataPagina(this.paginaActual);
            },
            getNextPage(){
                if(this.paginaActual < this.totalPaginas()){
                    this.paginaActual++;
                }
                this.getDataPagina(this.paginaActual);
            },
            isActive(noPagina){
                return noPagina == this.paginaActual ? 'active':'';
            },
            /*getNameFirstLevel(id){
                let nameLevel = this.listFirstLevel.find((obj) => obj.primer_nivel === id)
                return nameLevel.descripcion
            },
            getNameSecondLevel(primer,segundo){
                
                SegundoNivel.getAllLevelSecondByLevelFirst(primer)
                .then((res) => {
                    // console.log(res)
                    this.listSecondLevel = res.data.secondLevByFirstLev
                })
                .catch((err) => {
                    console.log(err)
                })

                let nameLevSec = this.listSecondLevel.find((obj) => obj.segundo_nivel === segundo) 
                return nameLevSec.descripcion
                // return primer+" - "+segundo
            },
            getNameNiif(id){
                let nameNiif = this.listNiif.find((obj) => obj.niif === id)
                return nameNiif.descripcion
            }*/
        },

        mounted(){
            this.isLoading = true
            Company.getAllCompanies()
            .then((result) => {
                this.listCompany = result.data.companies
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false
            })

            PlanCuentas.getVigenciaPlancuentas()
            .then((result) => {
                // console.log(result.data.getVigencia)
                this.listVigencia = result.data.getVigencia
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false
            })

            PrimerNivel.getAllLevelFirst()
            .then((result) => {
                // console.log(result.data.primerNivel)
                this.listFirstLevel = result.data.primerNivel
            })
            .catch((err) => {
                console.log(err)
            })

            // SegundoNivel.getAllLevelSecondByLevelFirst()
            // .then((res) => {
            //     console.log(res)
            // })
            // .catch((err) => {
            //     console.log(err)
            // })

            TipoReporte.getAllTipoReporte()
            .then((res) => {
                this.listReporte = res.data.typeReport
            })
            .catch((err) => console.log(err))

        },

        // watch: {
        //     consultaCuentas(){
        //         this.inicioPag();
        //     }
        // }
    }
</script>
<style src="../main.css"></style>
