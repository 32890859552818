<template>
  <div>
    <md-dialog :md-active.sync="showDialogEdit">
      <md-dialog-title class="text-center">{{labels.information}}</md-dialog-title>
      <md-dialog-content>
        <b-col md="8" lg="12" class="p-2 mx-auto">
          <b-form-group @submit.prevent="editInfoRol">
            <label>{{labels.name}} </label>
            <md-field>
              <md-input type="text" v-model="Item.nombre"></md-input>
            </md-field><br>

            <label>{{labels.description}}</label>
            <md-field>
                <md-textarea v-model="Item.descripcion" md-autogrow></md-textarea>
            </md-field>
          </b-form-group>
          <md-dialog-actions>
            <md-button class="md-primary" @click="editInfoRol(Item)">{{ labels.guardar }}</md-button>
            <md-button class="md-accent" @click="closeModal()">{{ labels.cerrar }}</md-button>
          </md-dialog-actions>
        </b-col>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import Rol from "@/services/Roles";
export default {
    props:['showDialogEdit','Item'],
    data(){
        return{
            isLoading:false,
            // showDialogEdit:false,
            Prueba:{
                nombre:'',
                description:'',
            },
        }
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                  information: "Editar Rol",
                  name: "Nombre:",
                  description: "Descripción:",
                  guardar : "Guardar",
                  cerrar : "Cerrar",
                }
            }else {
                return {
                  information: "Edit Rol",
                  name: "Name:",
                  description: "Description:",
                  guardar : "Save",
                  cerrar : "Close",
                }
            }
        }
    },
    methods:{
      editInfoRol(Item){
        // console.log(Item)
        // this.isLoading = true;
        Item.idUser = this.$store.state.userId
        Rol.updateRolById(Item)            
        .then(() => {
          this.isLoading = true;
          // this.roles = rta.data.roles;
          this.$toast.success("Rol editado correctamente")
          location. reload()
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
      },
      closeModal(){
        this.$emit('closeModal',false)
      }
    }
}
</script>
