<template>
    <div class="container ">
        <div class="d-flex justify-content-end" v-if="muestraSel">
            <!-- <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="editVariasCuentas()">
                <md-icon>edit</md-icon>
            </md-button> -->
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaNivTresNulo()">
                <md-icon>lock</md-icon>
            </md-button>
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaNivTres()">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div v-else class="pt-5"></div>
        <div class="headNivUno">{{ labels.levelTres }}</div>
        <div class="bodyNivUno">
            <select name="" id="" class="form-control" v-model="optSelTres" v-if="muestraSel">
                <option value="">{{ labels.select }}</option>
                <option v-for="nivel in listNivelTres" :key="nivel.id" :value="nivel.nivel_3">{{ nivel.nivel_3 }} . {{ nivel.descripcion }}</option>
            </select>
        </div>
        <CreaNivelTresVue :showCreaNivTres="showCreaNivTres" :nivelDosSelect="nivelDosSel" :nivelUnoSelect="nivelUnoSel" @closeModal="cierraModal" @refresNivelTres="refrescaSel"></CreaNivelTresVue>
    </div>
</template>

<script>
import CreaNivelTresVue from './CreaNivelTres.vue'
import Nivel from '../../services/Nivel'

export default {
    name:"NivelTres",
    data:() => ({
        listNivelTres : [],
        optSelTres : "",
        muestraSel : true,
        showCreaNivTres: false
    }),
    components:{
        CreaNivelTresVue
    },
    props:['infoNivTres','nivelDosSel','nivelUnoSel'],
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    levelTres: "Nivel III",
                    select: "Seleccione..."
                }
            }else{
                return {
                    levelTres: "Level III",
                    select: "Select..."
                }
            }
        }
    },
    methods:{
        creaNivTres(){
            this.showCreaNivTres = true
        },
        creaNivTresNulo(){
                const info = {
                    'numNivelUno' : this.nivelUnoSel,
                    'numNivelDos' : this.nivelDosSel,
                    'numNivelTres' : null,
                    'descripcion' : null,
                    'idUser' : this.$store.state.userId
                }

                if(this.$route.params.tipo == 'directo'){
                    Nivel.crearNivelTresEfe(info)
                    .then((res) => {
				this.$toast.success(res.data.message)
				this.muestraSel = false
				this.abreNivelCuatro()
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 500){
                            this.$toast.error(err.response.data.message)
                        }else{
                            this.$toast.error("Algo pasó, intentalo de nuevo")
                        }
                    })
                }else{
                    Nivel.crearNivelTresEfeInd(info)
                    .then((res) => {
				this.$toast.success(res.data.message)
				this.muestraSel = false
				this.abreNivelCuatro()
                    })
                    .catch((err) => {
                        if(err.response.status == 500){
				this.$toast.error(err.response.data.message)
                        }else{
                            this.$toast.error("Algo pasó, intentalo de nuevo")
                        }
                    })
                }
        },
        cierraModal(val){
            this.showCreaNivTres = val
        },
	abreNivelCuatro() {
		this.$emit('abreNivelCuatro',true)
	},
        refrescaSel(valRuta){
            if(valRuta == "directo"){
                Nivel.getLevThreeByLevOneLevTwo(this.nivelUnoSel,this.nivelDosSel)
                .then((res) => {
                    this.listNivelTres = res.data.nivelTresEfe
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.showCreaNivTres = false
                })
            }else{
                Nivel.getLevThreeByLevOneLevTwoInd(this.nivelUnoSel,this.nivelDosSel)
                .then((res) => {
                    this.listNivelTres = res.data.nivelTresEfe
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.showCreaNivTres = false
                })
            }            
        }
    },
    mounted(){
    },
    watch:{
        optSelTres(valNew){
            // console.log(valNew,valOld)
            this.$emit('envLevTres',valNew)
        },
        infoNivTres(valNew){
//        console.log("infoNivTres("+valNew+")",valNew)
		if(valNew == "undefine") {
			this.muestraSel = true
			return
		}
		if(valNew.length == 0) {
			this.muestraSel = true
			return
		}
		
		if(valNew[0].nivel_3 == null){
			this.muestraSel = false
			this.$emit('envLevTresNull',null)
		}else{
			this.muestraSel = true
			this.listNivelTres = this.infoNivTres
		}
        }
    }
}
</script>

<style src="../main.css">

</style>
