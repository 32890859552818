<template>
  <div class="container">
    <md-table v-model="ajustOrigen" md-card md-fixed-header>
        <md-table-toolbar>
            <h1 class="md-title"><b><i>{{labels.titleTbl}}</i></b></h1>
            <!-- <pre>
                {{ ajustOrigen }}
            </pre> -->
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.cuenta" class="text-center">{{item.cuenta}}</md-table-cell>
            <md-table-cell :md-label="labels.descripcion" class="text-left" >{{ item.descripcion }}</md-table-cell>
            <md-table-cell :md-label="labels.valor" class="text-left" >{{ item.valor }}</md-table-cell>
            <md-table-cell :md-label="labels.funcional" class="text-left" >{{ item.funcional }}</md-table-cell>
            <md-table-cell :md-label="labels.alterna" class="text-left" >{{ item.alterna }}</md-table-cell>
            <md-table-cell :md-label="labels.edit" class="text-center" >
                <md-button @click="editAjuste(item.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
            </md-table-cell>
        </md-table-row>
    </md-table>
    <EditAjustesOrigenVue :showEdiAjustOrig="showEdiAjustOrig" :dataEdit="ajustes" @reloadTblOrig="recargaTabla" @closeModal="cierraModal"/>
  </div>
</template>
<script>

import EditAjustesOrigenVue from './EditAjustesOrigen.vue'
import AjustesConsol from '../../services/AjustesConsol'

export default {
    name:"AjustesOrigen",
    props:['ajustOrigen'],
    components:{EditAjustesOrigenVue},
    data: () => ({
        ajustes:[],
        showEdiAjustOrig:false
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    titleTbl : "Ajustes Origen",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",    
                    funcional : "Funcional",    
                    alterna : "Alterna",
                    edit : "Editar"    
                }
            }else{

                return {
                    titleTbl : "Origin Settings",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "alterna",
                    edit : "Edit"
                }
            }
        }
    },
    methods:{
        async editAjuste(id){
            // console.log(id)
            let editAjuste = []
            editAjuste = await AjustesConsol.consulAjusteOrigenById(id)
            // console.log(editAjuste.data.ajuste)
            this.ajustes = editAjuste.data.ajuste[0]
            this.showEdiAjustOrig = true
        },
        cierraModal(val){
            this.showEdiAjustOrig = val
        },
        async recargaTabla(val){
            // console.log(val)
            this.showEdiAjustOrig = false

            let ajusteOrig = await AjustesConsol.consulAjusteOrigen(val.id,val.vigencia,val.idCompany)
            // console.log(ajusteOrig)
            this.ajustOrigen = ajusteOrig.data.ajustOrig
        }
    }

}
</script>
