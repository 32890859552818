<template>
    <div class="container" v-if="ajustDestino.length != 0">
        <md-table v-model="ajustDestino" md-card md-fixed-header>
            <md-table-toolbar>
                <h1 class="md-title"><b><i>{{labels.titleTbl}}</i></b></h1>
                <!-- <pre>
                    {{ ajustDestino }}
                </pre> -->
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="labels.cuenta" class="text-center">{{item.cuenta}}</md-table-cell>
                <md-table-cell :md-label="labels.descripcion" class="text-left" >{{ item.descripcion }}</md-table-cell>
                <md-table-cell :md-label="labels.valor" class="text-left" >{{ item.valor }}</md-table-cell>
                <md-table-cell :md-label="labels.funcional" class="text-left" >{{ item.funcional }}</md-table-cell>
                <md-table-cell :md-label="labels.alterna" class="text-left" >{{ item.alterna }}</md-table-cell>
                <md-table-cell :md-label="labels.edit" class="text-center" >
                    <md-button @click="editAjuste(item.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <EditAjustesDest :showEdiAjustDest="showEdiAjustDest" :infoEdit="ajustes" @reloadTblDest="recargaTabla" @closeModal="cierraModal" />
    </div>
</template>
<script>

import EditAjustesDest from './EditAjustesDest.vue';
import AjustesConsol from '../../services/AjustesConsol'

export default {
    name:"AjustesDestino",
    props:['ajustDestino'],
    components:{EditAjustesDest},
    data: () => ({
        ajustes:[],
        showEdiAjustDest:false
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){

                return {
                    titleTbl : "Ajustes Destino",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",    
                    funcional : "Funcional",    
                    alterna : "Alterna",
                    edit : "Editar"    
                }
            }else{

                return {
                    titleTbl : "Destination Settings",
                    cuenta : "Cuenta",
                    descripcion : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "alterna",
                    edit : "Edit"
                }
            }
        }
    },
    methods:{
        cierraModal(val){
            this.showEdiAjustDest = val
        },
        async editAjuste(id){
            // console.log(id)
            let editAjuste = []
            editAjuste = await AjustesConsol.consulAjusteDestinoById(id)
            
            this.ajustes = editAjuste.data.ajuste[0]
            this.showEdiAjustDest = true
        },
        async recargaTabla(val){
            // console.log(val)

            this.showEdiAjustDest = false
            let ajusteDestino = await AjustesConsol.consulAjusteDestino(val.id,val.vigencia)
            // console.log(ajusteDestino)
            this.ajustDestino = ajusteDestino.data.ajustDest
        }
    }

}
</script>