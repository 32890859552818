<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.newCompany }}
            </md-dialog-title>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="name"
                                v-model="form.name"
                                :placeholder="labels.name"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <md-select name="currency" v-model="form.idCurrency" placeholder="Seleccione...">
                                <md-option :key="currency.id" :value="currency.id" v-for="currency in listCurrency">{{ currency.nombre }}</md-option>
                            </md-select>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="acronym"
                                v-model="form.acronym"
                                :placeholder="labels.acronym"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="code"
                                v-model="form.code"
                                :placeholder="labels.code"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <md-select name="codReporte" v-model="form.codReporte" placeholder="Seleccione...">
                                <md-option :key="reporte.id" :value="reporte.codigo" v-for="reporte in listReportes">{{ reporte.nombre }}</md-option>
                            </md-select>
                        </md-field>
                    </b-col>
                </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="saveCompany">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>

    import Company from "@/services/Company"
    import Currency from "@/services/Currency"
    import Balance from "@/services/BalanceNiif"

    export default {
        name: "NewCompany",
        props: ['showDialog'],

        data: () => ({
            form : {
                name : "",
                idCurrency : "",
                acronym : "",
                code : "",
                codReporte : ""
            },
            listCurrency : "",
            listReportes : "",
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        newCompany : "Nueva compañia",
                        name : "Nombre",
                        acronym : "Acrónimo",
                        code : "Código",
                        codReporte : "Reporte",
                        save : "Guardar",
                        close : "Cerrar"
                    }
                }else{
                    return {
                        newCompany : "New company",
                        name : "Name",
                        acronym : "Acronym",
                        code : "Code",
                        codReporte : "Report",
                        save : "Save",
                        close : "Close"
                    }
                }
            }
        },
        methods : {
            clicked(){
                this.$emit('clicked', false);
            },
            saveCompany(){
                // console.log(this.form)
                if(this.form.name == ""){
                    this.$toast.error("Diligenciar el campo Nombre")
                
                }else if(this.form.idCurrency == ""){
                    this.$toast.error("Seleccionar una Moneda")

                }else if(this.form.acronym == ""){
                    this.$toast.error("Diligenciar el campo Acrónimo")

                }else if(this.form.code == ""){
                    this.$toast.error("Diligenciar el campo Código")
                }else if(this.form.codReporte == ""){
                    this.$toast.error("Diligenciar el campo Reporte")

                }else {
                    this.form.userId = this.$store.state.userId;
                    Company.createCompany(this.form)
                    .then((result) => {
                        this.$toast.success(result.data.message)
                        location.reload()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            }
        },
        mounted () {
		Currency.getAllCurrency()
		.then((res) => {
			this.listCurrency = res.data.currency;
		}).catch((error) => {
			console.log(error)
		})
		Balance.getReportes()
		.then ((result) => {
			this.listReportes = result.data.reportes
		})
        }
    }
</script>
