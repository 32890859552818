<template>
  <div class="container">
    <md-dialog :md-active.sync="showCreaNivUno" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content class="mt-3">
            <b-row class="">
                <b-col cols="1"></b-col>
                <b-col cols="3" class="text-left">
                    <h5>{{labels.nivel}}</h5>
                </b-col>
                <b-col cols="8" lg="6" class="text-left">
                    <input type="number" v-model="numeroNivel" class="form-control"><br>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="1"></b-col>
                <b-col cols="3" class="text-left">
                    <h5>{{labels.descripcion}}</h5>
                </b-col>
                <b-col cols="8" lg="6" class="text-left">
                    <input type="text" v-model="descripcion" class="form-control"><br>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardaNivel">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>

import Nivel from '../../services/Nivel'

export default {
    name: "CreaNivelUno",
    props:['showCreaNivUno'],
    data: () => ({
        numeroNivel : "",
        descripcion : ""
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Crear Nivel",
                    nivel: "Nivel:",
                    descripcion: "Descripción:",
                    save : "Guardar",
                    close : "Cerrar",
                }
            }else{
                return {
                    title: "Create Level",
                    nivel: "Level:",
                    descripcion: "Description",
                    save : "Save",
                    close : "Close",
                }
            }
        }
    },
    methods:{
        clearCampos(){
            this.numeroNivel = ""
            this.descripcion = ""
        },
        closeModal(){
            this.$emit("closeModal",false)
            this.clearCampos()
        },
        guardaNivel(){

            if(this.numeroNivel == ''){
                this.$toast.error("Diligenciar el nivel")
            
            }else if(this.descripcion == ''){
                this.$toast.error("Diligenciar la descripcion")
            
            }else{

                const info = {
                    'numNivel' : this.numeroNivel,
                    'descripcion' : this.descripcion,
                    'idUser' : this.$store.state.userId
                }

                if(this.$route.params.tipo == 'directo'){
                    
                    Nivel.crearNivelUnoEfe(info)
                    .then((res) => {
                        // console.log(res.data)
                        this.$toast.success(res.data.message)
                        this.refrescaNivel()
                    })
                    .catch((err) => {
                        console.log(err)
                        if(err.response.status == 500){
                            this.$toast.error(err.response.data.message)
                        }else{
                            this.$toast.error("Algo pasó, intentalo de nuevo")
                        }
                    })
                }else{

                    Nivel.crearNivelUnoEfeInd(info)
                    .then((res) => {
                        // console.log(res.data)
                        this.$toast.success(res.data.message)
                        this.refrescaNivel()
                    })
                    .catch((err) => {
                        // console.log(err)
                        if(err.response.status == 500){
                            this.$toast.error(err.response.data.message)
                        }else{
                            this.$toast.error("Algo pasó, intentalo de nuevo")
                        }
                    })
                }
            }
        },
        refrescaNivel(){
            this.clearCampos()
            this.$emit('refresNivelUno',this.$route.params.tipo)
        }
    }
}
</script>

<style>

</style>