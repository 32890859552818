<template>
    <div class="container">
        <md-dialog :md-active.sync="modalEditAllEcp" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                    {{ labels.tittle }}
                </md-dialog-title>
                <md-dialog-content>
                    <b-row class="">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{labels.actual}}</h5>
                        </b-col>
                        <b-col cols="6" lg="6" class="text-left">
                            <select v-model="selActual" id="" class="form-control">
                                <option value=""> {{ labels.select }} </option>
                                <option value="1"> {{ labels.yes }} </option>
                                <option value="0"> {{ labels.not }} </option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row class="">
                        <b-col cols="2"></b-col>
                        <b-col cols="4" class="text-left">
                            <h5>{{labels.operacion}}</h5>
                        </b-col>
                        <b-col cols="6" lg="6" class="text-left">
                            <select v-model="selOperacion" id="" class="form-control">
                                <option value=""> {{ labels.select }} </option>
                                <option value="+"> + </option>
                                <option value="-"> - </option>
                            </select>
                        </b-col>
                </b-row>
                    <md-dialog-actions class="mt-3">
                        <md-button class="md-primary" @click="editaCuenta">{{ labels.save }}</md-button>
                        <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                    </md-dialog-actions>
                </md-dialog-content>
        </md-dialog>
        <!-- <pre>{{ dataVigComFilCol[0] }}</pre> -->
    </div>
</template>

<script>
import EcpReporte from '../../services/EcpReporte';

export default {
    name:"EditMasivoECP",
    props:["modalEditAllEcp","dataVigComFilCol"],
    data: () => ({
        selActual: "",
        selOperacion: "",
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    tittle: "Editar todo el detalle",
                    select: "Seleccione...",
                    yes: "SI",
                    not: "NO",
                    actual: "Actual:",
                    operacion: "Operación:",
                    save: "Guardar",
                    close: "Cerrar"
                }
            }else{
                return {
                    tittle: "Edit all detail",
                    select: "Select...",
                    yes: "YES",
                    not: "NO",
                    actual: "Current:",
                    operacion: "Operation:",
                    save: "Save",
                    close: "Close"
                }
            }
        }
    },
    methods:{
        clearCampos(){
            this.selActual = ""
            this.selOperacion = ""
        },
        async editaCuenta(){
            // console.log("editaCuenta")
            if(this.selActual == "" || this.selOperacion == ""){
                this.$toast.error("Campos pendientes por diligenciar")
            }else{
                let datos = {
                    "vigencia" : this.dataVigComFilCol[0].vigencia,
                    "compania" : this.dataVigComFilCol[0].id_compania,
                    "fila" : this.dataVigComFilCol[0].fila,
                    "columna" : this.dataVigComFilCol[0].columna,
                    "actual" : this.selActual,
                    "operacion" : this.selOperacion,
                }
                // console.log(datos)
                let uptAllEcp = await EcpReporte.updatAllDetalleEcp(datos)
                if(uptAllEcp.status == 200){
                    this.$toast.success(uptAllEcp.data.message)
                    setTimeout(() => {                        
                        this.reloadTable()
                        this.clearCampos()
                    }, 1500);
                }else{
                    this.$toast.error(uptAllEcp.data.message)
                }
            }
        },
        reloadTable(){
            let info = {
                'vigencia' : this.dataVigComFilCol[0].vigencia,
                'companyId' : this.dataVigComFilCol[0].id_compania,
                'fila' : this.dataVigComFilCol[0].fila,
                'columna' : this.dataVigComFilCol[0].columna
            }
            this.$emit('reloadTable',info)
        },
        clicked(){
            // console.log("clicked")
            this.$emit('closeModal',false)
        }
    }
}
</script>

<style>

</style>