import Api from '@/services/Api'

export default {
    getAllMenu(){
        return Api().get('menu')
    },
    getMenuById(id){
        return Api().get('menu/menuById/'+id)
    },
    createMenu(datos){
        return Api().post('menu/create',datos)
    },
    updateMenuById(datos){
        return Api().put('menu/update',datos)
    },
    updateRolesByMenu(datos){
        return Api().put('menu/updateRolesByMenu',datos)
    }
}