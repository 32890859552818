<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center py-1">
                    {{labels.title}}
                </h2>
            </b-container>
        </b-row>
        <b-row>
            <b-container>
                <b-col md="10" class="mx-auto"> 
                    <b-button @click="goToModalTasaCambio()" id="create-btn">{{labels.create}}</b-button>
                </b-col>
            </b-container>
        </b-row>
        <b-row class="py-2">
            <!-- <div class="row justify-content-left">
                <div class="col-md-12">
                    <md-field md-inline>
                    <label>{{labels.nameTasa}}</label>
                    <md-input v-model="searchTasaCambio" @input="searchOnTableTasaCambio"></md-input>
                    </md-field>
                </div>
            </div> -->
            <b-container v-if="!isEmpty" class="pb-5">

			<div class="headOrigen"><h3><b><i>{{labels.titulo}}</i></b></h3></div>
			<div class="bodyOrigen">

			<md-table v-model="tasaCambio" md-card md-fixed-header @md-selected="f_muestra">
				<md-table-row slot="md-table-row" slot-scope="{ item } " md-selectable="single">
					<md-table-cell :md-label="labels.validity" class="text-left">{{item.vigencia}}</md-table-cell>
					<md-table-cell :md-label="labels.currency" class="text-left" >{{ getNameCurrency(item.id_moneda) }}</md-table-cell>
					<md-table-cell :md-label="labels.closingRate" class="text-right" >{{ item.tasa_cierre }}</md-table-cell>
					<md-table-cell :md-label="labels.averageRate" class="text-right" >{{ item.tasa_promedio }}</md-table-cell>
					<md-table-cell :md-label="labels.internalRate" class="text-right" >{{ item.tasa_interna }}</md-table-cell>
				</md-table-row>
			</md-table>
			</div>
			<md-dialog :md-active.sync="showDialogEdit">
				<md-dialog-title class="text-center"><b><i>{{labels.modificar}}</i></b></md-dialog-title>
				<md-dialog-content>
					<b-form-group @submit.prevent="editaInfoBalance">
					<b-row><b-col><h4>{{labels.validity}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.vigencia" type="text" readonly></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.currency}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.nombre_moneda" type="text" readonly></md-input> </md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.closingRate}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.tasa_cierre" type="text" ></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.averageRate}}</h4></b-col>
					<b-col cols="6"><md-field> <md-input v-model="formEdit.tasa_promedio" type="text" ></md-input> </md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.internalRate}}</h4></b-col>
					<b-col cols="6"><md-field> <md-input v-model="formEdit.tasa_interna" type="text" ></md-input> </md-field></b-col></b-row>
					</b-form-group>
					<md-dialog-actions>
						<md-button class="md-primary" @click="grabeTasa()">Guardar</md-button>
						<md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
            </b-container>
        </b-row>
        <NewTasaCambio :showDialog="showDialog" @clicked="closeModal"/>
    </div>
</template>
<script>
import TasaCambio from "@/services/TasaCambio"
import Currency from "@/services/Currency"
import NewTasaCambio from "../tasaCambio/NewTasaCambio.vue"

export default {
        name: "TasaCambio",

components: {
            NewTasaCambio
},

data: () => ({
	searchTasaCambio : null,
	isEmpty: false,
	isLoading: false,
	tasaCambio: [],
	showDialogEdit: false,
	formEdit: {},
	showDialog: false,
	listCurrency: []
}),
computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Tasas de cambio",
                        create: "Crear",
                        nameTasa: "Tasa cambio",
                        validity: "Vigencia",
                        closingRate: "Tasa cierre",
                        averageRate: "Tasa promedio",
                        internalRate: "Tasa interna",
                        currency: "Moneda",
                        modificar: "Modificacion de Tasas",
                    }
                }else{
                    return {
                        title: "Exchange rate",
                        create: "Create",
                        nameTasa: "Exchange rate",
                        validity: "Validity",
                        closingRate: "Closing rate",
                        averageRate: "Average rate",
                        internalRate: "Internal rate",
                        currency: "Currency",
                        modificar: "Exchange Rate Update",
                    }
                }
            }
},
methods: {
	
            goToModalTasaCambio(){
                // this.$router.push({path: 'tasaCambio/create'})
                // console.log("dasdas")
                this.showDialog = true;
            },
            closeModal(value){
                this.showDialog = value;
            },
            searchOnTableTasaCambio(){

            },
            getNameCurrency(id){
		let objMoneda = this.listCurrency.find((obj) => obj.id === id)
		let nombre_moneda=objMoneda.nombre
		return nombre_moneda
            },

	f_muestra(r_tasas) {
		this.formEdit = r_tasas
		this.showDialogEdit = true
		this.formEdit.nombre_moneda = this.getNameCurrency(r_tasas.id_moneda)
	},
	grabeTasa() {
		this.showDialogEdit=false
		this.formEdit.ajuste_actual=this.v_ajuste_actual
		this.formEdit.ajuste_funcional1=this.v_ajuste_funcional1
		TasaCambio.grabeTasa(this.formEdit)
		.then()
			this.$toast.error("Informacion Grabada")
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		});
	},

},
mounted(){
            this.isLoading = true;

            TasaCambio.getTasaCambio()
            .then((result) => {
                // console.log(result.data)
                this.tasaCambio = result.data.tasa;

                if (this.currency.length == 0) {
                    this.isEmpty = true;
                }
            }).catch(() => {
                this.error = true;
            }).finally(() => {
                this.isLoading = false;
            });

            Currency.getAllCurrency()
            .then((result) => {
                this.listCurrency = result.data.currency
//                console.log(this.listCurrency)
            })
            .catch((error) => {
                console.log(error)
            })
        }
}
</script>
<style src="../main.css"></style>
