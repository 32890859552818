<template>
    <div class="container">
        <div class="col-md-12">
            <h3><b><i>{{ f_titulo() }}</i></b></h3>
        </div>
	<md-table v-model="listCompany" md-card md-fixed-header @md-selected="f_muestra">
		<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
			<md-table-cell :md-label="labels.company" class="text-left">{{item.nombre}}</md-table-cell>
			<md-table-cell :md-label="labels.acronym" class="text-center">{{item.acronym}}</md-table-cell>
			<md-table-cell :md-label="labels.formato" class="text-rigth">{{item.formato}}</md-table-cell>
		</md-table-row>
	</md-table>
	<md-dialog :md-active.sync="showDialogEdit">
		<md-dialog-title class="text-center">{{f_titulo()}}</md-dialog-title>
		<md-dialog-content>
			<b-form-group @submit.prevent="editaInfoBalance">
			<b-row><b-col><h4>{{labels.company}}</h4></b-col>
			<b-col cols="8"><md-field><md-input v-model="formEdit.nombre" type="text" readonly></md-input></md-field></b-col></b-row>
			<b-row><b-col><h4>{{labels.acronym}}</h4></b-col>
			<b-col cols="8"><md-field><md-input v-model="formEdit.acronym" type="text" readonly></md-input> </md-field></b-col></b-row>
			<b-row><b-col><h4>{{labels.formato}}</h4></b-col>
			<b-col cols="8"><md-field><md-input v-model="formEdit.formato" type="text"></md-input> </md-field></b-col></b-row>
			</b-form-group>
			<md-dialog-actions>
				<md-button class="md-primary" @click="grabeFormato()">Guardar</md-button>
				<md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
    </div>
</template>
<script>

import Company from "../../services/Company"

export default{
name: "CargueFormato",
data: () => ({
	listCompany: [],
	companyId: "",
	showBtn: false,
	isLoading: false,
	formEdit: { },
	showDialogEdit : false,
}),
computed: {
            labels(){
                if(this.$store.state._language == "es-ES"){
                    return {
                        company : "Compañía",
                        acronym : "Acronimo",
                        formato : "Formato",
                    }
                
                }else{
                    return {
                        company : "Company",
                        acronym : "Acronym",
                        formato : "Formato",

                    }
                }
            }
},
methods: {
	f_titulo() {
		let titulo = ""
		let tituloEN=""
		titulo = "FORMATOS DE CARGUE DE BALANCES"
		tituloEN = "ACCOUNTING BALANCE UPLOAD FORMAT"
		if      (this.$store.state._language != "es-ES") {
			titulo= tituloEN
		}
		return titulo
	},
	f_muestra(r_formato) {
		this.formEdit = r_formato
		this.showDialogEdit = true
	},
	grabeFormato() {
		if	(this.formEdit.formato == "") {
			this.$toast.error("Diligenciar el campo Formato")
			return
		}
		this.formEdit.userId = this.$store.state.userId;
		Company.grabeFormato(this.formEdit)
		.then()
			this.$toast.success("Informacion Grabada")
			this.showDialogEdit = false
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		});
	},
},
mounted(){
	this.isLoading = true;

	Company.getAllFormatos()
	.then((result) => {
		this.listCompany = result.data.formatos
	})
	.catch((err) => {
		console.log(err)
	})
	.finally(() => {
		this.isLoading = false;
	})

}
}
</script>
<style src="../main.css"></style>
