<template>
  <div class="container">
    <md-dialog :md-active.sync="modCrearMenu" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.name}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="nombre"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.ruta}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="ruta"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.icono}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="icono"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.orden}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="orden"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.roles}}</h5>
                </b-col>
                <b-col cols="6" class="">
                    <md-field>
                        <md-select v-model="rolSelected" name="roles" id="roles" md-dense multiple>
                            <md-option v-for="rol in roles" :key="rol.id" :value="rol.id">{{ rol.nombre }}</md-option>
                        </md-select>
                    </md-field>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardarMenu">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="cierraModal">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>
<script>
import Menu from '../../../services/Menu';
import Roles from '../../../services/Roles';

export default {
    name:"CrearMenu",
    props:['modCrearMenu'],
    data: () => ({
        nombre : "",
        ruta : "",
        icono : "",
        orden : "",
        roles : [],
        rolSelected : []
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Crear menú",
                    save : "Guardar",
                    close : "Cerrar",
                    name : "Nombre:",
                    ruta : "Ruta:",
                    icono : "Icono:",
                    roles : "Roles:",
                    orden : "Orden:",
                }
            }else{
                return {
                    title: "Create menu",
                    save : "Save",
                    close : "Close",
                    name : "Name:",
                    ruta : "Route:",
                    icono : "Icon:",
                    roles : "Role:",
                    orden : "Order:",
                }
            }
        }
    },
    methods:{
        cierraModal(){
            // console.log("CierraModal")
            this.$emit('closeModal',false)
        },
        guardarMenu(){
            // console.log("guardaMenu",this.roles)
            if(this.nombre == ""){
                this.$toast.error(`Diligenciar el campo Nombre`)
            
            }else if(this.ruta == ""){
                this.$toast.error(`Diligenciar el campo Ruta`)
            
            }else if(this.icono == ""){
                this.$toast.error(`Diligenciar el campo Icono`)

            }else if(this.orden == ""){
                this.$toast.error(`Diligenciar el campo Orden`)
            
            }else if(this.rolSelected.length == 0){
                this.$toast.error(`Seleccionar minimo un Rol`)
            
            }else{
                let formu = {
                    'nombre' : this.nombre,
                    'ruta' : this.ruta,
                    'icono' : this.icono,
                    'roles' : this.rolSelected,
                    'orden' : this.orden,
                    'idUser' : this.$store.state.userId
                }
                // console.log(formu)

                Menu.createMenu(formu)
                .then((res) => {
                    // console.log(res)
                    if(res.status == 200){
                        this.$toast.success(res.data.message)
                        location.reload();
                    
                    }else if(res.status == 204){
                        this.$toast.error("El menú ingresado ya existe")
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    },
    mounted(){
        Roles.getAllRoles()
        .then((res) => {
            // console.log(res.data.roles)
            this.roles = res.data.roles
        })
        .catch((err) => {
            console.log(err)
        })
    }
}
</script>

<style>

</style>
