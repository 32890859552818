<template>
  <div class="container">
    <b-row>
      <b-container>
        <h2 class="text-center py-2">
          {{labels.title}}
        </h2>
      </b-container>
    </b-row>
    <!-- <b-row>
      <b-container>
        <h4 class="text-center border-bottom">
          {{labels.subtitle}}
        </h4>
        <b-col md="8" class="mx-auto">
          <ul>
            <li v-for="(item, idx) in labels.instructive" :key="idx">{{ item }}</li>
          </ul>
        </b-col>
      </b-container>
    </b-row> -->
    <b-row v-if="!isLoading">
      <b-container>
        <b-col md="10" class="mx-auto"> 
          <b-button @click="goToCreateUser()" id="create-btn">{{
              labels.create
            }}</b-button>
        </b-col>
      </b-container>
      <!-- <div class="row p-1 justify-content-center"> -->
          <div class="col-md-4">
            <md-field md-inline>
              <label>{{labels.name}}</label>
              <md-input v-model="searchUser" @input="searchOnTableUser"></md-input>
            </md-field>
          </div>
          <div class="col-md-4">
            <md-field md-inline>
              <label>{{labels.email}}</label>
              <md-input v-model="searchEmail" @input="searchOnTableEmail"></md-input>
            </md-field>
          </div>
      <!-- </div> -->
      <b-container v-if="!isEmpty" class="pb-5">
        <!-- <h4 class="text-center">
          {{labels.info}}
        </h4> -->
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <!-- <th scope="col">{{labels.createdAt}}</th> -->
              <th scope="col">{{labels.name}}</th>
              <th scope="col">{{labels.email}}</th>
              <th scope="col">{{labels.informationUser}}</th>
              <th scope="col">{{labels.edit}}</th>
              <th scope="col">{{labels.inactivate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pro in datosPaginados" :key="pro.id">
              <!-- <td>{{ pro.createdAt }}</td> -->
              <td>{{ pro.name }}</td>
              <td>{{ pro.email }}</td>
              <td>
                <md-button @click="showInfo(pro.id)" class = "md-raised md-primary btn-info"><md-icon>menu</md-icon></md-button>
              </td>
              <td>
                <md-button @click="editUser(pro.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
              </td>
              <td>
                <md-button @click="inactivaUser(pro.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && users.length > 0 && !isLoading"
        >
          <h3>{{labels.notFound}}</h3>
          <p>{{labels.notFoundMessage}}: <span>{{ search }}</span></p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{labels.empty}}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <InfoUser :modInfoUser="showInfoUser" :dataRole="roles" :dataUser="inforUser" @cierraModal="cierraModal"/>
    <EditarUser :showDialogEdit="showDialogEdit" :formEdit="formEdit" @cierraModal="cierraModal"></EditarUser>
    <InacticaItem :InactivaItem="inactivateUser" @confirmDelete="confirmInactivate" @cierraModal="cierraModal"/>
  </div>
</template>

<script>
import User from "@/services/User";
import Rol from "@/services/Roles";
import InfoUser from "./InfoUser.vue";
import EditarUser from "./EditarUser.vue";
import InacticaItem from "../utils/InacticaItem.vue";
// import Select2 from 'v-select2-component';
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.name).includes(toLower(term))
    );
  }
  return items;
};

const searchByEmail = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.email).includes(toLower(term))
    );
  }
  return items;
};

export default {
  name: "ListUsers",
  components: {InfoUser,EditarUser,InacticaItem},

  data: () => ({
    rolSeleccionado: {},//<-- el seleccionado estará aquí
    myOptions: [],
    nameUser: '',
    identificacionUser: '',
    telefonoUser: '',
    correo: '',
    rolSelected: '',
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    showDialogEdit: false,
    dialogItem: {},
    users: [],
    roles: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "List.xls",
    elementosPorPagina:10,
    datosPaginados: [],
    paginaActual: 1,
    searchUser: null,
    searchEmail: null,
    showInfoUser: false,
    inactivateUser: false,
    idUserInactivar: "",
    inforUser: [],
    formEdit: {
      nameUser: '',
      cedula: '',
      identificacionUser: '',
      phone: '',
      telefonoUser: '',
      correo: '',
      idUser: '',
      id_rol: '',
      password : ''
    },
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "USUARIOS",
          subtitle: "Instrucciones",
          instructive: ["Bienvenid@ a la plataforma para la visualización y creación de usuarios"],
          info: "Información General",
          search: "Buscar por Nombre de Usuario",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Fecha Creación",
          name: "Nombre",
          email: "Correo Electrónico",
          informationUser: "Información",
          edit: "Editar",
          inactivate: "Inactivar",
          companyId: "Id de la Compañia",
          phonenumber: "Teléfono de contacto",
          idNumber: "Número de identificación",
          role: "Rol",
          empty: "Aún no cuentas con usuarios",
          create: "Crear",
          active: "Activo",
          inactive: "Inactivo",
          status: "Estado",
          notFoundMessage: "No hay datos con",
          identificationCard: "Cedula",
          phone: "Telefono",
          passw: "Password"
        }
      }else {
        return {
          title: "USERS",
          subtitle: "Instructions",
          instructive: ["Welcome to the platform for the visualization and creation of users."],
          info: "General Information",
          search: "Search by User Name",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Created Date",
          name: "User Name",
          email: "Email",
          informationUser: "Information",
          edit: "Edit",
          inactivate: "Inactivate",
          companyId: "Company Id",
          phonenumber: "Contact Number",
          idNumber: "Identification Id",
          role: "Role",
          empty: "You don't have users yet",
          create: "Create",
          active: "Activo",
          inactive: "Inactive",
          status: "Status",
          notFoundMessage: "There is no data with",
          identificationCard: "Identification Card",
          phone: "Phone",
          passw: "Password"
        }
      }
    }
  },
  methods: {
    cierraModal(val){
      this.showInfoUser = val
      this.showDialogEdit = val
      this.inactivateUser = val
    },
    goToCreateUser(){
      this.$router.push({path: '/users/create'})
    },
    showInfo(id) {
      User.getAllUsersById(id)
      .then((res) => {
        // console.log(res.data.exiteUser)
        this.inforUser = res.data.exiteUser
        this.showInfoUser = true
      })
      .catch((err) => {
        console.log(err)
      })
    },
    editUser(id){
      // console.log(id)
      this.showDialogEdit = true
      const currentIdx = this.users.findIndex((obj) => obj.id == id);
      this.dialogItem = this.users[currentIdx];
      this.formEdit.nameUser = this.dialogItem.name;
      this.formEdit.cedula = this.dialogItem.cedula;
      // this.formEdit.identificacionUser = this.dialogItem.idNumber;
      // this.formEdit.telefonoUser = this.dialogItem.phone;
      this.formEdit.correo = this.dialogItem.email;
      this.formEdit.phone = this.dialogItem.telefono;
      this.formEdit.id_rol = this.dialogItem.id_rol;
      this.formEdit.idUser = id;
    },
    editaInfoUser(){
      // console.log(this.formEdit)
      this.isLoading = true;
      User.updateUser(this.formEdit)
      .then(() => {
        // console.log(res)
        this.$toast.success("Usuario editado correctamente")
        location. reload()
        this.isLoading = false;
        this.showDialogEdit = false;
      })
      .catch(() =>{
        this.error = true;
      })

    },
    inactivaUser(id){
      // console.log(id)
      this.idUserInactivar = id
      this.inactivateUser = true
    },
    confirmInactivate(){
      User.inactivarUser(this.idUserInactivar)
      .then(() => {
        this.$toast.success("Usuario inactivado correctamente")
        this.inactivateUser = false
        location. reload()
        this.isLoading = false;
      })
      .catch((err) => console.log(err));
    },
    deleteUser(id) {
      this.isLoading = true;
      User.deleteUser(id).then(() => {
        this.isLoading = false;
      });
      this.users = this.users.filter( user => user.id != id );
      this.searched = this.users
    },
    customSort(value) {
      // console.log(value);
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }

        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTableUser() {
      this.searchEmail = '';
      this.datosPaginados = searchByName(this.users, this.searchUser);
      if(this.searchUser == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableEmail() {
      this.searchUser = '';
      this.datosPaginados = searchByEmail(this.users, this.searchEmail);
      if(this.searchEmail == ''){
        this.getDataPagina(1)
      }
    },
    totalPaginas(){
      return Math.ceil(this.searched.length / this.elementosPorPagina)
    },
    getDataPagina(noPagina){
      this.datosPaginados = [];
      this.paginaActual = noPagina;
      let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
      let fin = (noPagina * this.elementosPorPagina);

      this.datosPaginados = this.searched.slice(ini,fin);
    },
    getPreviousPage(){
      if(this.paginaActual > 1){
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage(){
      if(this.paginaActual < this.totalPaginas()){
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    },
    /*getOptions(data){
      let optRol = {};
      for(let ind=0; ind < data.length; ind++){
        optRol = { id: data[ind].id, text: data[ind].nombre }
        this.myOptions.push(optRol)
      }
      // console.log(this.myOptions)
    },*/
  },
  mounted() {
    this.isLoading = true;
    User.getAllUsers(this.$store.state.userName)
      .then((result) => {
        // console.log(result.data)
        this.users = result.data.users;
        this.searched = this.users;
        this.getDataPagina(1);
        if (this.users.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });

    Rol.getAllRoles()
    .then((rta) => {
      // console.log(rta.data.roles)
      this.roles = rta.data.roles;
      // this.getOptions(rta.data.roles);
    })
    .catch(() => {
      // console.log(error);
      this.error = true;
    })
    .finally(() => {
      this.isLoading = false;
    });
  },
};
</script>

<style src="../main.css">
</style>
