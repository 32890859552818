import Api from '@/services/Api'

export default {
	getTasaCambio(){
		return Api().get('tasaCambio')
	},
	createTasaCambio (form){
		return Api().post('tasaCambio', form)
	},
	grabeTasa (form){
		return Api().post('tasaCambio/update', form)
	},
	modificaTasaPatrimonio (form){
		return Api().post('tasaPatrimonio/update', form)
	},
	grabeTasaPatrimonio (form){
		return Api().post('tasaPatrimonio/insert', form)
	},
	leaCuentasPatrimonio(companyId,tipo_cuenta) {
		return Api().get('cuentasPatrimonio/'+companyId+'/'+tipo_cuenta)
	},
	leaGrupoPatrimonio(companyId,tipo_cuenta) {
		return Api().get('grupoPatrimonio/'+companyId+'/'+tipo_cuenta)
	},
	leaGrupoPatrimonioTasas(datos) {
		return Api().post('grupoPatrimonioTasas',datos)
	},
	grabeCuentas (cuentas){
		return Api().post('cuentasPatrimonio/update', cuentas)
	},
}
