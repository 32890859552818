<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogCreate" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-col md="8" lg="12" class="p-2 mx-auto">
                        <b-form-group @submit.prevent="editInfoRol">
                        <label>{{labels.name}} </label>
                        <md-field>
                            <md-input type="text" v-model="nombre"></md-input>
                        </md-field><br>
                        <label>{{labels.description}}</label>
                        <md-field>
                            <md-textarea v-model="descripcion" md-autogrow></md-textarea>
                        </md-field>
                    </b-form-group>
                </b-col>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="guardaInfoRol()">{{ labels.guardar }}</md-button>
                    <md-button class="md-accent" @click="closeModal()">{{ labels.cerrar }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import Roles from '../../services/Roles';

export default {
    name:'Create',
    props:['showDialogCreate'],
    data: () => ({
        nombre : "",
        descripcion : "",
    }),
    computed: {
        labels() {
            if (this.$store.state._language == "es-ES") {
                return {
                    title : 'Crear Rol',
                    name : "Nombre:",
                    description : "Descripción:",
                    guardar : 'Guardar',
                    cerrar : 'Cerrar'
                }
            }else{
                return {
                    title : 'Create Rol',
                    name : "Name:",
                    description : "Description:",
                    guardar : 'Save',
                    cerrar : 'Close'
                }
            }
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModal',false)
        },
        guardaInfoRol(){
            if(this.nombre == ""){
                this.$toast.error('Diligenciar el nombre')
            
            }else if(this.descripcion == ""){
                this.$toast.error('Diligenciar la descripción')

            }else{
                let infoForm = {
                    'nombre' : this.nombre,
                    'descripcion' : this.descripcion,
                    'idUser' : this.$store.state.userId
                }
                // console.log(infoForm)

                Roles.createRol(infoForm)
                .then((res) => {
                    if(res.status == 201){
                        this.$toast.success('Rol creado.')
                        location. reload()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }
}
</script>

<style>

</style>