<template>
    <div class="container">
        <md-dialog :md-active.sync="modCreaReporte" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.numero}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="number" v-model="numero" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.nombre}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="text" v-model="nombre" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.principal}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <select v-model="principal" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option value="1">{{ labels.yes }}</option>
                            <option value="0">{{ labels.not }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaReporte">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import TipoReporte from '../../../services/TipoReporte'

export default {
    name:'CreaReporte',
    props:['modCreaReporte'],
    data:() => ({
        numero : '',
        nombre : '',
        principal : '',
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Crear reporte',
                    save : 'Guardar',
                    close : 'Cerrar',
                    numero : 'Número:',
                    nombre : 'Nombre:',
                    principal : 'Principal:',
                    select : 'Seleccione...',
                    yes : 'SI',
                    not : 'NO'
                }
            }else{
                return {
                    title : 'Create report',
                    save : 'Save',
                    close : 'Close',
                    numero : 'Number:',
                    nombre : 'Name:',
                    principal : 'Principal:',
                    select : 'Select...',
                    yes : 'YES',
                    not : 'NO'
                }
            }
        }
    },
    methods:{
        guardaReporte(){
            if(this.numero == '' || this.nombre == '' || this.principal == ''){
                this.$toast.error('Campo(s) pendiente(s) por diligenciar');
                
            }else{                
                let dataReport = {
                    'numero' : this.numero,
                    'nombre' : this.nombre,
                    'principal' : this.principal,
                    'idUser' : this.$store.state.userId
                }
                // console.log(dataReport);

                TipoReporte.createReporte(dataReport)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    this.$emit('refrescaTbl')
                    this.clearCampos()
                })
                .catch((err) => {
                    console.log(err);
                    // this.$toast.error(err.response.data.message)
                })
            }
        },
        closeModal(){
            this.$emit('closeModal',false)
        },
        clearCampos(){
            this.numero = ""
            this.nombre = ""
            this.principal = ""
        }
    }
}
</script>

<style>

</style>