<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center py-1">
                    {{labels.title}}
                </h2>
            </b-container>
        </b-row>
        <b-row>
            <b-container>
                <b-col md="10" class="mx-auto"> 
                    <b-button @click="goToModalCompany()" id="create-btn">{{labels.create}}</b-button>
                </b-col>
            </b-container>
        </b-row>
        <b-row class="py-2">
            <div class="row justify-content-left">
                <div class="col-md-12">
                    <md-field md-inline>
                    <label>{{labels.nameCompany}}</label>
                    <md-input v-model="searchCompany" @input="searchOnTableCompany"></md-input>
                    </md-field>
                </div>
            </div>
            <b-container v-if="!isEmpty" class="pb-5">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left">{{labels.name}}</th>
                            <th scope="col">{{labels.currency}}</th>
                            <th scope="col">{{labels.acronym}}</th>
                            <th scope="col">{{labels.code}}</th>
                            <th scope="col">{{labels.subcidiary}}</th>
                            <th scope="col">{{labels.edit}}</th>
                            <th scope="col">{{labels.inactivate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="company in datosPaginados" :key="company.id">
                            <td class="text-left">{{ company.nombre  }}</td>
                            <td>{{ getCurrency(company.id_moneda) }}</td>
                            <td>{{ company.acronym }}</td>
                            <td>{{ company.codigo }}</td>
                            <td>
                                <md-button @click="infoCompany(company.id)" class = "md-raised md-primary btn-info"><md-icon>menu</md-icon></md-button>
                            </td>
                            <td>
                                <md-button @click="editarCompany(company.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                            </td>
                            <td>
                                <md-button @click="InactivateCompany(company.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">{{ labels.previous }}</a></li>
                        <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                        <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">{{labels.next}}</a></li>
                    </ul>
                </nav>
            </b-container>
        </b-row>
        <NewCompany :showDialog="showDialog" @clicked="closeModal"/>
        <Edit :showDialogEdit="showDialogEdit" @clicked="closeModal" :Item="itemSelected"/>
        <ListSubsidiary :showDialogInfo="showDialogInfo" @clicked="closeModal" :Item="listadoSubsidiary" :companyName="datosCompany"/>
    </div>
</template>
<script>

    import Company from '@/services/Company'
    import Currency from '@/services/Currency'
    import Subsidiary from '@/services/Subsidiary'
    import NewCompany from '../company/NewCompany.vue'
    import Edit from '../company/Edit.vue'
    import ListSubsidiary from '../company/ListSubsidiary.vue'

    const toLower = (text) => {
        return text.toString().toLowerCase();
    };

    const searchByCompany = (items, term) => {
        if (term) {
            return items.filter((item) =>
                toLower(item.nombre).includes(toLower(term))
            );
        }
        return items;
    };

    export default {
        name : "Manage",

        components: {
            NewCompany,
            Edit,
            ListSubsidiary
        },

        data: () => ({
            searchCompany : null,
            isEmpty: false,
            isLoading: false,
            listCompany: [],
            listCurrency: [],
            showDialog: false,
            showDialogEdit: false,
            showDialogInfo: false,
            elementosPorPagina:10,
            datosPaginados: [],
            paginaActual: 1,
            itemSelected : {},
            listadoSubsidiary : [],
            datosCompany: {}
        }),
        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Compañía",
                        create: "Crear",
                        nameCompany: "Compañía",
                        name: "Nombre",
                        currency: "Moneda",
                        acronym: "Acrónimo",
                        code: "Código",
                        subcidiary: "Subsidiaria",
                        edit: "Editar",
                        inactivate: "Inactivar",
                        previous: "Ant.",
                        next: "Sig.",
                    }
                }else{
                    return {
                        title: "Company",
                        create: "Create",
                        nameCompany: "Company",
                        name: "Name",
                        currency: "Currency",
                        acronym: "Acronym",
                        code: "Code",
                        subcidiary: "Subsidiary",
                        edit: "Edit",
                        inactivate: "Inactivate",
                        previous: "Previous",
                        next: "Next",
                    }
                }
            }
        },
        methods: {
            goToModalCompany(){
                // this.$router.push({path: 'tasaCambio/create'})
                // console.log("dasdas")
                this.showDialog = true;
            },
            editarCompany(id){
                this.showDialogEdit = true;
                // this.itemSelected = id

                Company.getCompanyById(id)
                .then((rta) => {
                    // console.log(rta.data.company[0])
                    this.itemSelected = rta.data.company[0];
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            InactivateCompany(id){
                // console.log('ID: '+id)

                Company.inactivateCompany(id)
                .then((res) => {
                    // console.log(rta)
                    this.$toast.success(res.data.message)
                    location.reload()
                })
                .catch((err) => {
                    console.log(err)
                })

            },
            infoCompany(id){
                // console.log(id)

                Subsidiary.getAllSubsidiaryByComp(id)
                .then((res) => {
                    // console.log(res)
                    this.listadoSubsidiary = res.data.subsidiary                    
                })
                .catch((err) => {
                    console.log(err)
                })
                this.showDialogInfo = true

                Company.getCompanyById(id)
                .then((res) => {
                    // console.log(res)
                    this.datosCompany = res.data.company[0]
                })
                .catch((err) => {
                    console.log(err)
                })
            },
            closeModal(value){
                this.showDialog = value;
                this.showDialogEdit = value;
                this.showDialogInfo = value;
            },
            searchOnTableCompany(){
                this.datosPaginados = searchByCompany(this.listCompany, this.searchCompany);
                if(this.searchCompany == ''){
                    this.getDataPagina(1)
                }
            },
            totalPaginas(){
                return Math.ceil(this.listCompany.length / this.elementosPorPagina)
            },
            getDataPagina(noPagina){
                this.datosPaginados = [];
                this.paginaActual = noPagina;
                let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
                let fin = (noPagina * this.elementosPorPagina);

                this.datosPaginados = this.listCompany.slice(ini,fin);
            },
            getPreviousPage(){
                if(this.paginaActual > 1){
                    this.paginaActual--;
                }
                this.getDataPagina(this.paginaActual);
            },
            getNextPage(){
                if(this.paginaActual < this.totalPaginas()){
                    this.paginaActual++;
                }
                this.getDataPagina(this.paginaActual);
            },
                isActive(noPagina){
                return noPagina == this.paginaActual ? 'active':'';
            },
            getCurrency(id){
                // console.log(id)
                let objMoneda = this.listCurrency.find((obj) => obj.id === id)
                return objMoneda.nombre
            }
        },
        mounted(){

            Company.getAllCompanies()
            .then((result) => {
                // console.log(result.data.companies)
                this.listCompany = result.data.companies
                this.getDataPagina(1);
            })
            .catch((error) => {
                console.log(error)
            })

            Currency.getAllCurrency()
            .then((result) => {
                this.listCurrency = result.data.currency
                // console.log(this.listCurrency)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
</script>
<style src="../main.css"></style>
