<template>
    <div class="container">
        <md-dialog :md-active.sync="modEditarSubmenu" :md-click-outside-to-close="false">
            <!-- {{ item }} -->
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.name}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="item[0].nombre"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="item[0].descripcion"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.route}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="item[0].ruta"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.orden}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="item[0].orden"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.menu}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-select v-model="item[0].id_menu">
                                <md-option v-for="menu in listaMenu" :key="menu.id" :value="menu.id">{{ menu.nombre }}</md-option>
                            </md-select>
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardarSubMenu">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="cierraModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import Menu from '../../../services/Menu';
import SubMenu from '../../../services/SubMenu';

export default {
    name:"EditarSubmenu",
    props:['modEditarSubmenu','item'],
    data: () => ({
        nombre:"",
        descripcion:"",
        ruta:"",
        menuSelected:"",
        listaMenu:[],
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Editar submenu",
                    name : "Nombre:",
                    description : "Descripción:",
                    route : "Ruta:",
                    menu : "Menú:",
                    save : "Guardar",
                    close : "Cerrar",
                    orden : "Orden",
                }
            }else{
                return {
                    title : "Edit submenu",
                    name : "Name:",
                    description : "Description:",
                    route : "Route:",
                    menu : "Menu:",
                    save : "Save",
                    close : "Close",
                    orden : "Order",
                }
            }
        }
    },
    methods:{
        guardarSubMenu(){
            // console.log("guardarSubMenu")
            let dataForm = {
                'id' : this.item[0].id,
                'nombre' : this.item[0].nombre,
                'descripcion' : this.item[0].descripcion,
                'ruta' : this.item[0].ruta,
                'menu' : this.item[0].id_menu,
                'orden' : this.item[0].orden,
                'idUser' : this.$store.state.userId,
            }
            // console.log(dataForm)

            if(this.item[0].nombre == ""){
                this.$toast.error('Diligenciar el campo nombre')
            
            }else if(this.item[0].descripcion == ""){
                this.$toast.error('Diligenciar el campo descripcion')

            }else if(this.item[0].ruta == ""){
                this.$toast.error('Diligenciar el campo ruta')

            }else if(this.item[0].id_menu == ""){
                this.$toast.error('Diligenciar el campo menu')

            }else if(this.item[0].orden == ""){
                this.$toast.error('Diligenciar el campo Orden')

            }else{
                SubMenu.updateSubmenu(dataForm)
                .then((res) => {
                    if(res.status == 200){
                        this.$toast.success(res.data.message)
                        location.reload();
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        cierraModal(){
            this.$emit('cierraModal',false)
        }
    },
    mounted(){
        Menu.getAllMenu()
        .then((res) => {
            // console.log(res.data)
            this.listaMenu = res.data.listMenu
        })
        .catch((err) => console.log(err))
    }
}
</script>

<style>

</style>
