import Api from '@/services/Api'

export default {
    getAllLevelFirst(){
        return Api().get('getAllLevelFirst')
    },
    getFirstLevelById(primer_nivel){
        return Api().get('getFirstLevelById/'+ primer_nivel)
    },
    getFirstLevelByReporte(reporte){
        return Api().get('getFirstLevelByReporte/'+ reporte)
    },
    createLevelFirst(form){
        return Api().post('levelFirst/create',form)
    },
    updatePrimerNivel(form){
        return Api().post('levelFirst/update',form)
    }

}
