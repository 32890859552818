<template>
    <div>
        <b-row v-if="!success && !error">
            <b-col md="7" lg="5" class="mt-5 mx-auto">
                <b-form @submit.prevent="onSubmit" id="create-form">
                <div class="content">
                    <h4>{{ labels.greeting }}</h4>
                    <p>{{ labels.info }}</p>
                </div>
                <b-form-group :class="{ formLoading: isLoading }">
                    <md-field>
                        <label>{{ labels.currency }}</label>
                        <md-input
                            type="text"
                            name="name"
                            v-model="form.nombre"
                            :placeholder="labels.currency"
                            required
                        />
                    </md-field>
                    <md-field>
                        <label>{{ labels.acronym }}</label>
                        <md-input
                            type="text"
                            name="acronym"
                            v-model="form.acronimo"
                            :placeholder="labels.acronym"
                            required
                        />
                    </md-field>
                    <md-field>
                        <label>{{ labels.url }}</label>
                        <md-input
                            type="text"
                            name="url"
                            v-model="form.url"
                            :placeholder="labels.url"
                            required
                        />
                    </md-field>
                    <b-button type="submit" id="login-btn">{{ labels.login }}</b-button>
                </b-form-group>
                <div class="loadingSpinner" :class="{ active: isLoading }">
                    <b-spinner></b-spinner>
                </div>
                </b-form>
            </b-col>
        </b-row>
        <b-container v-else class="pt-5">
            <b-col md="2" class="mx-auto">
                <div
                class="success-svg mb-4"
                :class="{ active: success }"
                v-if="!error"
                >
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 496 496"
                    style="enable-background:new 0 0 496 496;"
                    xml:space="preserve"
                >
                    <path
                    d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z
                            M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"
                    style="fill: #00ad7e"
                    />
                    <path
                    d="M370.344,158.344L208,320.688l-82.344-82.344c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116
                            l88,88c3.124,3.123,8.188,3.123,11.312,0l168-168c3.07-3.178,2.982-8.242-0.196-11.312
                            C378.359,155.35,373.444,155.35,370.344,158.344z"
                    style="fill: #00ad7e"
                    />
                </svg>
                </div>
                <div class="success-svg mb-4" :class="{ active: error }" v-else>
                <svg
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 52 52"
                    style="enable-background:new 0 0 52 52;"
                    xml:space="preserve"
                    sodipodi:docname="error.svg"
                    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
                >
                    <metadata id="metadata43">
                    <rdf:RDF
                        ><cc:Work rdf:about=""
                        ><dc:format>image/svg+xml</dc:format
                        ><dc:type
                            rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title></dc:title></cc:Work
                    ></rdf:RDF>
                    </metadata>
                    <defs id="defs41" />
                    <sodipodi:namedview
                    pagecolor="#ffffff"
                    bordercolor="#666666"
                    borderopacity="1"
                    objecttolerance="10"
                    gridtolerance="10"
                    guidetolerance="10"
                    inkscape:pageopacity="0"
                    inkscape:pageshadow="2"
                    inkscape:window-width="1920"
                    inkscape:window-height="1013"
                    id="namedview39"
                    showgrid="false"
                    inkscape:zoom="4.5384615"
                    inkscape:cx="26"
                    inkscape:cy="26"
                    inkscape:window-x="-9"
                    inkscape:window-y="-9"
                    inkscape:window-maximized="1"
                    inkscape:current-layer="Capa_1"
                    />
                    <g id="g6" style="fill:#ee6363;fill-opacity:0.94117647">
                    <path
                        d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                        id="path2"
                        style="fill-opacity:0.94117647"
                    />
                    <path
                        d="M35.707,16.293c-0.391-0.391-1.023-0.391-1.414,0L26,24.586l-8.293-8.293c-0.391-0.391-1.023-0.391-1.414,0   s-0.391,1.023,0,1.414L24.586,26l-8.293,8.293c-0.391,0.391-0.391,1.023,0,1.414C16.488,35.902,16.744,36,17,36   s0.512-0.098,0.707-0.293L26,27.414l8.293,8.293C34.488,35.902,34.744,36,35,36s0.512-0.098,0.707-0.293   c0.391-0.391,0.391-1.023,0-1.414L27.414,26l8.293-8.293C36.098,17.316,36.098,16.684,35.707,16.293z"
                        id="path4"
                        style="fill-opacity:0.94117647"
                    />
                    </g>
                    <g id="g8"></g>
                    <g id="g10"></g>
                    <g id="g12"></g>
                    <g id="g14"></g>
                    <g id="g16"></g>
                    <g id="g18"></g>
                    <g id="g20"></g>
                    <g id="g22"></g>
                    <g id="g24"></g>
                    <g id="g26"></g>
                    <g id="g28"></g>
                    <g id="g30"></g>
                    <g id="g32"></g>
                    <g id="g34"></g>
                    <g id="g36"></g>
                </svg>
                </div>
            </b-col>
            <b-col md="8" class="mx-auto justify-content-around">
                <h4 v-if="errorMessage">{{ labels.error }}</h4>
                <span
                    type="button"
                    id="reset-btn"
                    v-if="success"
                    @click="resetForm()"
                >{{ labels.new }}</span>
                <span type="button" id="check-btn" v-if="error" @click="checkForm()">{{labels.validate}}</span>
                <span type="button" id="resend-btn" v-if="error" @click="onSubmit()">{{labels.try}}</span>
            </b-col>
        </b-container>
    
    </div>
</template>
<script>
import Currency from "@/services/Currency";
export default {
    data: () => ({
        form: {
            nombre: "",
            acronimo: "",
            url: ""
        },
        isLoading: false,
        success: false,
        error: false,
        errorMessage: false,
    
    }),
    methods: {
        onSubmit(){
            this.isLoading = true;

            this.form.idUser = this.$store.state.userId

            Currency.createCurrency(this.form)
            .then(() => {
                this.success = true;
                this.error = false;
                this.errorMessage = false;
            })
            .catch((err) => {
                this.success = false;
                this.error = true;
                if (err.response.status == 412) {
                    this.errorMessage = true;
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        checkForm: function() {
            this.error = false;
        },
        resetForm: function() {
            this.form.nombre = "";
            this.form.acronimo = "";
            this.form.url = "";
            
            this.success = false;
            this.error = false;
        },
    },
    computed: {
        labels() {
            if (this.$store.state._language == "es-ES") {
                return {
                    greeting: "Crea una Moneda",
                    info: "Diligencie los datos",
                    currency: "Moneda",
                    acronym: "Acrónimo",
                    url: "URL",
                    language: "Idioma de la invitación",
                    login: "Crear",
                    new: "Crear una nueva moneda",
                    try: "volver a intentar",
                    validate: "Validar el formulario",
                    error: "El moneda ya se encuentra inscrito"
                };
            } else {
                return {
                    greeting: "Let's Create a New Currency",
                    info: "Fill the fields",
                    currency: "Currency",
                    acronym: "Acronym",
                    url: "URL",
                    language: "Invite Email Language",
                    login: "Create",
                    new: "Create a new currency",
                    try: "try again",
                    validate: "validate form",
                    error: "This currency is already in the system"
                };
            }
        },
    },
}
</script>
<style src="../main.css"></style>
