<template>
    <div class="container">
        <div class="d-flex justify-content-end">
            <!-- <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="editVariasCuentas()">
                <md-icon>edit</md-icon>
            </md-button> -->
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaNivDos()">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="headNivUno">{{ labels.levelDos }}</div>
        <div class="bodyNivUno">
            <select name="" id="" class="form-control" v-model="optSelDos">
                <option value="">{{ labels.select }}</option>
                <option v-for="nivel in listNivelDos" :key="nivel.id" :value="nivel.nivel_2">{{ nivel.nivel_2 }} . {{ nivel.descripcion }}</option>
            </select>
        </div>
        <CreaNivelDosVue :showCreaNivDos="showCreaNivDos" :nivelUnoSelect="nivelUnoSel" @closeModal="cierraModal" @refresNivelDos="refrescaSel"></CreaNivelDosVue>
    </div>
</template>

<script>
import CreaNivelDosVue from './CreaNivelDos.vue'
import Nivel from '../../services/Nivel';

export default {
    name:"NivelDos",
    data:() => ({
        listNivelDos: [],
        optSelDos: "",
        showCreaNivDos: false
    }),
    components:{
        CreaNivelDosVue
    },
    props:['infoNivDos','nivelUnoSel'],
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Niveles EFE",
                    levelDos: "Nivel II",
                    select: "Seleccione..."
                }
            }else{
                return {
                    title: "Levels EFE",
                    levelDos: "Level II",
                    select: "Select..."
                }
            }
        }
    },
    methods:{
        creaNivDos(){
            this.showCreaNivDos = true
        },
        cierraModal(val){
            this.showCreaNivDos = val
        },
        refrescaSel(valRuta){
            if(valRuta == "directo"){
                
                Nivel.getLevTwoByLevOne(this.nivelUnoSel)
                .then((res) => {
                    // console.log(res.data.nivelDosEfe)
                    this.listNivelDos = res.data.nivelDosEfe
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.showCreaNivDos = false
                })
            }else{

                Nivel.getLevTwoByLevOneInd(this.nivelUnoSel)
                .then((res) => {
                    // console.log(res.data.nivelDosEfe)
                    this.listNivelDos = res.data.nivelDosEfe
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.showCreaNivDos = false
                })
            }
        }
    },
mounted(){

},
watch:{
        optSelDos(levelDos){
            // console.log("optSelDos",levelDos)
            this.$emit('envLevDos',levelDos)
        },
        infoNivDos(valNew){
		//console.log("infoNivDos",valNew)
		this.optSelDos=''
		if	(valNew.length >= 0){
			this.listNivelDos = this.infoNivDos
		}
	}
}
}
</script>

<style src="../main.css">

</style>
