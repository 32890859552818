<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogEdit" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">{{labels.title}}</md-dialog-title>
            <md-dialog-content>
            <b-col md="4" lg="12" class="p-4 mx-auto">
                <b-form-group @submit.prevent="editaInfoUser">
                <label>{{labels.name}} </label>
                <md-field>
                    <md-input v-model="formEdit.nameUser" type="text"></md-input>
                </md-field><br>
                <label>{{labels.identificationCard}} </label>
                <md-field>
                    <md-input v-model="formEdit.cedula" type="text"></md-input>
                </md-field><br>
                <label>{{labels.email}} </label>
                <md-field>
                    <md-input v-model="formEdit.correo" type="text"></md-input>
                </md-field><br>
                <label>{{labels.phone}} </label>
                <md-field>
                    <md-input v-model="formEdit.phone" type="text"></md-input>
                </md-field><br>
                <label>{{labels.role}} </label>
                <md-field>
                    <md-select v-model="formEdit.id_rol">
                        <md-option v-for="rol in listaRol" :key="rol.id" :value="rol.id">{{ rol.nombre }}</md-option>
                    </md-select>
                </md-field><br>
                <label>{{labels.passw}} </label>
                <md-field>
                    <md-input v-model="formEdit.password" type="password" required></md-input>
                </md-field><br>
                </b-form-group>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="editaInfoUser()">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="cierraModal()">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </b-col>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import Roles from '../../services/Roles';
import User from '../../services/User';

export default {
    name:"EditarUser",
    props:['showDialogEdit','formEdit'],
    data: () => ({
        listaRol : []
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Editar usuario",
                    save : "Guardar",
                    close : "Cerrar",
                    name : "Nombre",
                    identificationCard : "Cedula",
                    email : "Correo",
                    phone : "Telefono",
                    role : "Rol",
                    passw : "Contraseña"
                }
            }else{
                return {
                    title : "Edit user",
                    save : "Save",
                    close : "Close",
                    name : "Name",
                    identificationCard : "Identification card",
                    email : "Email",
                    phone : "Phone",
                    role : "Role",
                    passw : "Password"
                }
            }
        }
    },
    methods:{
        editaInfoUser(){
            // console.log("editaInfoUser")
            User.updateUser(this.formEdit)
            .then(() => {
                // console.log(res)
                this.$toast.success("Usuario editado correctamente")
                location.reload()
            })
            .catch(() =>{
                this.error = true;
            })
        },
        cierraModal(){
            this.$emit('cierraModal',false)
        }
    },
    mounted(){
        Roles.getAllRoles()
        .then((res) => {
            // console.log(res.data.roles)
            this.listaRol = res.data.roles
        })
        .catch((err) =>{console.log(err)})
    }
}
</script>

<style>

</style>