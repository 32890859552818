<template>
  <div>
    <b-row v-if="!success && !error">
      <b-col md="7" lg="5" class="mt-5 mx-auto">
        <b-form @submit.prevent="onSubmit" id="create-form">
          <div class="content">
            <h4>{{ labels.greeting }}</h4>
            <p>{{ labels.info }}</p>
          </div>
          <b-form-group :class="{ formLoading: isLoading }">
            <md-field>
              <label>{{ labels.concept }}</label>
              <md-input
                type="text" name="concept"
                v-model="form.concept"
                :placeholder="labels.concept"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.documentType }}</label>
              <md-input
                type="text" name="documentType"
                v-model="form.documentType"
                :placeholder="labels.documentType"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nii }}</label>
              <md-input
                type="text" name="nii"
                v-model="form.nii"
                :placeholder="labels.nii"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.name }}</label>
              <md-input
                type="text" name="name"
                v-model="form.name"
                :placeholder="labels.name"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.address }}</label>
              <md-input
                type="text" name="address"
                v-model="form.address"
                :placeholder="labels.address"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.departmentCode }}</label>
              <md-input
                type="text" name="departmentCode"
                v-model="form.departmentCode"
                :placeholder="labels.departmentCode"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.departmentName }}</label>
              <md-input
                type="text" name="departmentName"
                v-model="form.departmentName"
                :placeholder="labels.departmentName"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.mcpCode }}</label>
              <md-input
                type="text" name="mcpCode"
                v-model="form.mcpCode"
                :placeholder="labels.mcpCode"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.mcpName }}</label>
              <md-input
                type="text" name="mcpName"
                v-model="form.mcpName"
                :placeholder="labels.mcpName"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.country }}</label>
              <md-input
                type="text" name="country"
                v-model="form.country"
                :placeholder="labels.country"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.consultationDate }}</label>
              <md-input
                type="date" name="consultationDate"
                v-model="form.consultationDate"
                :placeholder="labels.consultationDate"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.retefuente1 }}</label>
              <md-input
                type="text" name="retefuente1"
                v-model="form.retefuente1"
                :placeholder="labels.retefuente1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameRetefuente1 }}</label>
              <md-input
                type="text" name="nameRetefuente1"
                v-model="form.nameRetefuente1"
                :placeholder="labels.nameRetefuente1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.retefuente2 }}</label>
              <md-input
                type="text" name="retefuente2"
                v-model="form.retefuente2"
                :placeholder="labels.retefuente2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameRetefuente2 }}</label>
              <md-input
                type="text" name="nameRetefuente2"
                v-model="form.nameRetefuente2"
                :placeholder="labels.nameRetefuente2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.reteiva }}</label>
              <md-input
                type="text" name="reteiva"
                v-model="form.reteiva"
                :placeholder="labels.reteiva"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.reteica1 }}</label>
              <md-input
                type="text" name="reteica1"
                v-model="form.reteica1"
                :placeholder="labels.reteica1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameReteica1 }}</label>
              <md-input
                type="text" name="nameReteica1"
                v-model="form.nameReteica1"
                :placeholder="labels.nameReteica1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.reteica2 }}</label>
              <md-input
                type="text" name="reteica2"
                v-model="form.reteica2"
                :placeholder="labels.reteica2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameReteica2 }}</label>
              <md-input
                type="text" name="nameReteica2"
                v-model="form.nameReteica2"
                :placeholder="labels.nameReteica2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa1 }}</label>
              <md-input
                type="text" name="tributa1"
                v-model="form.tributa1"
                :placeholder="labels.tributa1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa1 }}</label>
              <md-input
                type="text" name="nameTributa1"
                v-model="form.nameTributa1"
                :placeholder="labels.nameTributa1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa2 }}</label>
              <md-input
                type="text" name="tributa2"
                v-model="form.tributa2"
                :placeholder="labels.tributa2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa2 }}</label>
              <md-input
                type="text" name="nameTributa2"
                v-model="form.nameTributa2"
                :placeholder="labels.nameTributa2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa3 }}</label>
              <md-input
                type="text" name="tributa3"
                v-model="form.tributa3"
                :placeholder="labels.tributa3"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa3 }}</label>
              <md-input
                type="text" name="nameTributa3"
                v-model="form.nameTributa3"
                :placeholder="labels.nameTributa3"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa4 }}</label>
              <md-input
                type="text" name="tributa4"
                v-model="form.tributa4"
                :placeholder="labels.tributa4"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa4 }}</label>
              <md-input
                type="text" name="nameTributa4"
                v-model="form.nameTributa4"
                :placeholder="labels.nameTributa4"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa5 }}</label>
              <md-input
                type="text" name="tributa5"
                v-model="form.tributa5"
                :placeholder="labels.tributa5"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa5 }}</label>
              <md-input
                type="text" name="nameTributa5"
                v-model="form.nameTributa5"
                :placeholder="labels.nameTributa5"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.tributa6 }}</label>
              <md-input
                type="text" name="tributa6"
                v-model="form.tributa6"
                :placeholder="labels.tributa6"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.nameTributa6 }}</label>
              <md-input
                type="text" name="nameTributa6"
                v-model="form.nameTributa6"
                :placeholder="labels.nameTributa6"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.rtefte1 }}</label>
              <md-input
                type="text" name="rtefte1"
                v-model="form.rtefte1"
                :placeholder="labels.rtefte1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.rtefte2 }}</label>
              <md-input
                type="text" name="rtefte2"
                v-model="form.rtefte2"
                :placeholder="labels.rtefte2"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.ica1 }}</label>
              <md-input
                type="text" name="ica1"
                v-model="form.ica1"
                :placeholder="labels.ica1"
                required
              />
            </md-field>
            <md-field>
              <label>{{ labels.ica2 }}</label>
              <md-input
                type="text" name="ica2"
                v-model="form.ica2"
                :placeholder="labels.ica2"
                required
              />
            </md-field>
            <b-button type="submit" id="login-btn">{{ labels.submit }}</b-button>
          </b-form-group>
          <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-container v-else class="pt-5">
      <b-col md="2" class="mx-auto">
        <div
          class="success-svg mb-4"
          :class="{ active: success }"
          v-if="!error"
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 496 496"
            style="enable-background:new 0 0 496 496;"
            xml:space="preserve"
          >
            <path
              d="M248,0C111.033,0,0,111.033,0,248s111.033,248,248,248s248-111.033,248-248C495.841,111.099,384.901,0.159,248,0z
                     M248,480C119.87,480,16,376.13,16,248S119.87,16,248,16s232,103.87,232,232C479.859,376.072,376.072,479.859,248,480z"
              style="fill: #00ad7e"
            />
            <path
              d="M370.344,158.344L208,320.688l-82.344-82.344c-3.178-3.07-8.242-2.982-11.312,0.196c-2.994,3.1-2.994,8.015,0,11.116
                    l88,88c3.124,3.123,8.188,3.123,11.312,0l168-168c3.07-3.178,2.982-8.242-0.196-11.312
                    C378.359,155.35,373.444,155.35,370.344,158.344z"
              style="fill: #00ad7e"
            />
          </svg>
        </div>
        <div class="success-svg mb-4" :class="{ active: error }" v-else>
          <svg
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 52 52"
            style="enable-background:new 0 0 52 52;"
            xml:space="preserve"
            sodipodi:docname="error.svg"
            inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
          >
            <metadata id="metadata43">
              <rdf:RDF
                ><cc:Work rdf:about=""
                  ><dc:format>image/svg+xml</dc:format
                  ><dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"/><dc:title></dc:title></cc:Work
              ></rdf:RDF>
            </metadata>
            <defs id="defs41" />
            <sodipodi:namedview
              pagecolor="#ffffff"
              bordercolor="#666666"
              borderopacity="1"
              objecttolerance="10"
              gridtolerance="10"
              guidetolerance="10"
              inkscape:pageopacity="0"
              inkscape:pageshadow="2"
              inkscape:window-width="1920"
              inkscape:window-height="1013"
              id="namedview39"
              showgrid="false"
              inkscape:zoom="4.5384615"
              inkscape:cx="26"
              inkscape:cy="26"
              inkscape:window-x="-9"
              inkscape:window-y="-9"
              inkscape:window-maximized="1"
              inkscape:current-layer="Capa_1"
            />
            <g id="g6" style="fill:#ee6363;fill-opacity:0.94117647">
              <path
                d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
                id="path2"
                style="fill-opacity:0.94117647"
              />
              <path
                d="M35.707,16.293c-0.391-0.391-1.023-0.391-1.414,0L26,24.586l-8.293-8.293c-0.391-0.391-1.023-0.391-1.414,0   s-0.391,1.023,0,1.414L24.586,26l-8.293,8.293c-0.391,0.391-0.391,1.023,0,1.414C16.488,35.902,16.744,36,17,36   s0.512-0.098,0.707-0.293L26,27.414l8.293,8.293C34.488,35.902,34.744,36,35,36s0.512-0.098,0.707-0.293   c0.391-0.391,0.391-1.023,0-1.414L27.414,26l8.293-8.293C36.098,17.316,36.098,16.684,35.707,16.293z"
                id="path4"
                style="fill-opacity:0.94117647"
              />
            </g>
            <g id="g8"></g>
            <g id="g10"></g>
            <g id="g12"></g>
            <g id="g14"></g>
            <g id="g16"></g>
            <g id="g18"></g>
            <g id="g20"></g>
            <g id="g22"></g>
            <g id="g24"></g>
            <g id="g26"></g>
            <g id="g28"></g>
            <g id="g30"></g>
            <g id="g32"></g>
            <g id="g34"></g>
            <g id="g36"></g>
          </svg>
        </div>
      </b-col>
      <b-col md="8" class="mx-auto justify-content-around">
        <h4 v-if="errorMessage">{{ labels.error }}</h4>
        <span
          type="button"
          id="reset-btn"
          v-if="success"
          @click="resetForm()"
          >{{ labels.new }}</span
        >
        <span type="button" id="check-btn" v-if="error" @click="checkForm()">{{
          labels.validate
        }}</span>
        <span type="button" id="resend-btn" v-if="error" @click="onSubmit()">{{
          labels.try
        }}</span>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import Provider from "@/services/Provider";
export default {
    name:"CeatedProvider",

    data:() => ({
        form: {
            concept: "",
            documentType: "",
            nii: "",
            name: "",
            address: "",
            departmentCode: "",
            departmentName: "",
            mcpCode: "",
            mcpName: "",
            country: "",
            consultationDate: "",
            retefuente1: "",
            nameRetefuente1: "",
            retefuente2: "",
            nameRetefuente2: "",
            reteiva: "",
            reteica1: "",
            nameReteica1: "",
            reteica2: "",
            nameReteica2: "",
            tributa1: "",
            nameTributa1: "",
            tributa2: "",
            nameTributa2: "",
            tributa3: "",
            nameTributa3: "",
            tributa4: "",
            nameTributa4: "",
            tributa5: "",
            nameTributa5: "",
            tributa6: "",
            nameTributa6: "",
            rtefte1: "",
            rtefte2: "",
            ica1: "",
            ica2: "",
        },
        isLoading: false,
        success: false,
        error: false,
        errorMessage: false,
    }),
    methods:{
        onSubmit(){
            // console.log(this.form)
            this.isLoading = true;
            Provider.createProvider(this.form)
            .then(() => {
                this.success = true;
                this.error = false;
                this.errorMessage = false;
            })
            .catch((err) => {
                this.success = false;
                this.error = true;
                if (err.response.status == 412) {
                    this.errorMessage = true;
                }
            })
        },
        checkForm: function() {
            this.error = false;
            this.isLoading = false;
        },
        resetForm: function() {
            this.form = {
                concept: "",
                documentType: "",
                nii: "",
                name: "",
                address: "",
                departmentCode: "",
                departmentName: "",
                mcpCode: "",
                mcpName: "",
                country: "",
                consultationDate: "",
                retefuente1: "",
                nameRetefuente1: "",
                retefuente2: "",
                nameRetefuente2: "",
                reteiva: "",
                reteica1: "",
                nameReteica1: "",
                reteica2: "",
                nameReteica2: "",
                tributa1: "",
                nameTributa1: "",
                tributa2: "",
                nameTributa2: "",
                tributa3: "",
                nameTributa3: "",
                tributa4: "",
                nameTributa4: "",
                tributa5: "",
                nameTributa5: "",
                tributa6: "",
                nameTributa6: "",
                rtefte1: "",
                rtefte2: "",
                ica1: "",
                ica2: "",
            },
            this.isLoading = false;
            this.success = false;
            this.error = false;
        },
    },
    computed:{
        labels() {
            if (this.$store.state._language == "es-ES") {
                return {
                    greeting: "Crear un proveedor",
                    info: "Diligencie los datos",
                    provider: "Proveedor",
                    concept: "Concepto",
                    documentType: "Tipo de documento",
                    nii: "Número de identificación",
                    name: "Razon social",
                    address: "Dirección",
                    departmentCode: "Código del departamento",
                    departmentName: "Nombre del departamento",
                    mcpCode: "Código mcp",
                    mcpName: "Nombre mcp",
                    country: "País",
                    consultationDate: "Fecha Sarlaf",
                    retefuente1: "Retefuente (1)",
                    nameRetefuente1: "Nombre retefuente (1)",
                    retefuente2: "Retefuente (2)",
                    nameRetefuente2: "Nombre retefuente (2)",
                    reteiva: "Reteiva",
                    reteica1: "Reteica (1)",
                    nameReteica1: "Nombre reteica (1)",
                    reteica2: "Reteica (2)",
                    nameReteica2: "Nombre reteica (2)",
                    tributa1: "Código tributa (1)",
                    nameTributa1: "Nombre tributa (1)",
                    tributa2: "Código tributa (2)",
                    nameTributa2: "Nombre tributa (2)",
                    tributa3: "Código tributa (3)",
                    nameTributa3: "Nombre tributa (3)",
                    tributa4: "Código tributa (4)",
                    nameTributa4: "Nombre tributa (4)",
                    tributa5: "Código tributa (5)",
                    nameTributa5: "Nombre tributa (5)",
                    tributa6: "Código tributa (6)",
                    nameTributa6: "Nombre tributa (6)",
                    rtefte1: "Rtefte (1)",
                    rtefte2: "Rtefte (2)",
                    ica1: "Ica (1)",
                    ica2: "Ica (2)",
                    submit: "Crear",
                    new: "Crear uno nuevo",
                    try: "volver a intentar",
                    validate: "Validar el formulario",
                    error: "El proveedor ya existe",
                };
            } else {
                return {
                    greeting: "Create an provider",
                    info: "Fill the fields",
                    provider: "Provider",
                    concept: "Concept",
                    documentType: "Document type",
                    nii: "Identification number",
                    name: "Business name",
                    address: "Address",
                    departmentCode: "Department code",
                    departmentName: "Department name",
                    mcpCode: "mcp code",
                    mcpName: "mcp name",
                    country: "Country",
                    consultationDate: "Sarlaf Date",
                    retefuente1: "Retefuente (1)",
                    nameRetefuente1: "Retefuente name (1)",
                    retefuente2: "Retefuente (2)",
                    nameRetefuente2: "Retefuente name (2)",
                    reteiva: "Reteiva",
                    reteica1: "Reteica (1)",
                    nameReteica1: "Reteica name (1)",
                    reteica2: "Reteica (2)",
                    nameReteica2: "Reteica name (2)",
                    tributa1: "Tributa code (1)",
                    nameTributa1: "Tributa name (1)",
                    tributa2: "Tributa code (2)",
                    nameTributa2: "Tributa name (2)",
                    tributa3: "Tributa code (3)",
                    nameTributa3: "Tributa name (3)",
                    tributa4: "Tributa code (4)",
                    nameTributa4: "Tributa name (4)",
                    tributa5: "Tributa code (5)",
                    nameTributa5: "Tributa name (5)",
                    tributa6: "Tributa code (6)",
                    nameTributa6: "Tributa name (6)",
                    rtefte1: "Rtefte (1)",
                    rtefte2: "Rtefte (2)",
                    ica1: "Ica (1)",
                    ica2: "Ica (2)",
                    submit: "Create",
                    new: "Create a new one",
                    try: "try again",
                    validate: "validate form",
                    error: "Provider already exists",
                };
            }
        },
    },
    mounted(){
        this.isLoading = false;
    }
}
</script>

<style src="../main.css">
</style>