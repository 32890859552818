List
<template>
  <div class="container">
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{ labels.title }}
        </h1>
      </b-container>
    </b-row>
    <b-row>
      <b-container>
        <h4 class="text-center border-bottom">
          {{ labels.subtitle }}
        </h4>
        <b-col md="8" class="mx-auto">
          <ul>
            <li v-for="(item, idx) in labels.instructive" :key="idx">
              {{ item }}
            </li>
          </ul>
        </b-col>
      </b-container>
    </b-row>
    <FiltersInvoice @escucharHijo="variableHijo" @limpiaFiltro="clearTable" v-if="this.$route.name == 'list'"/>
    <b-row v-if="!isLoading">
      <md-table-toolbar>
            <md-button
              class="md-accent md-toolbar-section-end custom-filter"
              @click="showFilters = true"
              >{{ labels.customFilter }}
              <font-awesome-icon :icon="['fas', 'filter']"
            /></md-button>
          </md-table-toolbar>
      <md-button  class="md-fab md-fab-bottom-right md-fixed" id="graphsButton" @click="toggleGraphs()" v-if="this.$store.state.userRole != 'APP'">
        <font-awesome-icon :icon="['fas', 'chart-pie']" v-if="!showGraphs"/>
        <font-awesome-icon :icon="['fas', 'times']" v-if="showGraphs"/>
      </md-button>
      <b-container v-if="!showGraphs">
      <b-container> 
        <md-button class="md-raised md-primary" id="update-invoices-button" @click="extractInvoices()">{{labels.updateButton}}</md-button>
      </b-container>
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{ labels.info }}
        </h4>
        <b-row>
          <div class="col-md-3">
            <md-field md-inline>
              <label>Nit</label>
              <md-input v-model="searchNit" @input="searchOnTableNit"></md-input>
            </md-field>
          </div>
          <div class="col-md-3">
            <md-field md-inline>
              <label>{{labels.provider}}</label>
              <md-input v-model="searchProveedor" @input="searchOnTableProveedor"></md-input>
            </md-field>
          </div>
          <div class="col-md-3">
            <md-field md-inline>
              <label>{{labels.comment}}</label>
              <md-input v-model="searchComentario" @input="searchOnTableComentario"></md-input>
            </md-field>
          </div>
          <div class="col-md-3">
            <md-field md-inline>
              <label>Factura</label>
              <md-input v-model="searchFactura" @input="searchOnTableFactura"></md-input>
            </md-field>
          </div>
        </b-row>
        <div class="table-responsive justify-content-center" v-if="searched.length != 0">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">{{labels.locatedDate}}</th>
              <th scope="col">Nit</th>
              <th scope="col">{{labels.provider}}</th>
              <th scope="col" v-if="$route.name != 'pending'">{{labels.approver}}</th>
              <th scope="col">{{labels.invoiceDate}}</th>
              <th scope="col">{{labels.invoiceNumber}}</th>
              <th scope="col">{{labels.paymentValue}}</th>
              <th scope="col">{{labels.approved}}</th>
              <th scope="col">{{labels.isCaused}}</th>
              <th scope="col">{{labels.causedDate}}</th>
              <th scope="col">{{labels.causedNumber}}</th>
              <th scope="col">{{labels.isPayed}}</th>
              <th scope="col">{{labels.payDate}}</th>
              <th scope="col">{{labels.payNumber}}</th>
              <th scope="col">{{labels.comment}}</th>
              <th scope="col">{{labels.information}}</th>
              <th scope="col" v-if="roleUser == 'ADM'">{{labels.deleteInvoices}}</th>
              <th v-if="showBtnDelete == false && roleUser == 'ADM'"
                scope="col">{{labels.deleteMultipInvoices}} 
              </th>
              <th v-else-if="showBtnDelete == true">
                <md-button @click="deleMultipInvoi"> <span><font-awesome-icon :icon="['fas', 'trash']" style="color: #912710;"/></span> </md-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pro in datosPaginados" :key="pro.id">
              <td>
                <span v-if="$route.path == '/list'">{{ pro.createdAt}}</span>
                <span v-else>{{fechaFormat(pro.createdAt)}}</span>
              </td>
              <td>{{ pro.inscriptionId }}</td>
              <td>{{ pro.provider }}</td>
              <td v-if="$route.name != 'pending'">{{ getApproverName(pro.approvers) }}</td>
              <td>{{ pro.date }}</td>
              <td>{{ pro.number }}</td>
              <td>{{ pro.total }}</td>
              <td>{{ pro.status }}</td>
              <td>
                <span v-if="pro.caused">SI</span>
                <span v-else>NO</span>
              </td>
              <td>{{ pro.causedDate }}</td>
              <td>{{ pro.causedNumber }}</td>
              <td>
                <span v-if="pro.payed">SI</span>
                <span v-else>NO</span>
              </td>
              <td>{{ pro.payDate }}</td>
              <td>{{ pro.payNumber }}</td>
              <td>{{ pro.comment }}</td>
              <td>
                <md-button @click="showInfo(pro.id)" class = "md-raised md-primary btn-info"><md-icon>menu</md-icon></md-button>
              </td>
              <td v-if="roleUser == 'ADM'">
                <md-button @click="deleteInvoice(pro.id)" class = "md-raised md-primary btn-dangerr"><md-icon>delete</md-icon></md-button>
              </td>
              <td v-if="roleUser == 'ADM'">
                <md-checkbox 
                  v-model="delMultiInvoice"
                  @change="selMultipInvoice(pro.id)"
                  :value="pro.id"
                ></md-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <!-- <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav> -->
        <nav aria-label="Page navigation example" v-if="searched.length != 0">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <li v-for="pagina in pages.slice(paginaActual - 1, paginaActual + 9)" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <!-- <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li> -->
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
        <h5 v-else>{{msjSinReg}}</h5>
        <!-- <md-table
          v-model="searched"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          md-card
          id="list-invoices-table"
        >
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-start">
              <label>{{ labels.searchbar }}</label>
              <md-input v-model="search" @input="searchOnTable" />
            </md-field>
            <md-button
              class="md-accent md-toolbar-section-end custom-filter"
              @click="showFilters = true"
              >{{ labels.customFilter }}
              <font-awesome-icon :icon="['fas', 'filter']"
            /></md-button>
          </md-table-toolbar>

          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            @click="showInfo(item.id)"
            class="delete-icon-hover"
            :class="{ 'remove-effect': cantEdit }"
          >
            <md-table-cell class="td-icon" v-if="!cantEdit">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                @click="
                  deleteInvoice(item.id);
                  isDeletingInvoice = true;
                "
              />
            </md-table-cell>
            <md-table-cell
              :md-label="labels.locatedDate"
              md-sort-by="createdAt"
              >{{ item.createdAt }}</md-table-cell
            >
            <md-table-cell :md-label="labels.provider" md-sort-by="provider">{{
              item.provider
            }}</md-table-cell>  
            <md-table-cell
              :md-label="labels.locatedNumber"
              md-sort-by="located"
              >{{ item.located }}</md-table-cell
            >
            <md-table-cell
              :md-label="labels.approver"
              md-sort-by="approver"
              v-if="$route.name != 'pending'"
              >{{ getApproverName(item.approvers) }}</md-table-cell
            >
            <md-table-cell :md-label="labels.invoiceDate" md-sort-by="date">{{
              item.date
            }}</md-table-cell>
            <md-table-cell
              :md-label="labels.invoiceNumber"
              md-sort-by="number"
              >{{ item.number }}</md-table-cell
            >
            <md-table-cell :md-label="labels.paymentValue">
              {{ item.total }}
            </md-table-cell>
<md-table-cell :md-label="labels.approved" md-sort-by="approved">
              <span v-if="item.status == 'PND'" class="status pending">{{
                labels.status[0]
              }}</span>
              <span v-if="item.status == 'DEC'" class="status declined">{{
                labels.status[1]
              }}</span>
              <span v-if="item.status == 'APP'" class="status approved">{{
                labels.status[2]
              }}</span>
              <span v-if="item.status == 'CHK'" class="status checked">{{
                labels.status[3]
              }}</span>
            </md-table-cell>
            <md-table-cell :md-label="labels.isCaused">
              <span v-if="item.caused">SI</span>
              <span v-else>NO</span>
            </md-table-cell>
             <md-table-cell :md-label="labels.causedDate">
              {{ item.causedDate }}
            </md-table-cell>
            <md-table-cell :md-label="labels.causedNumber">
              {{ item.causedNumber }}
            </md-table-cell>
            <md-table-cell :md-label="labels.isPayed">
              <span v-if="item.payed">SI</span>
              <span v-else>NO</span>
            </md-table-cell>
            <md-table-cell :md-label="labels.payDate">
              {{ item.payDate }}
            </md-table-cell>
            <md-table-cell :md-label="labels.payNumber">
              {{ item.payNumber }}
            </md-table-cell>
            <md-table-cell :md-label="labels.comment">
              {{ item.comment }}
            </md-table-cell>         
          </md-table-row>
        </md-table> -->
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && invoices.length > 0 && !isLoading"
        >
          <h3>{{ labels.notFound }}</h3>
          <p>
            {{ labels.notFoundMessage }}:
            <span>{{ search }}</span>
          </p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{ labels.empty }}</span>
        </b-col>
      </b-container>
      </b-container>
      <b-container v-else>
        <Metrics :status="pieData" :invoice-per-approver="invoicesApprover" :payed-caused="payedAndCaused"/>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>

    <md-dialog :md-active.sync="showDialog" id="dialog-modal">
      <md-dialog-title
        ><strong>{{ labels.invoiceInformation }}</strong></md-dialog-title
      >
      <md-dialog-content>
        <md-table>
          <md-table-row>
            <md-table-cell>{{ labels.currency }}</md-table-cell>
            <md-table-cell>{{ dialogItem.currency }}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.beforeTax }}</md-table-cell>
            <md-table-cell>{{ dialogItem.beforeTax }}</md-table-cell>
          </md-table-row>
          <md-table-row
            v-if="
              dialogItem.xmlFile &&
                dialogItem.xmlFile.taxTotal &&
                typeof dialogItem.xmlFile.taxTotal.taxSubtotal.taxCategory ==
                  'object'
            "
          >
            <md-table-cell>{{
              dialogItem.xmlFile.taxTotal.taxSubtotal.taxCategory.taxScheme.name
            }}</md-table-cell>
            <md-table-cell>{{
              (
                (dialogItem.beforeTax *
                  dialogItem.xmlFile.taxTotal.taxSubtotal.taxCategory.percent) /
                100
              ).toFixed(2)
            }}</md-table-cell>
          </md-table-row>
          <!-- <md-table-row v-else class="taxes" v-for="(item,idx) in dialogItem.xmlFile.taxTotal.taxSubtotal.taxCategory" :key="'tax-'+idx">
            <md-table-cell>{{item.taxScheme.name}}</md-table-cell>
            <md-table-cell>{{(dialogItem.total * item.percent)/100}}</md-table-cell>
          </md-table-row> -->
          <md-table-row>
            <md-table-cell>{{ labels.totalTaxes }}</md-table-cell>
            <md-table-cell>{{ dialogItem.ivaTax }}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.paymentValue }}</md-table-cell>
            <md-table-cell>{{ dialogItem.total }}</md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.filepath">
            <md-table-cell>{{ labels.document }}</md-table-cell>
            <md-table-cell
              ><a :href="dialogItem.filepath" class="download" target="_blank">
                <font-awesome-icon :icon="['fas', 'paper-plane']"/></a
            ></md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.filepathPdf">
            <md-table-cell>{{ labels.documentPDF }}</md-table-cell>
            <md-table-cell
              ><a href="#" class="download" @click="download(dialogItem.id)">
                <font-awesome-icon :icon="['fas', 'file-download']"/></a
            ></md-table-cell>
          </md-table-row>
          <md-table-row
            v-if="
                ($store.state.userRole == 'ADM' ||
                  $store.state.userRole == 'COM' ||
                  $store.state.userRole == 'APP')
            "
          >
            <md-table-cell>{{ labels.approver }}</md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select
                  v-model="dialogItem.approvers"
                  multiple
                  @md-closed="updateInvoiceApprovers(dialogItem.id)"
                >
                  <md-option
                    v-for="(item, idx) in approvers"
                    :key="'approver-' + idx"
                    :value="item.id"
                    >{{
                      users[users.findIndex((obj) => obj.id == item.userId)]
                        .username
                    }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.approved }}</md-table-cell>
            <md-table-cell
              ><md-field>
                <md-select
                  v-model="dialogItem.status"
                  name="status"
                  :placeholder="labels.approved"
                  :disabled="cantEdit"
                  @md-selected="updateInvoice(dialogItem.id)"
                >
                  <md-option value="PND">{{ labels.status[0] }}</md-option>
                  <md-option value="DEC">{{ labels.status[1] }}</md-option>
                  <md-option value="APP">{{ labels.status[2] }}</md-option>
                  <md-option value="CHK">{{ labels.status[3] }}</md-option>
                </md-select></md-field
              ></md-table-cell
            >
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.comment }}</md-table-cell>
            <md-table-cell
              ><md-field>
                <md-input
                  v-model="dialogItem.comment"
                  :disabled="cantEdit"
                  @blur="updateInvoiceComment(dialogItem.id)"
                ></md-input> </md-field
            ></md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.isPayed }}</md-table-cell>
            <md-table-cell
              ><md-checkbox
                v-model="dialogItem.payed"
                :disabled="cantEdit"
                @change="updateInvoice(dialogItem.id)"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.payed">
            <md-table-cell>{{ labels.payNumber }}</md-table-cell>
            <md-table-cell
              ><md-field
                ><md-input
                  v-model="dialogItem.payNumber"
                  :disabled="cantEdit"
                  @blur="updateInvoice(dialogItem.id)"
                ></md-input
              ></md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.payed">
            <md-table-cell>{{ labels.payDate }}</md-table-cell>
            <md-table-cell>
              <date-picker
                v-model="dialogItem.payDate"
                :format="datePickerFormat"
                :value-type="datePickerFormat"
                :disabled="cantEdit"
                @change="updateInvoice(dialogItem.id)"
              ></date-picker>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>{{ labels.isCaused }}</md-table-cell>
            <md-table-cell
              ><md-checkbox
                v-model="dialogItem.caused"
                :disabled="cantEdit"
                @change="updateInvoice(dialogItem.id)"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.caused">
            <md-table-cell>{{ labels.causedNumber }}</md-table-cell>
            <md-table-cell
              ><md-field
                ><md-input
                  v-model="dialogItem.causedNumber"
                  :disabled="cantEdit"
                  @blur="updateInvoice(dialogItem.id)"
                ></md-input
              ></md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row v-if="dialogItem.caused">
            <md-table-cell>{{ labels.causedDate }}</md-table-cell>
            <md-table-cell>
              <date-picker
                v-model="dialogItem.causedDate"
                :format="datePickerFormat"
                :value-type="datePickerFormat"
                :disabled="cantEdit"
                @change="updateInvoice(dialogItem.id)"
              ></date-picker>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <h5 v-if="dialogItem.moreInfo.length > 0">Otros Campos</h5>
        <form @submit.prevent="updloadNewFields" enctype="multipart/form-data">
          <md-table>
            <md-table-row
              v-for="(item, idx) in dialogItem.moreInfo"
              :key="'custom-field-' + idx"
            >
              <md-table-cell>
                <md-field v-if="!item.type">
                  <md-select v-model="item.type" placeholder="Tipo de campo">
                    <md-option value="text">Texto / Numerico</md-option>
                    <md-option value="textarea">Texto Largo</md-option>
                    <!-- <md-option value="file">Archivo / Documento</md-option> -->
                  </md-select>
                </md-field>
                <span
                  v-if="!item.edit && item.type"
                  @dblclick="item.edit = true"
                >
                  {{ item.label }}
                </span>
                <md-field
                  v-if="item.edit && item.type"
                  class="delete-row-more-info"
                >
                  <span @click="dialogItem.moreInfo.splice(idx, 1)"
                    ><font-awesome-icon :icon="['fas', 'trash']"
                  /></span>
                  <md-input
                    v-model="item.label"
                    placeholder="Nombre del campo"
                    :disabled="cantEdit"
                    @blur="item.edit = false"
                  ></md-input>
                </md-field>
              </md-table-cell>
              <md-table-cell>
                <md-field v-if="item.type == 'text'">
                  <md-input
                    v-model="item.value"
                    :disabled="cantEdit"
                  ></md-input>
                </md-field>
                <md-field v-if="item.type == 'textarea'">
                  <md-textarea
                    v-model="item.value"
                    :disabled="cantEdit"
                  ></md-textarea>
                </md-field>
                <!-- <md-field v-if="item.type == 'file'">
                <md-file v-model="item.value" @md-change="item.value = this.$refs.file.files[0]"></md-file>
              </md-field> -->
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div
            class="text-center"
            v-if="
              $store.state.userRole == 'COM' ||
                $store.state.userRole == 'ADM' ||
                $store.state.userRole == 'APP'
            "
          >
            <h6 v-show="dialogMoreInfoError" class="invalid-login">
              Diligencia los campos que agregaste
            </h6>
            <b-row>
              <b-col>
                <md-button
                  v-if="dialogItem.moreInfo.length > 0"
                  class="md-raised md-accent"
                  type="submit"
                >
                  Guardar
                </md-button>
                <md-button class="md-primary" @click="addElementToInvoice()">
                  Crear uno nuevo
                </md-button></b-col
              >
            </b-row>
          </div>
        </form>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-accent" @click="showDialog = false">{{
          labels.close
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="showFilters" id="filters-modal">
      <md-dialog-title>
        <strong>{{ labels.customFilter }}</strong>
      </md-dialog-title>
      <md-dialog-content>
        <md-table>
          <md-table-row>
            <md-table-cell>
              {{ labels.filterLabels.status }}
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select v-model="filterValues.status" multiple>
                  <md-option value="PND">{{ labels.status[0] }}</md-option>
                  <md-option value="DEC">{{ labels.status[1] }}</md-option>
                  <md-option value="APP">{{ labels.status[2] }}</md-option>
                  <md-option value="CHK">{{ labels.status[3] }}</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              {{ labels.filterLabels.payed }}
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select v-model="filterValues.payed" multiple>
                  <md-option value="true">SI</md-option>
                  <md-option value="false">NO</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              {{ labels.filterLabels.caused }}
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select v-model="filterValues.caused" multiple>
                  <md-option value="true">SI</md-option>
                  <md-option value="false">NO</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              {{labels.comment}}
            </md-table-cell>
            <md-table-cell>
              <md-input type="text" class="inpFilt" v-model="filterValues.filtComment"></md-input>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              {{labels.filtInvoice}}
            </md-table-cell>
            <md-table-cell>
              <md-input type="text" class="inpFilt" v-model="filterValues.filtInvoice"></md-input>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>
              {{ labels.filterLabels.approver }}
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select v-model="filterValues.approver">
                  <md-option
                    v-for="(item, idx) in approvers"
                    :key="'approver-' + idx"
                    :value="item.id"
                    >{{
                      users[users.findIndex((obj) => obj.id == item.userId)]
                        .username
                    }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="clearFilters()">{{
          labels.clear
        }}</md-button>
        <md-button class="md-accent" @click="applyFilters()">{{
          labels.applyFilter
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Invoice from "@/services/Invoice";
import Approver from "@/services/Approver";
import User from "@/services/User";
import Metrics from "@/components/metrics/Metrics";
import FiltersInvoice from "./FiltersInvoice.vue";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
const toLower = (text) => {
  // console.log(text);
  // console.log(typeof(text));
  return String(text).toLowerCase();
};

const searchInput = (items, term) => {
  if (term) {
    return items.filter((item) =>
      (toLower(item.located).includes(toLower(term)) ||
      toLower(item.number).includes(toLower(term)) ||
      toLower(item.provider).includes(toLower(term)) ||
      toLower(item.payNumber).includes(toLower(term)) ||
      toLower(item.causedNumber).includes(toLower(term))
      )
    );
  }

  return items;
};

const searchByNit = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.inscriptionId).includes(toLower(term))
    );
  }
  return items;
};

const searchByProveedor = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.provider).includes(toLower(term))
    );
  }
  return items;
};

const searchByComentario = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.comment).includes(toLower(term))
    );
  }
  return items;
};

const searchByFactura = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.number).includes(toLower(term))
    );
  }
  // console.log(items)
  return items;
};

export default {
  name: "List",
  components: {
    DatePicker,
    Metrics,
    FiltersInvoice
  },
  data: () => ({
    showGraphs: false,
    showFilters: false,
    showMetrics: false,
    currentSort: "createdAt",
    currentSortOrder: "asc",
    datePickerFormat: "DD/MM/YYYY",
    invoices: [],
    invoicesList: [],
    approvers: [],
    searched: [],
    users: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    isDeletingInvoice: false,
    success: false,
    error: false,
    filterValues: {
      status: [],
      payed: [],
      caused: [],
      approver: null,
      filtComment: "",
      filtInvoice: ""
    },
    dialogMoreInfoError: false,
    dialogItem: {
      payDate: null,
      causedDate: null,
      moreInfo: [],
    },
    showDialog: false,
    pieData: {
      PND: 0,
      CHK: 0,
      APP: 0,
      DEC: 0
    },
    payedAndCaused: {
      "Pagada": 0,
      "Causada": 0,
      "Ambas": 0,
      "Ninguna": 0
    },
    elementosPorPagina:10,
    datosPaginados: [],
    paginaActual: 1,
    searchNit: null,
    searchProveedor: null,
    searchComentario: null,
    searchFactura: null,
    pages: [],
    msjSinReg: "No hay registros.",
    delMultiInvoice: [],
    showBtnDelete: false,
    roleUser: "",
  }),
  computed: {
    complement() {
      return this.$store.state.userRole == "COM";
    },
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Plataforma Para La Visualización De Facturas",
          subtitle: "Instrucciones",
          instructive: [
            "Bienvenidos a la plataforma para la visualización de facturas, lo invitamos a revisar la información de cada factura.",
          ],
          info: "Información de Facturas",
          searchbar: "Buscar",
          locatedNumber: "# Radicado",
          locatedDate: "Fecha Radicado",
          invoiceDate: "Fecha Factura",
          provider: "Proveedor",
          approver: "Aprobador",
          invoiceNumber: "# Factura",
          currency: "Moneda",
          beforeTax: "Valor antes de impuestos",
          iva: "IVA Facturado",
          impo: "Impoconsumo",
          otherTax: "Otros Impuestos",
          reteFuente: "RETEFUENTE",
          reteICA: "RETEICA",
          reteIVA: "RETEIVA",
          paymentValue: "Valor a Pagar",
          document: "Soporte",
          documentPDF: "Documento PDF",
          approved: "Estado",
          comment: "Comentarios",
          status: ["Pendiente", "Rechazado", "Aprobado", "Revisado"],
          isPayed: "Pagada",
          payNumber: "Número de pago",
          payDate: "Fecha de Pago",
          isCaused: "Causada",
          causedNumber: "Número de causación",
          causedDate: "Fecha de Causación",
          moreInfo: "Más Información",
          empty: "Aún no cuentas con facturas",
          invoiceInformation: "Información de la factura",
          notFound: "No se encontraron facturas",
          notFoundMessage: "No hay facturas con ese número",
          close: "Cerrar",
          totalTaxes: "Total Impuestos",
          customFilter: "Filtros",
          clear: "Limpiar Filtros",
          updateButton: "Actualizar Facturas del correo",
          filterLabels: {
            status: "Estado",
            payed: "Pagada",
            caused: "Causada",
            approver: "Aprobador",
          },
          applyFilter: "Aceptar y Cerrar",
          information: "Información",
          deleteInvoices: "Eliminar facturas",
          deleteMultipInvoices: "Eliminar varias facturas",
          filtInvoice: "# Factura",
        };
      } else {
        return {
          title: "Invoice Visualization Platform",
          subtitle: "Instructions",
          instructive: [
            "Welcome to the platform for the visualization of invoices, we invite you to review the information of each invoice.",
          ],
          info: "Invoices Information",
          searchbar: "Search",
          locatedNumber: "Filing #",
          locatedDate: "Filing Date",
          invoiceDate: "Invoice Date",
          provider: "Provider",
          approver: "Aprobador",
          invoiceNumber: "Invoice #",
          currency: "Currency",
          beforeTax: "Value before taxes",
          iva: "Invoiced VAT",
          impo: "Impoconsumo",
          otherTax: "Other taxes",
          reteFuente: "RETEFUENTE",
          reteICA: "RETEICA",
          reteIVA: "RETEIVA",
          paymentValue: "Value to be paid",
          document: "Document",
          documentPDF: "PDF Document",
          approved: "Status",
          comment: "Comments",
          isPayed: "Pagada",
          payNumber: "Número de pago",
          payDate: "Payment Date",
          isCaused: "Causada",
          causedNumber: "Número de causación",
          causedDate: "Fecha de Causación",
          moreInfo: "Más Información",
          status: ["Pending", "Declined", "Approved", "Checked"],
          empty: "You don't have invoices yet",
          invoiceInformation: "Invoice Information",
          notFound: "No invoices found",
          notFoundMessage: "There isn't invoices with that number",
          close: "Close",
          totalTaxes: "Taxes Total",
          customFilter: "Filters",
          clear: "Clear",
          updateButton: "Update Email Invoices",
          filterLabels: {
            status: "Status",
            payed: "Payed",
            caused: "Caused",
            approver: "Approver",
            information: "Information"
          },
          applyFilter: "Apply & Close",
          information: "Information",
          deleteInvoices: "Delete invoices",
          deleteMultipInvoices: "Delete multiple invoices",
          filtInvoice: "# Invoice",
        };
      }
    },
    cantEdit() {
      return (
        this.$store.state.userRole == "CHK" ||
        this.$store.state.userRole == "AGN"
      );
    },
  },
  methods: {
     toggleGraphs(){
      this.showGraphs = !this.showGraphs
      if(this.showGraphs){
        this.pieData = {
          PND: 0,
          APP: 0,
          DEC: 0,
          CHK: 0
        }
        this.payedAndCaused = {
          "Pagada": 0,
          "Causada": 0,
          "Ambas": 0,
          "Ninguna": 0
        }
        this.invoicesApprover = {}
        this.searched.forEach(obj => {
          this.pieData[obj.status] = (this.pieData[obj.status] || 0) + 1
          if(obj.payed && obj.caused){
            this.payedAndCaused["Ambas"] = this.payedAndCaused["Ambas"] + 1 
          }else if(obj.payed && !obj.caused){
            this.payedAndCaused["Pagada"] = this.payedAndCaused["Pagada"] + 1 
          }else if(!obj.payed && obj.caused){
            this.payedAndCaused["Causada"] = this.payedAndCaused["Causada"] + 1 
          }else{
            this.payedAndCaused["Ninguna"] = this.payedAndCaused["Ninguna"] + 1 
          }
            if(obj.approvers.length > 0) {
              obj.approvers.forEach(item => {
                let tempUserId = this.approvers.filter(app => app.id == item)[0].userId
                let tempUserName = this.users.filter(user => user.id == tempUserId)[0].username
                // this.approversCount[item] = (this.approversCount[item] || 0) + 1
                this.invoicesApprover[tempUserName] = (this.invoicesApprover[tempUserName] || 0) + 1
              })
            }else{
                this.invoicesApprover["No Asignadas"] = (this.invoicesApprover["No Asignadas"] || 0 )+ 1
            }
          }
        )
        console.log(this.payedAndCaused)
      }
    },
    applyFilters() {
      this.searched = this.invoicesList
      this.searched = this.searched.filter((item) => {
        // console.log(item)
        return (this.filterValues.status.includes(item.status) || this.filterValues.status.length == 0) && 
               (this.filterValues.payed.includes(item.payed.toString()) || this.filterValues.payed.length == 0) && 
               (this.filterValues.caused.includes(item.caused.toString()) || this.filterValues.caused.length == 0) &&
               (this.filterValues.filtInvoice.includes(item.number.toString()) || this.filterValues.filtInvoice.length == 0) &&
               (this.filterValues.filtComment.includes(item.comment) || this.filterValues.filtComment.length == 0) && 
               ((item.approvers && item.approvers.includes(this.filterValues.approver)) || this.filterValues.approver == null) 
      });
      this.getDataPagina(1)
      this.totalPaginas()
      this.showFilters = false;
    },
    extractInvoices(){
      Invoice.startNewProcess()
        .then(() => {
          console.log("Started")
          this.$toast.success("Proceso Iniciado Correctamente")
        })
        .catch(() => {
          console.log("Failed to start")
          this.$toast.error("Hay un proceso pendiente por finalizar")
        })
    },
    clearFilters() {
      this.filterValues = {
        status: [],
        payed: [],
        caused: [],
        approver: null,
        filtInvoice: null,
        filtComment: null
      };
      this.searched = this.invoices
    },
    getApproverName(arrApprovers) {
      let strApprovers = [];
      if (arrApprovers.length > 0) {
        arrApprovers.forEach((approverId) => {
          const idxApprover = this.approvers.findIndex(
            (obj) => obj.id == approverId
          );
          const idxUser = this.users.findIndex(
            (obj) => obj.id == this.approvers[idxApprover].userId
          );
          strApprovers.push(this.users[idxUser].username);
        });
      }
      return strApprovers.join(", ");
    },
    showInfo(id) {
      if (!this.isDeletingInvoice) {
        if(this.$route.name == "pending"){
          const currentIdx = this.invoices.findIndex((obj) => obj.id == id);
          this.dialogItem = this.invoices[currentIdx];
          this.showDialog = true;
        }else{
          const currentIdList = this.invoicesList.findIndex((obj) => obj.id == id);
          this.dialogItem = this.invoicesList[currentIdList];
          this.showDialog = true;
        }
      }
    },
    download(id) {
      Invoice.download(id).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + ".pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    updateInvoice(id) {
      // console.log(this.invoices.filter(obj => obj.id == id)[0])
      const invoiceBody = {
        status: this.dialogItem.status,
        payed: this.dialogItem.payed,
        payNumber: this.dialogItem.payNumber,
        payDate: this.dialogItem.payDate,
        caused: this.dialogItem.caused,
        causedNumber: this.dialogItem.causedNumber,
        causedDate: this.dialogItem.causedDate,
      };
      // console.log(invoiceBody);
      Invoice.updateInvoiceById(id, invoiceBody)
        .then(() => {
          // this.success = true;
          // console.log(result)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateInvoiceApprovers(id) {
      const assignedValue = this.dialogItem.approvers.length > 0 ? true : false;
      Invoice.updateInvoiceById(id, {
        approvers: this.dialogItem.approvers,
        assigned: assignedValue,
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    updloadNewFields() {
      this.dialogMoreInfoError = false;
      this.dialogItem.moreInfo.forEach((element) => {
        if (!element.label) {
          this.dialogMoreInfoError = true;
        }
      });
      if (!this.dialogMoreInfoError) {
        const id = this.dialogItem.id;
        Invoice.updateInvoiceById(id, { moreInfo: this.dialogItem.moreInfo })
          // Invoice.updateInvoiceById(id, formData)
          .then(() => {
            // this.success = true;
            // console.log(result)
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    addElementToInvoice() {
      this.dialogItem.moreInfo.push({
        type: null,
        label: null,
        value: null,
        edit: true,
      });
    },
    updateInvoicePayment(id) {
      const currentInv = this.invoices.findIndex((obj) => obj.id == id);
      const date = this.invoices[currentInv].date;
      // this.isLoading = true;
      Invoice.updatePaydate(id, date)
        .then(() => {})
        .catch(() => {
          //console.log(error);
          const currentInv = this.invoices.findIndex((obj) => obj.id == id);
          this.invoices[currentInv].payDate = null;

          // this.error = true;
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    updateInvoiceComment(id) {
      if(this.$route.name == "pending"){
        const currentInv = this.invoices.findIndex((obj) => obj.id == id);
        const comment = this.invoices[currentInv].comment;
        Invoice.updateComment(id, comment)
        .then(() => {})
        .catch(() => {
          //console.log(error);
          const currentInv = this.invoices.findIndex((obj) => obj.id == id);
          this.invoices[currentInv].comment = null;
        });
      }else{
        const currentInv = this.invoicesList.findIndex((obj) => obj.id == id);
        const comment = this.invoicesList[currentInv].comment;
        Invoice.updateComment(id, comment)
        .then(() => {})
        .catch(() => {
          //console.log(error);
          const currentInv = this.invoices.findIndex((obj) => obj.id == id);
          this.invoices[currentInv].comment = null;
        });
      }
    },
    deleteInvoice(id) {
      if (!this.cantEdit) {
        this.showDialog = false;
        this.isLoading = true;
        Invoice.deleteInvoiceById(id)
          .then((result) => {
            console.log(result)
            // this.invoices = this.invoices.filter((el) => el.id != id);
            // this.searched = this.invoices;
            // if (this.invoices.length == 0) {
            //   this.isEmpty = true;
            // }

            this.invoicesList = this.invoicesList.filter((el) => el.id != id);
            console.log(this.invoicesList)
            this.searched = this.invoicesList;
            if (this.invoicesList.length == 0) {
              this.isEmpty = true;
            }
          })
          .catch(() => {
            // console.log(error);
            this.error = true;
          })
          .finally(() => {
            this.isLoading = false;
            this.isDeletingInvoice = false;
          });
      } else {
        this.isDeletingInvoice = false;
      }
    },
    customSort(value) {
      // console.log(value);
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searched = searchInput(this.invoices, this.search);
    },
    searchOnTableNit() {
      this.searchProveedor = '';
      this.searchComentario = '';
      this.searchFactura = '';
      if(this.$route.name == "list"){
        this.datosPaginados = searchByNit(this.invoicesList, this.searchNit);
      }else{
        this.datosPaginados = searchByNit(this.invoices, this.searchNit);
      }
      if(this.searchNit == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableProveedor(){
      this.searchNit = '';
      this.searchComentario = '';
      this.searchFactura = '';
      if(this.$route.name == "list"){
        this.datosPaginados = searchByProveedor(this.invoicesList, this.searchProveedor);
      }else{
        this.datosPaginados = searchByProveedor(this.invoices, this.searchProveedor);
      }

      if(this.searchProveedor == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableComentario(){
      this.searchNit = '';
      this.searchProveedor = '';
      this.searchFactura = '';
      if(this.$route.name == "list"){
        this.datosPaginados = searchByComentario(this.invoicesList, this.searchComentario);
      }else{
        this.datosPaginados = searchByComentario(this.invoices, this.searchComentario);
      }

      if(this.searchComentario == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableFactura(){
      this.searchNit = '';
      this.searchProveedor = '';
      this.searchComentario = '';
      if(this.$route.name == "list"){
        this.datosPaginados = searchByFactura(this.invoicesList, this.searchFactura)
      }else{
        this.datosPaginados = searchByFactura(this.invoices, this.searchFactura)
      }

      if(this.searchFactura == ''){
        this.getDataPagina(1)
      }
    },
    totalPaginas(){
      let numOfPage = Math.ceil(this.searched.length / this.elementosPorPagina)
      this.pages = []
      for (let i = 1; i <= numOfPage; i++){
        // console.log(i)
        this.pages.push(i)
      }
      return numOfPage
      // return Math.ceil(this.searched.length / this.elementosPorPagina)
    },
    getDataPagina(noPagina){
      this.datosPaginados = [];
      this.paginaActual = noPagina;
      let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
      let fin = (noPagina * this.elementosPorPagina);

      this.datosPaginados = this.searched.slice(ini,fin);
    },
    getPreviousPage(){
      if(this.paginaActual > 1){
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage(){
      if(this.paginaActual < this.totalPaginas()){
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    },
    fechaFormat(fecha){
      let year = fecha.slice(6)
      let month = fecha.slice(3,-5)
      let day = fecha.slice(0,-8)
      return year+'-'+month+'-'+day
    },
    variableHijo(val){
      console.log(val.length)
      if(val.length != 0){

        this.searched = val;
        this.invoicesList = val;
        this.getDataPagina(1);
        this.totalPaginas()
      
      }else{
        this.msjSinReg
      }
    },
    clearTable(val){
      this.searched = val;
      this.invoicesList = val;
      this.msjSinReg = "";
    },
    selMultipInvoice(){
      // console.log(this.delMultiInvoice)
      if(this.delMultiInvoice.length != 0){
        this.showBtnDelete = true
      }else{
        this.showBtnDelete = false
      }
    },
    deleMultipInvoi(){
      console.log(this.delMultiInvoice)
      Invoice.deleMultipInvoice(this.delMultiInvoice)
      .then(() => {
        // console.log(res.data)
        this.$toast.success("Factura(s) eliminada(s)")
        location. reload()
      })
      .catch((e) =>{
        console.log(e)
      })
      .finally(() => {

      })
    }
  },
  mounted() {
    this.isLoading = true;
    this.roleUser = this.$store.state.userRole;
    Approver.getAll()
      .then((result) => {
        this.approvers = result.data.data;
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      });
    User.getAllUsers()
      .then((result) => {
        // console.log(result.data)
        this.users = result.data.users;
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      });
    if (this.$route.name == "pending") {
      Invoice.getAllPending()
        .then((result) => {
          // console.log(result)
          this.invoices = result.data.invoices;
          this.searched = this.invoices;
          this.getDataPagina(1);
          if (this.invoices.length == 0) {
            this.isEmpty = true;
          }
        })
        .catch(() => {
          // console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      /*Invoice.getAll()
        .then((result) => {
          // console.log(result)
          this.invoices = result.data.invoices;
          if (this.$store.state.userRole == "APP") {
            const approverId = this.approvers[this.approvers.findIndex(obj => obj.userId == this.$store.state.userId)].id
            this.invoices = this.invoices.filter((el) =>
              el.approvers.includes(approverId)
            );
          }
          if (this.invoices.length == 0) {
            this.isEmpty = true;
          }
          // this.searched = this.invoices;
          // this.getDataPagina(1);
        })
        .catch(() => {
          // console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
        });*/
        this.isLoading = false;
    }
  },
  watch: {
    invoices(){
      this.totalPaginas();
    }
  }
};
</script>

<style src="../main.css" scoped>
form {
  width: 100%;
}
</style>

