<template>
	<div class="container">
		<b-container class="pb-5">
			<div class="headDestino"><h3><b><i>{{f_titulo()}}</i></b></h3></div>
			<div class="bodyDestino">

			<md-table v-model="infoDestino" md-card md-fixed-header @md-selected="f_muestra">
				<md-table-row slot="md-table-row" slot-scope="{ item } " md-selectable="single">
					<md-table-cell :md-label="labels.cuenta" class="text-left border">{{item.cuenta}}</md-table-cell>
					<md-table-cell :md-label="labels.descripcion" class="text-left border" >{{ item.descripcion }}</md-table-cell>
					<md-table-cell :md-label="labels.saldo_actual" class="text-right border" >{{ formato(item.saldo_actual) }}</md-table-cell>
					<md-table-cell :md-label="labels.funcional1" class="text-right border" >{{ formato(item.funcional1) }}</md-table-cell>
					<md-table-cell :md-label="labels.funcional2" class="text-right border" >{{ formato(item.funcional2) }}</md-table-cell>
					<md-table-cell :md-label="labels.ajuste_actual" class="text-right border" >{{ formato(item.ajuste_actual) }}</md-table-cell>
					<md-table-cell :md-label="labels.ajuste_funcional1" class="text-right border" >{{ formato(item.ajuste_funcional1) }}</md-table-cell>
				</md-table-row>
			</md-table>
			</div>
			<md-dialog :md-active.sync="showDialogEdit">
				<md-dialog-title class="text-center"><h3><b><i>{{f_titulo()}}<br>{{labels.origen}}</i></b></h3></md-dialog-title>
				<md-dialog-content>
					<b-form-group @submit.prevent="editaInfoBalance">
					<b-row><h4><b-col cols="2"><b><i>{{labels.cuenta}}</i></b></b-col></h4>
					<b-col cols="2"><h4>{{formEdit.cuenta}}</h4></b-col>
					<b-col cols="8"><h4>{{formEdit.descripcion}}</h4></b-col></b-row>
					<b-row><b-col cols="2"><h4><b><i>{{labels.saldo_actual}}</i></b></h4></b-col>
					<b-col cols="2"></b-col>
					<b-col cols="8"><h4>{{formEdit.saldo_actual}}</h4></b-col></b-row>

					<b-row><b-col cols="4"><h4><b><i>{{labels.funcional1}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.promedio1}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.interna1}}</i></b></h4></b-col></b-row>
					<b-row><b-col cols="4" class="border"><h4>{{formEdit.funcional1}}</h4></b-col>
					<b-col cols="4" class="border"><h4>{{formEdit.promedio1}}</h4></b-col>
					<b-col cols="4" class="border"><h4>{{formEdit.interna1}}</h4></b-col></b-row>

					<b-row><b-col cols="4"><h4><b><i>{{labels.funcional2}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.promedio2}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.interna2}}</i></b></h4></b-col></b-row>
					<b-row><b-col cols="4" class="border"><h4>{{formEdit.funcional2}}</h4></b-col>
					<b-col cols="4" class="border"><h4>{{formEdit.promedio2}}</h4></b-col>
					<b-col cols="4" class="border"><h4>{{formEdit.interna2}}</h4></b-col></b-row>


					<b-row><b-col cols="3"><h4><b><i>{{labels.ajuste_actual}}</i></b></h4></b-col>
					<b-col cols="1"></b-col>
					<b-col v-if="!eliminar" cols="8" class="col-lg-3 border" >
						<md-field> <md-input v-model="v_ajuste_actual" type="text" v-on:blur="f_ajuste_actual"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="8" class="col-lg-3 border">
						<md-field> <md-input v-model="v_ajuste_actual" type="text" readonly></md-input> </md-field>
					</b-col>
					</b-row>

					<b-row><b-col cols="4"><h4><b><i>{{labels.ajuste_funcional1}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.ajuste_promedio1}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.ajuste_interna1}}</i></b></h4></b-col></b-row>
					<b-row>
					<b-col v-if="!eliminar" cols="4" class="col-lg-3 border" >
						<md-field> <md-input v-model="v_ajuste_funcional1" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field> <md-input v-model="v_ajuste_funcional1" type="text" readonly></md-input> </md-field>
					</b-col>
					<b-col v-if="!eliminar" cols="4" class="border" >
						<md-field> <md-input v-model="v_ajuste_promedio1" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field> <md-input v-model="v_ajuste_promedio1" type="text" readonly></md-input> </md-field>
					</b-col>
					<b-col v-if="!eliminar" cols="4" class="border" >
						<md-field> <md-input v-model="v_ajuste_interna1" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field><md-input v-model="v_interna1" type="text" readonly></md-input> </md-field>
					</b-col>
					</b-row>
					<b-row><b-col cols="4"><h4><b><i>{{labels.ajuste_funcional2}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.ajuste_promedio2}}</i></b></h4></b-col>
					<b-col cols="4"><h4><b><i>{{labels.ajuste_interna2}}</i></b></h4></b-col></b-row>
					<b-row>
					<b-col v-if="!eliminar" cols="4" class="col-lg-3 border" >
						<md-field> <md-input v-model="v_ajuste_funcional2" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field> <md-input v-model="v_ajuste_funcional2" type="text" readonly></md-input> </md-field>
					</b-col>
					<b-col v-if="!eliminar" cols="4" class="border" >
						<md-field> <md-input v-model="v_ajuste_promedio2" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field> <md-input v-model="v_ajuste_promedio2" type="text" readonly></md-input> </md-field>
					</b-col>
					<b-col v-if="!eliminar" cols="4" class="border" >
						<md-field> <md-input v-model="v_ajuste_interna2" type="text"></md-input> </md-field>
					</b-col>
					<b-col v-if="eliminar" cols="4" class="border">
						<md-field><md-input v-model="v_interna2" type="text" readonly></md-input> </md-field>
					</b-col>
					</b-row>

					</b-form-group>
					<md-dialog-actions>
						<md-button v-if="!eliminar" class="md-primary" @click="f_restaurar()">Restaurar</md-button>
						<md-button v-if="!eliminar" class="md-primary" @click="grabeAjuste()">Guardar</md-button>
						<md-button v-if="eliminar" class="md-primary" @click="elimineAjuste()">Eliminar</md-button>
						<md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
			<md-dialog :md-active.sync="showContraAjuste">
				<md-dialog-title class="text-center">{{f_titulo()}}<br>{{labels.origen}}</md-dialog-title>
				<md-dialog-content>
					<b-form-group @submit.prevent="editaInfoBalance">
					<b-row><b-col><h4>{{labels.cuenta}}</h4></b-col>
					<b-col cols="8"><md-field><md-input v-model="formEdit.cuenta" type="text" ></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.descripcion}}</h4></b-col>
					<b-col cols="8"><md-field><md-input v-model="formEdit.descripcion" type="text" readonly></md-input> </md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.ajuste_actual}}</h4></b-col>
					<b-col cols="8"><md-field> <md-input v-model="valor_ajustar_actual" type="text" readonly></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.ajuste_funcional}}</h4></b-col>
					<b-col cols="8"><md-field> <md-input v-model="valor_ajustar_funcional1" type="text" readonly></md-input></md-field></b-col></b-row>
					</b-form-group>
					<md-dialog-actions>
						<md-button class="md-primary" @click="grabeAjuste()">Guardar</md-button>
						<md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</b-container>
	</div>
</template>
<script>
import Balance from "@/services/BalanceNiif"


export default {
name: "EliminaDestino",

data: () => ({
	optDestino: [],
	showDialogEdit : false,
	showContraAjuste : false,
	formEdit: { },
	v_ajuste_actual : '',
	v_ajuste_funcional1: '',
	v_ajuste_promedio1: '',
	v_ajuste_interna1: '',
	v_ajuste_funcional2: '',
	v_ajuste_promedio2: '',
	v_ajuste_interna2: '',
	porcentaje: 1,
	vc_cuenta: "99999999",
	vc_descripcion : "Cuenta controlante",
	vc_saldo_actual : 0,
	vc_funcional1 : 0,
	vc_ajuste_actual : 0,
	vc_ajuste_funcional1 : 0,
	valor_ajustar_actual: 0,
	valor_ajustar_funcional1: 0,
	eliminar: false,
}),
props:[	'infoDestino',
	'infoOpcion',
	'infoEmpresa',
	'infoTipo',
	'infoVigencia',
	'infoSecuencia',
	'infoValorAjustarActual',
	'infoValorAjustarFuncional1'],
computed:{
	labels() {
		if (this.$store.state._language == "es-ES") {
			return {
				destino : "Cuenta Controlada",
				cuenta : "Cuenta",
				descripcion : "Descripcion",
				saldo_actual: "Saldo",
				funcional1 : "Funcional",
				funcional2 : "Funcional2",
				promedio1 : "Promedio",
				promedio2 : "Promedio2",
				interna1 : "Interna",
				interna2 : "Interna2",
				ajuste_actual : "Ajuste Saldo",
				ajuste_funcional1 : "Ajuste Funcional",
				ajuste_promedio1 : "Ajuste Promedio",
				ajuste_interna1 : "Ajuste Interna",
				ajuste_funcional2 : "Ajuste Funcional2",
				ajuste_promedio2 : "Ajuste Promedio2",
				ajuste_interna2 : "Ajuste Interna2",
				opcion : "Modificar",
                                ajustar : "Ajustar",
			}
		} else {
			return {
				destino : "Controling Accounts",
				cuenta : "Account",
				descripcion : "Description",
				saldo_actual: "Saldo",
				funcional1 : "Funcional",
				funcional2 : "Funcional2",
				promedio1 : "Average",
				promedio2 : "Average2",
				interna1 : "Internal",
				interna2 : "Internal2",
				ajuste_actual : "Balance Adjustment",
				ajuste_funcional1 : "Funcional Adjustment",
				ajuste_promedio1 : "Average Adjustment",
				ajuste_interna1 : "Internal Adjustment",
				ajuste_funcional2 : "Funcional Adjustment2",
				ajuste_promedio2 : "Average Adjustment2",
				ajuste_interna2 : "Internal Adjustment2",
				opcion : "Modify",
				ajustar : "Adjust",
			}
		}
	}
},
methods: {
	f_titulo() {
		let titulo = this.infoEmpresa+" "+this.infoTipo
		return titulo
	},
	formato(numero) {
		return  new Intl.NumberFormat('en-US',{ maximumFractionDigits: 0}).format(numero)
	},
	f_muestra(r_destino) {
		if	(this.infoOpcion == 2) {
			this.porcentaje = 1
			if	(r_destino.saldo_actual != 0) {
				this.porcentaje = r_destino.funcional1/r_destino.saldo_actual
			} else {
				if	(r_destino.ajuste_actual != 0) 
					this.porcentaje = r_destino.ajuste_funcional1/r_destino.ajuste_actual
			}
			this.eliminar=false
			if	(r_destino.origen == "C") {
				this.eliminar=true
			}
			this.formEdit = r_destino
			this.v_ajuste_actual = this.formEdit.ajuste_actual
			this.v_ajuste_funcional1 = this.formEdit.ajuste_funcional1
			this.v_ajuste_promedio1 = this.formEdit.ajuste_promedio1
			this.v_ajuste_interna1 = this.formEdit.ajuste_interna1
			this.v_ajuste_funcional2 = this.formEdit.ajuste_funcional2
			this.v_ajuste_promedio2 = this.formEdit.ajuste_promedio2
			this.v_ajuste_interna2 = this.formEdit.ajuste_interna2
			this.showDialogEdit = true
		}
	},
	f_ajuste_actual() {
		if	(this.v_ajuste_actual == "0") {
			this.v_ajuste_funcional1 = 0
			this.v_ajuste_promedio1 = 0
			this.v_ajuste_interna1 = 0
			this.v_ajuste_funcional2 = 0
			this.v_ajuste_promedio2 = 0
			this.v_ajuste_interna2 = 0
		} else	{
			let porcentaje = parseFloat(this.v_ajuste_actual)/parseFloat(this.formEdit.saldo_actual)
			porcentaje = Math.abs(porcentaje)
			this.v_ajuste_actual=-Math.round(this.formEdit.saldo_actual*porcentaje*100)/100
			this.v_ajuste_funcional1=-Math.round(this.formEdit.funcional1*porcentaje*100)/100
			this.v_ajuste_promedio1=-Math.round(this.formEdit.promedio1*porcentaje*100)/100
			this.v_ajuste_interna1=-Math.round(this.formEdit.interna1*porcentaje*100)/100
			this.v_ajuste_funcional2=-Math.round(this.formEdit.funcional2*porcentaje*100)/100
			this.v_ajuste_promedio2=-Math.round(this.formEdit.promedio2*porcentaje*100)/100
			this.v_ajuste_interna2=-Math.round(this.formEdit.interna2*porcentaje*100)/100
		}
	},
	f_restaurar() {
		this.v_ajuste_actual=-this.formEdit.saldo_actual
		this.v_ajuste_funcional1=-this.formEdit.funcional1
		this.v_ajuste_promedio1=-this.formEdit.promedio1
		this.v_ajuste_interna1=-this.formEdit.interna1
		this.v_ajuste_funcional2=-this.formEdit.funcional2
		this.v_ajuste_promedio2=-this.formEdit.promedio2
		this.v_ajuste_interna2=-this.formEdit.interna2
		this.formEdit.ajuste_actual=this.v_ajuste_actual
		this.formEdit.ajuste_funcional1=this.v_ajuste_funcional1
		this.formEdit.ajuste_promedio1=this.v_ajuste_promedio1
		this.formEdit.ajuste_interna1=this.v_ajuste_interna1
		this.formEdit.ajuste_funcional2=this.v_ajuste_funcional2
		this.formEdit.ajuste_promedio2=this.v_ajuste_promedio2
		this.formEdit.ajuste_interna2=this.v_ajuste_interna2
	},
	grabeAjuste() {
		let v_error = false
		if	(this.v_ajuste_actual == "") {
			this.$toast.error("Diligenciar el campo Ajuste Actual")
			v_error = true
			return
		}
		let v_mensaje = ""
		let v_valor = -this.formEdit.saldo_actual
		let v_valor_funcional1 = -this.formEdit.funcional1
		if	(v_valor >0) {
			if	(this.v_ajuste_actual > v_valor) {
				v_mensaje = "Valor no puede ser mayor a "+
					this.formato(v_valor)
				v_error=true
				this.v_ajuste_actual=v_valor
				this.v_ajuste_funcional1=v_valor_funcional1
			} else	{
				if	(this.formEdit.ajuste_actual<0) {
					v_mensaje="Valor no puede ser menor a 0"
					v_error = true
					this.v_ajuste_actual=v_valor
					this.v_ajuste_funcional1=v_valor_funcional1
				}
			}
		} else	{
			if	(this.v_ajuste_actual < v_valor) {
				v_mensaje = "Valor Ajuste no puede ser menor a "+
					this.formato(v_valor)
				this.v_ajuste_actual = v_valor
				this.v_ajuste_funcional1=v_valor_funcional1
				v_error=true
			} else	{
				if	(this.v_ajuste_actual>0) {
					v_mensaje="Valor Ajuste debe ser menor a 0"
					v_error = true
					this.v_ajuste_actual = v_valor
					this.v_ajuste_funcional1 = v_valor_funcional1
				}
			}
		}
		if	(v_error) {
			this.$toast.error(v_mensaje)
			return
		}
		if	(!v_error) {
			this.showDialogEdit=false
			this.formEdit.ajuste_actual=this.v_ajuste_actual
			this.formEdit.ajuste_funcional1=this.v_ajuste_funcional1
			this.formEdit.ajuste_promedio1=this.v_ajuste_promedio1
			this.formEdit.ajuste_interna1=this.v_ajuste_interna1
			this.formEdit.ajuste_funcional2=this.v_ajuste_funcional2
			this.formEdit.ajuste_promedio2=this.v_ajuste_promedio2
			this.formEdit.ajuste_interna2=this.v_ajuste_interna2
			Balance.grabeAjuste("D",this.formEdit)
			.then()
				this.$toast.success("Informacion Grabada")
			.catch(() => {
				this.$toast.error("Informacion no Grabada")
				this.error = true;
			});
		}
	},
	elimineAjuste() {
		this.showDialogEdit=false
		this.formEdit.ajuste_actual=this.v_ajuste_actual
		this.formEdit.ajuste_funcional1=this.v_ajuste_funcional1
		Balance.elimineAjuste("D",this.formEdit)
		.then()
			this.$toast.success("Informacion Grabada")
			this.$emit('cierraDetalle')
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		});
	},
  },
 mounted() {

},
watch:{
        optDestino(Destino){
            // console.log(levelDos)
            this.$emit('envDestino',Destino)
        }
    }
};
</script>

<style src="../main.css"></style>
