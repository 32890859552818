<template>
    <div class="container">
        <md-dialog :md-active.sync="showEdiAjustDest" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <!-- <pre>
                    {{ infoEdit }}
                </pre> -->
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.cuenta}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h6>{{ infoEdit.cuenta }}</h6>
                    </b-col>
                </b-row>
                <!-- <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h6>{{ infoEdit.descripcion }}</h6>
                    </b-col>
                </b-row> -->
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.valor}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.valor"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.funcional}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.funcional"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.alterna}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input v-model="infoEdit.alterna"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaAjuste">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
  
<script>
import AjustesConsol from '../../services/AjustesConsol'

export default {
    name:"EditAjustesDest",
    props:['showEdiAjustDest','infoEdit'],
    data: () => ({

    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Edita ajuste",
                    save : "Guardar",
                    close : "Cerrar",
                    cuenta : "Cuenta",
                    description : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "Alterna"
                    
                }
            }else{
                return {
                    title : "Edit setting",
                    save : "Save",
                    close : "Close",
                    cuenta : "Account",
                    description : "Description",
                    valor : "Value",
                    funcional : "Functional",
                    alterna : "Alternate"
                    
                }
            }
        }
    },
    methods:{
        guardaAjuste(){

		if	(this.infoEdit.valor === '') {
			this.infoEdit.valor = null
		}
		if	(this.infoEdit.funcional === '') {
			this.infoEdit.funcional = null
		}
		if	(this.infoEdit.alterna === '') {
			this.infoEdit.alterna = null
		}
		let form = {
			'id' :  this.infoEdit.id,
			'vigencia' :  this.infoEdit.vigencia,
			'companyId' :  this.infoEdit.id_compania,
			'valor' :  this.infoEdit.valor,
			'funcional' :  this.infoEdit.funcional,
			'alterna' :  this.infoEdit.alterna,
			'idUser' :  this.$store.state.userId,
		}
		if	(form.valor != null &&
			(form.funcional === null ||
			form.alterna === null)) {
			this.$toast.error("Funcional y Alterna no pueden ser nulos")
			return
		}

		if	(form.valor === null &&
			(form.funcional != null ||
			form.alterna != null)) {
			this.$toast.error("Funcional y Alterna deben ser nulos")
			return
		}


            // console.log(form)

            AjustesConsol.updateAjusteDestino(form)
            .then((res) => {
                // console.log(res.data.message)
                if(res.status == 200){
                    this.$toast.success(res.data.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    this.reloadTbl()
                }, 2000)
            })

        },
        closeModal(){
            this.$emit('closeModal',false)
        },
        reloadTbl(){
            let form = {
                'id' :  this.infoEdit.secuencia,
                'vigencia' :  this.infoEdit.vigencia,
                'companyId' :  this.infoEdit.id_compania
            }
            this.$emit('reloadTblDest',form)
        }
    }
}
</script>

<style>

</style>
