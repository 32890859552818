<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center py-2">
                {{labels.title}}
                </h2>
            </b-container>
        </b-row>
       <!--  <b-row>
            <b-container>
                <h4 class="text-center border-bottom">
                    {{labels.subtitle}}
                </h4>
                <b-col md="8" class="mx-auto">
                    <ul>
                        <li v-for="(item, idx) in labels.instructive" :key="idx">{{ item }}</li>
                    </ul>
                </b-col>
            </b-container>
        </b-row> -->
        <b-row>
            <b-container>
                <b-col md="10" class="mx-auto"> 
                    <b-button @click="goToCreateCurrency()" id="create-btn">{{labels.create}}</b-button>
                </b-col>
            </b-container>
            <div class="row p-1 justify-content-left">
                <div class="col-md-12">
                    <md-field md-inline>
                    <label>{{labels.name}}</label>
                    <md-input v-model="searchCurrency" @input="searchOnTableCurrency"></md-input>
                    </md-field>
                </div>
            </div>
            <b-container v-if="!isEmpty" class="pb-5">
                <!-- <h4 class="text-center">
                {{labels.info}}
                </h4> -->
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                        <!-- <th scope="col">{{labels.createdAt}}</th> -->
                        <th class="text-left" scope="col">{{labels.name}}</th>
                        <th scope="col">{{labels.acronym}}</th>
                        <th scope="col">{{labels.funcional}}</th>
                        <th scope="col">{{labels.url}}</th>
                        <th scope="col">{{labels.edit}}</th>
                        <th scope="col">{{labels.inactivate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="currency in datosPaginados" :key="currency.id">
                        <!-- <td>{{ pro.createdAt }}</td> -->
                        <td class="text-left">{{ currency.nombre }}</td>
                        <td>{{ currency.acronimo }}</td>
                        <td>{{ f_funcional(currency.isFuncional) }}</td>
                        <td>{{ currency.url }}</td>
                        <td>
                            <md-button @click="editCurrency(currency.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                        </td>
                        <td>
                            <md-button @click="deleteCurrrency(currency.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
                        </td>
                        </tr>
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
                        <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                        <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
                    </ul>
                </nav>
                <b-col
                    md="6"
                    class="mx-auto empty-table pt-4"
                    v-if="searched.length == 0 && !isLoading">
                    <h3>{{labels.notFound}}</h3>
                    <p>{{labels.notFoundMessage}}: <span>{{ search }}</span></p>
                </b-col>
            </b-container>
            <b-container v-else>
                <b-col md="2" class="mx-auto">
                    <div class="success-svg mb-4" :class="{ active: isEmpty }">
                        <img src="../../assets/imgs/file-2.svg" alt="warning" />
                    </div>
                </b-col>
                <b-col md="12" class="justify-content-around">
                    <span id="message" v-if="isEmpty">{{labels.empty}}</span>
                </b-col>
            </b-container>
        </b-row>
        <!-- <b-row v-else>
            <b-col md="12" lg="12" class="p-4 mx-audo">
                <div class="text-center">
                    <b-spinner></b-spinner>
                </div>
            </b-col>
        </b-row> -->

        <md-dialog :md-active.sync="showDialogEdit">
            <md-dialog-title>{{labels.information}}</md-dialog-title>
            <md-dialog-content>
                <b-form-group @submit.prevent="editaInfoCurrency">
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.name}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.nameCurrency" type="text"></md-input>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.funcional}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                           <input type="radio" value="0" id="" v-model="formEdit.isFuncional" class="ml-3"> <span>No</span>
                           <input type="radio" value="1" id="" v-model="formEdit.isFuncional" class="ml-3"> <span>Funcional</span>
                           <input type="radio" value="2" id="" v-model="formEdit.isFuncional" class="ml-3"> <span>Alterna</span>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.acronym}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.acronym" type="text"></md-input>
                        </md-field><br>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.url}} </h5>
                    </b-col>
                    <b-col cols="8" class="text-left">
                        <md-field>
                            <md-input v-model="formEdit.url" type="text"></md-input>
                        </md-field><br>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="4"></b-col>
                    <b-col cols="8" class="text-left">
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="editaInfoCurrency()">Guardar</md-button>
                            <md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
                        </md-dialog-actions>
                    </b-col>
                </b-row>
                </b-form-group>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import Currency from "@/services/Currency"

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByCurrency = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.nombre).includes(toLower(term))
    );
  }
  return items;
};

export default {
  name: "ManageCurrency",
  // components: {Select2},

  data: () => ({
    rolSeleccionado: {},//<-- el seleccionado estará aquí
    myOptions: [],
    nameUser: '',
    identificacionUser: '',
    telefonoUser: '',
    correo: '',
    rolSelected: '',
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    showDialogEdit: false,
    dialogItem: {},
    currency: [],
    roles: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "List.xls",
    elementosPorPagina:10,
    datosPaginados: [],
    paginaActual: 1,
    searchCurrency: null,
    searchEmail: null,
    formEdit: {
      nameCurrency: '',
      acronym: '',
      isFuncional: '',
      url: '',
      idCurrency: '',
    },
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "MONEDAS",
          subtitle: "Instrucciones",
          instructive: ["Bienvenid@ a la plataforma para la visualización y creación de monedas"],
          create: "Crear",
          name: "Moneda",
          acronym: "Acrónimo",
          funcional: "Funcional",
          url: "URL",
          edit: "Editar",
          inactivate: "Inactivar",
          /*info: "Información General",
          search: "Buscar por Nombre de Usuario",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Fecha Creación",
          email: "Correo Electrónico",
          informationUser: "Información",
          companyId: "Id de la Compañia",
          phonenumber: "Teléfono de contacto",
          idNumber: "Número de identificación",
          role: "Rol",
          empty: "Aún no cuentas con usuarios",
          active: "Activo",
          inactive: "Inactivo",
          status: "Estado",
          notFoundMessage: "No hay datos con",
          identificationCard: "Cedula",
          phone: "Telefono",*/
        }
      }else {
        return {
          title: "CURRENCY",
          subtitle: "Instructions",
          instructive: ["Welcome to the platform for the visualization and creation of currencies."],
          create: "Create",
          name: "Currency",
          acronym: "Acronym",
          url: "URL",
          funcional: "Funtional",
          edit: "Edit",
          inactivate: "Inactivate",
          /*info: "General Information",
          search: "Search by User Name",
          information: "Información del Usuario",
          tapLabel: "Datos",
          createdAt: "Created Date",
          email: "Email",
          informationUser: "Information",
          companyId: "Company Id",
          phonenumber: "Contact Number",
          idNumber: "Identification Id",
          role: "Role",
          empty: "You don't have users yet",
          active: "Activo",
          inactive: "Inactive",
          status: "Status",
          notFoundMessage: "There is no data with",
          identificationCard: "Identification Card",
          phone: "Phone",*/
        }
      }
    }
  },
  methods: {
    f_funcional(opcion) {
	if	(opcion == 1) return "Funcional"
	if	(opcion == 2) return "Alterna"
	return "No"
    },
    goToCreateCurrency(){
      this.$router.push({path: '/currency/create'})
    },
    editCurrency(id){
      // console.log(id)
      this.showDialogEdit = true
      const currentIdx = this.currency.findIndex((obj) => obj.id == id);
      this.dialogItem = this.currency[currentIdx];
      this.formEdit.nameCurrency = this.dialogItem.nombre;
      this.formEdit.isFuncional = this.dialogItem.isFuncional;
      this.formEdit.acronym = this.dialogItem.acronimo;
      this.formEdit.url = this.dialogItem.url;
      this.formEdit.idCurrency = id;
    },
    editaInfoCurrency(){
    // console.log(this.formEdit)
      if	(this.formEdit.isFuncional > 0) {
		const currentIdx = this.currency.findIndex((obj) =>
			obj.isFuncional == this.formEdit.isFuncional);
		if	(this.currency[currentIdx].id != this.formEdit.idCurrency) {
			this.$toast.error("La moneda "+this.currency[currentIdx].nombre+" tiene ya la condicion")
			this.formEdit.isFuncional = 0
			return
		}
      }
      this.isLoading = true;
      Currency.updateCurrency(this.formEdit)
      .then(() => {
        // console.log(res)
        this.$toast.success("Moneda editada correctamente")
        location. reload()
        this.isLoading = false;
        this.showDialogEdit = false;
      })
      .catch(() =>{
        this.error = true;
      })

    },
    deleteCurrrency(id){
      // console.log(id)
      this.isLoading = true;
      Currency.deleteCurrency(id).then(() => {
        this.$toast.success("Moneda eliminada correctamente")
        location. reload()
        this.isLoading = false;
      });
    },
    searchOnTableCurrency() {
      this.datosPaginados = searchByCurrency(this.currency, this.searchCurrency);
      if(this.searchCurrency == ''){
        this.getDataPagina(1)
      }
    },
    totalPaginas(){
      return Math.ceil(this.searched.length / this.elementosPorPagina)
    },
    getDataPagina(noPagina){
      this.datosPaginados = [];
      this.paginaActual = noPagina;
      let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
      let fin = (noPagina * this.elementosPorPagina);

      this.datosPaginados = this.searched.slice(ini,fin);
    },
    getPreviousPage(){
      if(this.paginaActual > 1){
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage(){
      if(this.paginaActual < this.totalPaginas()){
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    },
    getOptions(data){
      let optRol = {};
      for(let ind=0; ind < data.length; ind++){
        optRol = { id: data[ind].id, text: data[ind].nombre }
        this.myOptions.push(optRol)
      }
      // console.log(this.myOptions)
    }
  },
  mounted() {
    this.isLoading = true;
    Currency.getAllCurrency()
    .then((result) => {
        // console.log(result.data)
        this.currency = result.data.currency;
        this.searched = this.currency;
        this.getDataPagina(1);
        if (this.currency.length == 0) {
          this.isEmpty = true;
        }
    })
    .catch(() => {
        // console.log(error);
        this.error = true;
    })
    .finally(() => {
        this.isLoading = false;
    });
  },
};
</script>

<style src="../main.css"></style>
