<template>
  <div class="container">
    <md-dialog :md-active.sync="modInfoUser" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">{{labels.title}}</md-dialog-title>
        <md-dialog-content>
            <b-row>
                <b-col>
                    <h5>{{labels.name}}</h5>
                </b-col>
                <b-col>
                    <h6>{{dataUser[0].name}}</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h5>{{labels.cedula}}</h5>
                </b-col>
                <b-col>
                    <h6>{{dataUser[0].cedula}}</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h5>{{labels.correo}}</h5>
                </b-col>
                <b-col>
                    <h6>{{dataUser[0].email}}</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h5>{{labels.phone}}</h5>
                </b-col>
                <b-col>
                    <h6>{{dataUser[0].telefono}}</h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h5>{{labels.rol}}</h5>
                </b-col>
                <b-col>
                    <!-- <h5>{{dataUser.name}}</h5> -->
                    {{ nombreRol() }}
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-2">
                <md-button class="md-accent" @click="cierraModal">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
export default {
    name: 'infoUser',
    props: ['modInfoUser','dataRole','dataUser'],
    data: () => ({

    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Información del Usuario",
                    save : "Guardar",
                    close : "Cerrar",
                    name : "Nombre:",
                    cedula : "Cedula:",
                    correo : "Correo electrónico:",
                    phone : "Telefono:",
                    rol : "Rol:"
                }
            }else{
                return {
                    title : "User information",
                    save : "Save",
                    close : "Close",
                    name : "Name:",
                    cedula : "Identification number:",
                    correo : "Email:",
                    phone : "Phone:",
                    rol : "Rol:"
                }
            }
        }
    },
    methods:{
        cierraModal(){
            this.$emit('cierraModal',false)
        },
        nombreRol(){
            let nameRole = ''
            this.dataUser.forEach((user) => {
                // console.log(user)
                this.dataRole.forEach((rol) => {
                    // console.log(rol)
                    if(rol.id == user.id_rol){
                        // console.log(rol.nombre)
                        nameRole = rol.nombre
                    }
                })
            })
            return nameRole
        }
    }
}
</script>

<style>

</style>