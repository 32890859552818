<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div class="row col-md-12 mt-2" v-if="listaReportes.length != 0">
            <div class="col-md-12 d-flex justify-content-end">
                <td>
                    <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="creaReporte">
                        <md-icon>add</md-icon>
                    </md-button>
                </td>
            </div>
            <div class="row col-md-12 justify-content-center">
                <md-table md-card md-fixed-header v-model="listaReportes">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell :md-label="labels.numero" class="border">{{ item.codigo }}</md-table-cell>
                        <md-table-cell :md-label="labels.name" class="text-left border">{{ item.nombre }}</md-table-cell>
                        <md-table-cell :md-label="labels.principal" class="text-left border">{{item.principal }}</md-table-cell>
                        <md-table-cell :md-label="labels.edit" class="border">
                            <md-button style="background-color: #e5ac00; width: 30px; height: 30px;" class="md-fab md-primary" @click="editReporte(item.id)">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell :md-label="labels.delete" class="border">
                            <md-button style="background-color: #cd2838; width: 30px; height: 30px;" class="md-fab md-primary" @click="deleteReporte(item.id)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </div>
        <div v-else>
            <h4 class="mt-4">{{ labels.noData }}</h4>
            <div class="mt-4">
                <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="creaReporte">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
        </div>
        <CreaReporte :modCreaReporte="modCreaReporte" @refrescaTbl="reloadTbl" @closeModal="cierraModal"/>
        <EditaReporte :modEditaReporte="modEditaReporte" :ItemReport="infoReporte" @refrescaTbl="reloadTbl" @closeModal="cierraModal"/>
        <InacticaItem :InactivaItem="deletFila" @confirmDelete="confirmDel" @cierraModal="closeMod"/>
    </div>
</template>

<script>
import TipoReporte from '../../../services/TipoReporte'
import CreaReporte from './CreaReporte.vue'
import EditaReporte from './EditaReporte.vue'
import InacticaItem from '../../utils/InacticaItem.vue'

export default {
    name:'TipoReporte',
    components:{CreaReporte,EditaReporte,InacticaItem},
    data:() => ({
        showTblReportes : false,
        listaReportes : [],
        modCreaReporte : false,
        modEditaReporte : false,
        infoReporte : [],
        idReportDel : null,
        deletFila : false
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : 'Reportes',
                    numero : 'Número',
                    name : 'Nombre',
                    edit : 'Editar',
                    delete : 'Eliminar',
                    noData : 'No hay datos',
                    principal: "Principal"
                }
            }else{
                return {
                    title : 'Reports',
                    numero : 'Number',
                    name : 'Name',
                    edit : 'Edit',
                    delete : 'Eliminar',
                    noData : 'No hay datos',
                    principal: "Main"
                }
            }
        }
    },
    methods:{
        cierraModal(valor){
            this.modCreaReporte = valor
            this.modEditaReporte = valor
        },
        closeMod(valor){
            this.deletFila = valor
        },
        creaReporte(){
            // console.log('creaReporte');
            this.modCreaReporte = true
        },
        async editReporte(id){
            // console.log('editaId:',id);
            let dataEdit = await TipoReporte.getTipoReporteById(id)
            this.infoReporte = dataEdit.data.qryReporte
            this.modEditaReporte = true
        },
        deleteReporte(id){
            // console.log('deleteId:',id);
            this.idReportDel = id
            this.deletFila = true
        },
        confirmDel(){
            
            TipoReporte.deleteReporte(this.idReportDel)
            .then((res) => {
                this.$toast.success(res.data.message)
                this.reloadTbl()
                this.deletFila = false
            })
            .catch((err) => console.log(err))
        },
        reloadTbl(){
            TipoReporte.getAllTipoReporte()
            .then((res) => {
                // console.log(res);
                this.listaReportes = res.data.typeReport
                this.modCreaReporte = false
                this.modEditaReporte = false
            })
            .catch((err) => console.log(err))
        }
    },
    mounted(){
        TipoReporte.getAllTipoReporte()
        .then((res) => {
            // console.log(res);
            this.listaReportes = res.data.typeReport
        })
        .catch((err) => console.log(err))
    }
}
</script>

<style>

</style>
