<template>
    <div class="container">
        <div class="col-md-12">
            <h3><b><i>{{ f_titulo() }}</i></b></h3>
        </div>
        <h4>
                <label for="selVigencia" class=""> {{ labels.vigencia }}</label>&nbsp; 
                <input type="month" name="" id="" v-model="selVigencia">&nbsp; 
                <label for="company" class=""> {{ labels.company }}</label>&nbsp; 
                <select id="company" name="" v-model="companyId"  v-on:click="f_valide_formato">&nbsp; >
                    <option value="">{{labels.seleccione}}</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id">{{ company.acronym+ " "+company.nombre }}</option>
                </select>
		<span v-if="selVigencia && companyId && formato_asignado">
                      <input id="archivoExcel" type="file" @change="cargaExcel()" class="btn-crear">&nbsp; 
                      <span v-if="muestraHoja">
                            <label for="hoja" class=""> {{ labels.hoja }}</label>&nbsp; 
                            <select id="hoja" name="" v-model="selectedHoja" v-on:click="f_showHoja()">
                                <option value="">{{labels.seleccione}}</option>
                                <option v-for="(item,idx) in listHojas" :value="item" :key="idx">{{ item }}</option>
                            </select>
                      </span>
		</span>
        </h4>
        <div class="col-md-1"></div>
        <div v-if="showBtnHoja == true" class="col-md-12 row justify-content-center mt-4">
            <b-button @click="cargaBalanceSeleccionado()" id="consult-btn">{{ labels.chargeHoja }}</b-button>
        </div>
        <div v-if="!isLoading"></div>
        <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>
<script>

import Company from "../../services/Company"
import Cargue from "../../services/Cargue";
import * as XLSX from "xlsx"

export default{
name: "CargueBalance",
data: () => ({
	listCompany: [],
	listVigencia: [],
	companyId: "",
	selVigencia: "",
	formato_asignado: false,
	listCuenta: [],
	listHojas: [],
	showBtnHoja: false,
	isLoading: false,
	inputArch: "",
	workbook: "",
	muestraHoja: false,
	selHoja: false,
	selectedHoja: '',
	fileReader: '',
	formato: '',
}),
computed: {
            labels(){
                if(this.$store.state._language == "es-ES"){
                    return {
                        title : "CARGUE BALANCE FORMATO EXCEL SIN INFORMACION NIIF",
                        company : "Compañía:",
                        vigencia : "Vigencia:",
                        charge : "Cargar",
                        chargeHoja : "Cargar Hoja",
			seleccione: "Seleccione.....",
			hoja: "Hoja"
                    }
                
                }else{
                    return {
                        title : "LOAD BALANCE SHEET EXCEL FORMAT WITHOUT NIIF INFORMATION",

                        company : "Company:",
                        vigencia : "Validity:",
                        charge : "Charge",
                        chargeHoja : "Charge Sheet",
			seleccione: "Select.....",
			hoja: "Sheet"


                    }
                }
            }
},
methods: {
	f_titulo() {
		let titulo = ""
		let tituloEN=""
		titulo = "PROCESO DE CARGUE DE BALANCES"
		tituloEN = "ACCOUNTING BALANCE UPLOAD PROCESS"
		if      (this.$store.state._language != "es-ES") {
			titulo= tituloEN
		}
		if	(this.formato) {
			titulo = this.f_titulo_cargue()
		}
		return titulo
	},
	f_titulo_cargue() {
		let titulo = ""
		let tituloEN=""
		switch (this.formato) {
		case	1 :
			titulo = "CARGUE BALANCE EXCEL UNIVERSIDAD EL BOSQUE"
			tituloEN = "LOAD BALANCE SHEET EXCEL UNIVERSIDAD EL BOSQUE"
			break;
		case	2 :
			titulo = "CARGUE BALANCE EXCEL IPS EL BOSQUE"
			tituloEN = "LOAD BALANCE EXCEL IPS EL BOSQUE"
			break;
		case	3 :
			titulo = "CARGUE BALANCE FORMATO 3"
			tituloEN = "LOAD BALANCE SHEET FORMAT 3"
			break;
		case	4 :
			titulo = "CARGUE BALANCE FORMATO 4"
			tituloEN = "LOAD BALANCE SHEET FORMAT 4"
			break;
		case	5 :
			titulo = "CARGUE BALANCE FORMATO 5"
			tituloEN = "LOAD BALANCE SHEET FORMAT 5"
			break;
		case	6 :
			titulo = "CARGUE BALANCE FORMATO 6"
			tituloEN = "LOAD BALANCE SHEET FORMAT 6"
			break;
		}
		if      (this.$store.state._language != "es-ES") {
			titulo= tituloEN
		}
		return titulo
	},
	cargaExcel() {
		this.showBtnHoja = false
		this.muestraHoja = false
		this.selHoja = false
		this.selectedHoja = false
                const inputArch = document.getElementById("archivoExcel")
		var selectedFile=inputArch.files[0]
		this.fileReader = new FileReader();
		this.fileReader.onload = function(event) {
			var data = event.target.result
			this.workbook = XLSX.read(data, {type: "binary"})
			let hojas = this.workbook.SheetNames
			this.listHojas=hojas
		};
		if	(selectedFile) {
			this.fileReader.readAsBinaryString(selectedFile)
			setTimeout(()=>{
					this.muestraHoja=true
					this.listHojas = this.fileReader.listHojas
					},2000)
		}
	},
	cargaBalanceSeleccionado() {
		let rowObject = XLSX.utils.sheet_to_row_object_array(
			this.fileReader.workbook.Sheets[this.selectedHoja])
		this.listCuenta = []
		rowObject.forEach(fila =>{
			var columnas=Object.values(fila)
			this.listCuenta.push(columnas)
		})
		let form = {
			"vigencia"      : this.cambFormatoVigencia(),
			"company"       : this.companyId,
			"listadoCuentas": this.listCuenta,
			'idUser'        : this.$store.state.userId
		}
		let v_error = false
		switch (this.formato) {
		case	1 :
			Cargue.cargaBalace(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	2 :
			Cargue.cargaBalaceF2(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	3 :
			Cargue.cargaBalaceF3(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	4 :
			Cargue.cargaBalaceF4(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	5 :
			Cargue.cargaBalaceF5(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	6 :
			Cargue.cargaBalaceF6(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	7 :
			Cargue.cargaBalaceF7(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	8 :
			Cargue.cargaBalaceF8(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		case	9 :
			Cargue.cargaBalaceF9(form)
			.then((res) => {
				if	(res.status !== 200) {
					v_error = false
				} else {
					v_error = true
				}
			})
			if	(v_error) {
				this.$toast.error("Problema al Cargar")
			} else {
				setTimeout(()=>{
				this.actualizaNiif(form.vigencia,form.company)
				},10000)
			}
			break;
		}
		if	(!v_error) {
			this.showBtnHoja = false
		}
	},
	actualizaNiif(p_vigencia,p_compania) {
		Cargue.actualizaNiif(p_vigencia,p_compania)
		.then((res) => {
			if	(res.status === 200) {
				this.$toast.success("Cargue Completo")
			} else {
				this.$toast.error("Error(1) al Actualizar Niif(Cargue)")
			}
		})
		.catch(() => {
			this.$toast.error("Error(2) al Actualizar Niif(Cargue) ")
		})
	},
	cambFormatoVigencia(){
                let anio = this.selVigencia.substring(0,4)
                let mes = this.selVigencia.substring(5,7)
                let newMes = mes

                return anio+newMes
	},
	f_showHoja() {
		this.showBtnHoja  = true
	},
	f_valide_formato() {
		this.formato_asignado = false
		let objFormato = this.listCompany.find((obj)=>obj.id === this.companyId)
		this.formato=objFormato.formato
		if	(this.formato) {
			this.formato_asignado = true
		} else {
			this.$toast.success(objFormato.nombre+" sin formato asignado")
		}
	},
},
mounted(){
	this.isLoading = true;

	Company.getAllFormatos()
	.then((result) => {
		this.listCompany = result.data.formatos
	})
	.catch((err) => {
		console.log(err)
	})
	.finally(() => {
		this.isLoading = false;
	})

	Cargue.getVigenciaCargue()
	.then((res) => {
		this.listVigencia = res.data.getVigencia
	})
	.catch((err) => {
		console.log(err)
	})
	.finally(() => {
		this.isLoading = false;
	})
	}
}
</script>
<style src="../main.css"></style>
