<template>
    <div class="container">
        <md-dialog :md-active.sync="modAsociaMenu" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ nameRol() }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2" class="text-center" v-for="menu in listadoMenus" :key="menu.id">
                        <md-button @click="listaSubmenu(menu.id)" class="md-dense md-raised md-primary btn-info">{{ menu.nombre }}</md-button>
                    </b-col>
                </b-row>
                <div class="col-md-12 d-flex justify-content-center">
                    <div class="col-2">
                        <h6><md-checkbox v-for="submenu in listadoSubMenus" :key="submenu.id" v-model="submenuSelect" :value="submenu.id" @change="asociaSubMenu(submenu.id)" class="md-primary">{{ submenu.nombre }}</md-checkbox></h6>
                    </div>
                </div>
                <md-dialog-actions class="mt-2">
                    <!-- <md-button class="md-primary" @click="asociaMenu">{{ labels.save }}</md-button> -->
                    <md-button class="md-accent" @click="cierraModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import Submenu from '../../services/SubMenu';
import Menu from '../../services/Menu';
import Roles from '../../services/Roles';
import RoleMenu from '../../services/RoleMenu';

export default {
    name:"ModalAsociaMenu",
    props:['modAsociaMenu','IdRol'],
    data: () => ({
        listadoMenus: [],
        listadoSubMenus: [],
        listadoSubMenusAsoc: [],
        submenuSelect: [],
        nombreRol: "",
        menuSelected: "",
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Rol:",
                    save : "Guardar",
                    close : "Cerrar"
                }
            }else{
                return {
                    title : "Rol:",
                    save : "Save",
                    close : "Close"
                }
            }
        }
    },
    methods:{
        nameRol(){
            if(this.IdRol != ""){
                Roles.getAllRolesById(this.IdRol)
                .then((res) => {
                    // console.log(res.data.rolById)
                    this.nombreRol = res.data.rolById[0].nombre
                })
                .catch((error) => {console.log(error)})
    
                return this.nombreRol
            }
        },
        listaSubmenu(idMenu){
            // console.log(this.IdRol,idMenu)
            this.menuSelected = idMenu
            Submenu.subMenuByIdMenu(idMenu)
            .then((res) => {
                // console.log(res.data.listSubMenu)
                this.listadoSubMenus = res.data.listSubMenu
            })
            .catch((error) => {console.log(error)})

            RoleMenu.getSubMenuByRolByMenu(this.IdRol,idMenu)
            .then((res) => {
                let submenuChecked = []
                // console.log(res.data.listRolMenu)
                this.listadoSubMenusAsoc = res.data.listRolMenu
                this.listadoSubMenusAsoc.forEach((elem) => {
                    submenuChecked.push(elem.id_submenu)
                })
                // console.log(numSubmenu)
                this.submenuSelect = submenuChecked
            })
            .catch((error) => {console.log(error)})
        },
        asociaSubMenu(subMenuId){
            // console.log(this.IdRol,this.menuSelected,subMenuId)
            let datos = {
                "idRol" : this.IdRol,
                "idMenu" : this.menuSelected,
                "idSubmenu" : subMenuId
            }

            RoleMenu.updateAsociacionRolemenu(datos)
            .then((res) => {
                // console.log(res.data.message)
                this.$toast.success(res.data.message)
            })
            .catch((err) => {console.log(err)})
        },
        cierraModal(){
            this.listadoSubMenus = []
            this.$emit('closeModal',false)
        }
    },
    mounted(){
        Menu.getAllMenu()
        .then((res) => {
            // console.log(res.data.listMenu)
            this.listadoMenus = res.data.listMenu
        })
        .catch((error) => {console.log(error)})
    }
}
</script>

<style>

</style>