<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogEdit" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.cuenta}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h6>{{Item.cuenta}}</h6>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.name}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-input v-model="Item.descripcion" class="form-control"></md-input>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="Item.niif" class="form-control">
                            <option v-for="planCons in listNiif" :key="planCons.id" :value="planCons.niif"> {{ planCons.niif}}-{{ planCons.nameNiif }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.esf}}</h5>
                    </b-col>
                    <b-col cols="6" lg="3" class="text-left">
                            <select v-model="Item.esf" class="form-control">
                                <option disabled :value="Item.esf">{{ Item.esf == "X" ? "SI" : "NO"}}</option>
                                <option value="X">{{ labels.yes }}</option>
                                <option value="">{{ labels.not }}</option>
                            </select>
                        <!-- </md-field> -->
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.eri}}</h5>
                    </b-col>
                    <b-col cols="6" lg="3" class="text-left">
                        <select v-model="Item.eri" class="form-control">
                            <option disabled :value="Item.eri">{{ Item.eri == "X" ? "SI" : "NO"}}</option>
                            <option value="X">{{ labels.yes }}</option>
                            <option value="">{{ labels.not }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.efe}}</h5>
                    </b-col>
                    <b-col cols="6" lg="3" class="text-left">
                        <select v-model="Item.efe" class="form-control">
                            <option disabled :value="Item.efe">{{ Item.efe == "X" ? "SI" : "NO"}}</option>
                            <option value="X">{{ labels.yes }}</option>
                            <option value="">{{ labels.not }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.ecp}}</h5>
                    </b-col>
                    <b-col cols="6" lg="3" class="text-left">
                        <select v-model="Item.ecp" class="form-control">
                            <option disabled :value="Item.ecp">{{ Item.ecp == "X" ? "SI" : "NO"}}</option>
                            <option value="X">{{ labels.yes }}</option>
                            <option value="">{{ labels.not }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="editPlanCuenta">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
    import PlanCuentas from '../../services/PlanCuentas'

    export default {
        name: "EditarCuenta",
        props: ['showDialogEdit','Item','listNiif'],

        data: () => ({
            cuenta: "",
            esf:"",
            eri:"",
            efe:"",
            ecp:"",
            niif: "",
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Editar cuenta",
                        save : "Guardar",
                        close : "Cerrar",
                        cuenta: "Cuenta:",
                        name: "Nombre:",
                        niif: "Niif:",
                        esf: "Esf:",
                        eri: "Eri:",
                        efe: "Efe:",
                        ecp: "Ecp:",
                        sel: "Seleccione...",
                        yes: "SI",
                        not: "NO"
                        
                    }
                }else{
                    return {
                        title: "Edit account",
                        save : "Save",
                        close : "Close",
                        cuenta: "Account:",
                        name: "Name:",
                        niif: "Niif:",
                        esf: "Esf:",
                        eri: "Eri:",
                        efe: "Efe:",
                        ecp: "Ecp:",
                        sel: "Select...",
                        yes: "Yes",
                        not: "No"
                        
                    }
                }
            }
        },

        methods: {

            editPlanCuenta(){
                if(this.Item.descripcion == ""){
                    this.$toast.error("Diliganciar el campo Nombre")
                }else if(this.Item.niif == ""){
                    this.$toast.error("Diliganciar el campo Niif")
                
                }else{
                    
                    let form = {
                        'nombre' : this.Item.descripcion,
                        'niif' : this.Item.niif,
                        'esf' : this.Item.esf == "SI" || this.Item.esf == "X" ? "X" : "",
                        'eri' : this.Item.eri == "SI" || this.Item.eri == "X" ? "X" : "",
                        'efe' : this.Item.efe == "SI" || this.Item.efe == "X" ? "X" : "",
                        'ecp' : this.Item.ecp == "SI" || this.Item.ecp == "X" ? "X" : "",
                        'id' : this.Item.id,
                        'cuenta' : this.Item.cuenta,
                        'vigencia' : this.Item.vigencia,
                        'id_compania' : this.Item.id_compania,
                        'codReporte' : this.Item.codReporte,
                        'idUser' : this.$store.state.userId,
                    }
                    // console.log(form)
                    PlanCuentas.planCuentasUpdate(form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        this.clicked()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.reload()
                    })
                }
            },
            clicked(){
                this.$emit('clicked', false);
                this.esf = ""
            },
            reload(){
                this.$emit('reload',this.Item.vigencia,this.Item.id_compania,this.Item.codReporte)
            },
        },
    }

</script>
<style src="../main.css"></style>
