<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center py-1">
                    {{labels.title}}
                </h2>
		<h5>
                <label for="company" class=""> {{ labels.compania }}</label> &nbsp; &nbsp; 
                <select id="company" name="" v-model="companyId" v-on:click="f_compania()">
                    <option value="">{{labels.compania}}...</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id" >{{ company.acronym+"-"+company.nombre }}</option>
                </select>
		</h5>
            </b-container>
        </b-row>
        <b-row>
            <b-container v-if="grupo&&companyId">
                <b-col md="12" class="mx-auto"> 
                    <b-button @click="f_capital()" id="create-btn">{{labels.capital}}</b-button> &nbsp; &nbsp; 
                    <b-button @click="f_resultados()" id="create-btn">{{labels.resultados}}</b-button> &nbsp; &nbsp; 
                    <b-button @click="f_valorizacion()" id="create-btn">{{labels.valorizacion}}</b-button>
                </b-col>
            </b-container>
            <b-container v-if="detalle&&companyId">
		<h4><b><i>{{ titulo_grupo }}</i></b></h4>
                <b-col md="12" class="mx-auto"> 
                    <b-button @click="f_cuentas()" id="create-btn">{{labels.cuentas}}</b-button> &nbsp; &nbsp; 
                    <b-button @click="f_tasas()" id="create-btn">{{labels.tasas}}</b-button> &nbsp; &nbsp; 
                    <b-button @click="f_regresar()" id="create-btn">{{labels.regresar}}</b-button> &nbsp; &nbsp; 
                </b-col>
            </b-container>
        </b-row>
        <b-row class="py-2">
            <b-container v-if="cuentas" class="pb-5">
		<div class="headOrigen">
			<md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="f_crea_cuentas()">
				<md-icon>add</md-icon>
			</md-button>
			<md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="f_borra_cuentas()">
				<md-icon>delete</md-icon>
			</md-button>
		</div>
		<div class="bodyCuentas" v-if="cuentas">
			<md-table v-model="cuentasPatrimonio" md-card md-fixed-header @md-selected="f_muestra_cuenta">
				<md-table-row slot="md-table-row" slot-scope="{ item } " md-selectable="single">
					<md-table-cell :md-label="labels.cuenta" class="text-left border">{{item.cuenta}}</md-table-cell>
					<md-table-cell :md-label="labels.descripcion" class="text-left border" >{{ item.descripcion }}</md-table-cell>
				</md-table-row>
			</md-table>
		</div>

		<md-dialog :md-active.sync="creacuentas">
			<md-dialog-title class="text-center"><b><i>{{titulo_grupo}}</i></b></md-dialog-title>
			<md-dialog-content>
				<b-form-group @submit.prevent="editaInfoBalance">
					<b-row class="" >
						<b-col cols="3" class="text-left">
							<h5>{{labels.criterio}}</h5>
						</b-col>
						<b-col cols="1" lg="9" class="text-left">
							<input type="text" v-model="criterioC" v-on:input="f_criterio_cuenta" placeholder="Criterio Cuenta..." class="form-control"><br>
						</b-col>
						<b-col cols="3"></b-col>
						<b-col cols="1" lg="9" class="text-left">
							<input type="text" v-model="criterioD" v-on:input="f_criterio_descripcion" placeholder="Criterio Descripcion..." class="form-control">
						</b-col>
					</b-row>
					<b-row class="" >
						<b-col cols="3" class="text-left">
							<h5>{{labels.account}}</h5>
						</b-col>
						<b-col cols="1" lg="9" class="text-left">
							<select multiple v-model="listadoCuentas" class="form-control">
								<option v-for="cuenta in showCuentas" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }}-{{cuenta.descripcion}}</option>
							</select>
						</b-col>
					</b-row>
				</b-form-group>
				<md-dialog-actions>
					<md-button class="md-primary" @click="grabeCuentas()">Guardar</md-button>
					<md-button class="md-accent" @click="creacuentas = false">Cerrar</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
            </b-container>
            <b-container v-if="tasas" class="pb-8">
		<div class="headTasas">
			<md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="f_crea_tasas()">
				<md-icon>add</md-icon>
			</md-button>
                        <label for="currency" class=""><h3><b><i>{{ labels.conversion }}</i></b></h3></label> &nbsp; 
                        <select name="currency" v-model="idCurrency" placeholder="Seleccione Moneda..." v-on:click="leaGrupoPatrimonioTasas()">
                                <option :key="currency.id" :value="currency.id" v-for="currency in listMonedas">{{ currency.nombre }}</option>
                        </select>
		</div>
		<div class="bodyTasas" v-if="idCurrency">

			<md-table v-model="tasaCambio" md-card md-fixed-header @md-selected="f_muestra">
				<md-table-row slot="md-table-row" slot-scope="{ item } " md-selectable="single">
					<md-table-cell :md-label="labels.validity" class="text-left border">{{item.vigencia}}</md-table-cell>
					<md-table-cell :md-label="labels.closingRate" class="text-right border" >{{ item.tasa_cierre }}</md-table-cell>
					<md-table-cell :md-label="labels.inverso" class="text-right border" >{{item.inverso}}</md-table-cell>
				</md-table-row>
			</md-table>
		</div>
		<md-dialog :md-active.sync="creatasas">
			<md-dialog-title class="text-center"><b><i>{{titulo_grupo}}</i></b></md-dialog-title>
			<md-dialog-content>
				<b-form-group @submit.prevent="editaInfoBalance">
					<b-row><b-col><h4>{{labels.validity}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.vigencia" type="month" ></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.currency}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.nombre_moneda" type="text" readonly></md-input> </md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.tasaPatrimonio}}</h4></b-col>
					<b-col cols="6"><md-field><md-input v-model="formEdit.tasa_cierre" type="text" ></md-input></md-field></b-col></b-row>
					<b-row><b-col><h4>{{labels.inverso}}</h4></b-col>
					<b-col><select id="inverso" name="" v-model="formEdit.inverso">
					<option value="0" selected>Falso</option>
					<option value="1">Verdadero</option>
					</select></b-col></b-row>
				</b-form-group>
				<md-dialog-actions>
					<md-button class="md-primary" @click="grabeTasaPatrimonio()">Guardar</md-button>
					<md-button class="md-accent" @click="creatasas = false">Cerrar</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
		<md-dialog :md-active.sync="showDialogEdit">
			<md-dialog-title class="text-center"><b><i>{{titulo_grupo}}</i></b></md-dialog-title>
			<md-dialog-content>
				<b-form-group @submit.prevent="editaInfoBalance">
				<b-row><b-col><h4>{{labels.validity}}</h4></b-col>
				<b-col cols="6"><md-field><md-input v-model="formEdit.vigencia" type="text" readonly></md-input></md-field></b-col></b-row>
				<b-row><b-col><h4>{{labels.currency}}</h4></b-col>
				<b-col cols="6"><md-field><md-input v-model="formEdit.nombre_moneda" type="text" readonly></md-input> </md-field></b-col></b-row>
				<b-row><b-col><h4>{{labels.closingRate}}</h4></b-col>
				<b-col cols="6"><md-field><md-input v-model="formEdit.tasa_cierre" type="text" ></md-input></md-field></b-col></b-row>
				<b-row><b-col><h4>{{labels.inverso}}</h4></b-col>
				<b-col><select id="inverso" name="" v-model="formEdit.inverso">
				<option value="0" selected>Falso</option>
				<option value="1">Verdadero</option>
				</select></b-col></b-row>
				</b-form-group>
				<md-dialog-actions>
					<md-button class="md-primary" @click="modificaTasaPatrimonio()">Modificar</md-button>
					<md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
            </b-container>
        </b-row>
    </div>
</template>
<script>
import Company from "@/services/Company"
import TasaCambio from "@/services/TasaCambio"
import Currency from "@/services/Currency"

export default {
        name: "TasasPatrimonio",


data: () => ({
	searchTasaCambio : null,
	isEmpty: false,
	isLoading: false,
	tasaCambio: [],
        listCompany : [],
	companyId: '',
	idCurrency: '',
	idMoneda: '',
	showDialogEdit: false,
	formEdit: {},
	showDialog: false,
	listCurrency: [],
	listMonedas: [],
	grupo:	true,
	detalle: false,
	cuentas : false,
	creacuentas : false,
	creatasas : false,
	tasas : false,
	titulo_grupo: '',
	cuentasPatrimonio: [],
	showCuentas : [],
	listadoCuentas : [],
	criterioC: "",
	criterioD: "",
	inverso:false,
}),
computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Tasas de cambio - Patrimonio",
                        cuentas: "Cuentas",
                        cuenta: "Cuenta",
                        descripcion: "Descripcion",
                        tasas: "Tasas",
                        capital: "Capital",
                        resultados: "Resultados",
                        valorizacion: "Valorizacion",
                        regresar: "Regresar",
                        compania: "Compañia",
                        nameTasa: "Tasa cambio",
                        validity: "Vigencia",
                        closingRate: "Tasa cierre",
                        tasaPatrimonio: "Tasa Patrimonio",
                        inverso: "Inverso",
                        averageRate: "Tasa promedio",
                        internalRate: "Tasa interna",
                        currency: "Moneda",
                        conversion: "Moneda de conversion",
                        modificar: "Modificacion de Tasas",
                    }
                }else{
                    return {
                        title: "Heritage Exchange Rate",
                        cuentas: "Account",
                        cuenta: "Account",
                        descripcion: "Description",
                        tasas: "Rates",
                        create: "Create",
                        capital: "Capital",
                        resultados: "Results",
                        valorizacion: "Valorization",
                        regresar: "Return",
                        compania: "Company",
                        nameTasa: "Exchange rate",
                        validity: "Validity",
                        closingRate: "Closing rate",
                        tasaPatrimonio: "Heritage rate",
                        inverso: "Invers",
                        averageRate: "Average rate",
                        internalRate: "Internal rate",
                        currency: "Currency",
                        conversion: "Exchange Currency",
                        modificar: "Exchange Rate Update",
                    }
                }
            }
},
methods: {
	
	f_compania(){
		this.grupo = true
		this.detalle = false
		this.tipo_cuenta = 0
		this.cuentas = false
		this.tasas = false
		this.idCurrency = ''
	},
	f_regresar(){
		this.grupo = true
		this.detalle = false
		this.cuentas = false
		this.tasas = false
	},
	f_valorizacion(){
		if (this.$store.state._language == "es-ES") {
			this.titulo_grupo = "VALORIZACION"
		} else {
			this.titulo_grupo = "VALORIZATION"
		}
		this.grupo = false
		this.detalle = true
		this.tipo_cuenta = 3
		this.leaGrupoPatrimonio()
	},
	f_resultados(){
		if (this.$store.state._language == "es-ES") {
			this.titulo_grupo = "RESULTADOS"
		} else {
			this.titulo_grupo = "RESULTS"
		}
		this.grupo = false
		this.detalle = true
		this.tipo_cuenta = 2
		this.leaGrupoPatrimonio()
	},
	f_capital(){
		if (this.$store.state._language == "es-ES") {
			this.titulo_grupo = "CAPITAL"
		} else {
			this.titulo_grupo = "CAPITAL"
		}
		this.grupo = false
		this.detalle = true
		this.tipo_cuenta = 1
		this.leaGrupoPatrimonio()
	},
	f_cuentas(){
		this.cuentas = true
		this.tasas = false
		if	(this.tipo_cuenta === 1) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "CAPITAL"
			} else {
				this.titulo_grupo = "CAPITAL"
			}
		}
		if	(this.tipo_cuenta === 2) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "RESULTADOS"
			} else {
				this.titulo_grupo = "RESULTS"
			}
		}
		if	(this.tipo_cuenta === 3) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "VALORIZACION"
			} else {
				this.titulo_grupo = "VALORIZATION"
			}
		}
		if	(this.$store.state._language == "es-ES") {
			this.titulo_grupo = "CUENTAS "+this.titulo_grupo
		} else {
			this.titulo_grupo = this.titulo_grupo+" ACCOUNTS"
		}
	},
	f_tasas(){
		this.tasas = true
		this.cuentas = false
		this.idCurrency = ''
		if	(this.tipo_cuenta === 1) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "CAPITAL"
			} else {
				this.titulo_grupo = "CAPITAL"
			}
		}
		if	(this.tipo_cuenta === 2) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "RESULTADOS"
			} else {
				this.titulo_grupo = "RESULTS"
			}
		}
		if	(this.tipo_cuenta === 3) {
			if (this.$store.state._language == "es-ES") {
				this.titulo_grupo = "VALORIZACION"
			} else {
				this.titulo_grupo = "VALORIZATION"
			}
		}
		if	(this.$store.state._language == "es-ES") {
			this.titulo_grupo = "TASAS "+this.titulo_grupo
		} else {
			this.titulo_grupo = this.titulo_grupo+" RATES"
		}
		const compania = this.listCompany.find((obj) => obj.id === this.companyId)
		this.titulo_grupo = this.titulo_grupo + " "+this.getAcronymCurrency(compania.id_moneda)
		this.listMonedas=this.listCurrency.filter((obj) => obj.id != compania.id_moneda)
		this.idMoneda = compania.id_moneda
		this.formEdit.id_origen = compania.id_moneda
	},
	f_crea_cuentas(){
		this.criterioC=''
		this.criterioD=''
		this.creacuentas = true
		TasaCambio.leaCuentasPatrimonio(this.companyId,this.tipo_cuenta)
		.then((result) => {
			this.listCuentas = result.data.cuentasPatrimonio
			this.showCuentas = this.listCuentas
		}).catch(() => {
			this.error = true;
		})
	},
	f_crea_tasas(){
		if	(!this.idCurrency) {
			return
		}
		this.cuentas = false
		this.creatasas = true
		this.formEdit.vigencia= ''
		this.formEdit.idCompania= this.companyId
		this.formEdit.tipo_cuenta= this.tipo_cuenta
		this.formEdit.userId = this.$store.state.userId;
		this.formEdit.id_conversion= this.idCurrency
		this.formEdit.nombre_moneda= this.getNameCurrency(this.idCurrency)
		this.formEdit.inverso= 0
		this.formEdit.tasa_cierre= ''
	},

	f_criterio_cuenta() {
		const criterio = "^"+this.criterioC
		if	(this.criterioC.length ==0) {
			this.showCuentas = this.listCuentas
		} else {
			this.showCuentas = this.listCuentas.filter(
			(cuenta) => cuenta.cuenta.match(criterio)>0)
		}
	},
	f_criterio_descripcion() {
		if	(this.criterioD.length == 0) {
			this.showCuentas = this.listCuentas
		} else {
			this.showCuentas = this.listCuentas.filter(
			(cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.criterioD.toUpperCase())>=0)
		}
	},

	f_muestra_cuenta() {
	},
	leaGrupoPatrimonio() {
		TasaCambio.leaGrupoPatrimonio(this.companyId,this.tipo_cuenta)
		.then((result) => {
			this.cuentasPatrimonio = result.data.cuentasPatrimonio
		}).catch(() => {
			this.error = true;
		})
	},
	leaGrupoPatrimonioTasas() {
		const datos = {}
		datos.companyId = this.companyId
		datos.tipo_cuenta = this.tipo_cuenta
		datos.idOrigen = this.idMoneda
		datos.idConversion = this.idCurrency
		TasaCambio.leaGrupoPatrimonioTasas(datos)
		.then((result) => {
			this.tasaCambio = result.data.tasaCambio;
		}).catch(() => {
			this.error = true;
		})
	},			

	getNameCurrency(id){
		let objMoneda = this.listCurrency.find((obj) => obj.id === id)
		let nombre_moneda=objMoneda.nombre
		return nombre_moneda
	},

	getAcronymCurrency(id){
		let objMoneda = this.listCurrency.find((obj) => obj.id === id)
		let nombre_moneda=objMoneda.acronimo
		return nombre_moneda
	},

	f_muestra(r_tasas) {
		this.formEdit = r_tasas
		this.showDialogEdit = true
		this.formEdit.nombre_moneda = this.getNameCurrency(r_tasas.id_conversion)
	},
	modificaTasaPatrimonio() {
		this.showDialogEdit=false
		this.formEdit.userId= this.$store.state.userId
		TasaCambio.modificaTasaPatrimonio(this.formEdit)
		.then((result) => {
			if	(result.status === 200) {
				this.$toast.success("Informacion Grabada")
			} else {
				this.$toast.error("Informacion no Grabada")
			}
		})
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		});
	},
	grabeTasaPatrimonio() {
		this.creatasas=false
		if	(this.formEdit.vigencia === '') {
			this.$toast.error("Falta Campo Vigencia")
			return
		}
		const vl_vigencia = this.formEdit.vigencia.replace('-','')
		const v_vigencia = this.tasaCambio.find((obj) => obj.vigencia === vl_vigencia)
		if	(typeof v_vigencia !== "undefined") {
			this.$toast.error("Vigencia ya definida. Modifiquela")
			return
		}
		if	(this.formEdit.tasa_cierre === '') {
			this.$toast.error("Falta información de la tasa del PAtrimonio")
			return
		}
		TasaCambio.grabeTasaPatrimonio(this.formEdit)
		.then((result) => {
			if	(result.status === 200) {
				this.$toast.success("Informacion Grabada")
			} else {
				this.$toast.error("Informacion no Grabada")
			}
		})
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		})
		setTimeout(()=>{
			this.leaGrupoPatrimonioTasas()
		},2000)
	},
	grabeCuentas() {
		let infoCuentas = {} 
		infoCuentas.companyId = this.companyId
		infoCuentas.tipo_cuenta = this.tipo_cuenta
		infoCuentas.listadoCuentas = this.listadoCuentas
		infoCuentas.userId = this.$store.state.userId;

		this.creacuentas = false
		TasaCambio.grabeCuentas(infoCuentas)
		.then((result) => {
			if	(result.status === 200) {
				this.$toast.success("Informacion Grabada")
			} else {
				this.$toast.error("Problemas al grabar. Consulte!")
			}
		})
		.catch(() => {
			this.$toast.error("Informacion no Grabada")
			this.error = true;
		})
		setTimeout(()=>{
			this.leaGrupoPatrimonio()
		},2000)
	},
	f_borra_cuentas(){
		this.listadoCuentas = []
		this.grabeCuentas()
		setTimeout(()=>{
			this.leaGrupoPatrimonio()
		},2000)
	},

},
mounted(){
	this.isLoading = true;

	Company.getAllSubsidiarias()
	.then((result) => {
		this.listCompany = result.data.companies
	})
	.catch((err) => {
		console.log(err)
	})



	Currency.getAllCurrency()
	.then((result) => {
		this.listCurrency = result.data.currency
	})
	.catch((error) => {
		console.log(error)
	})
}

}
</script>
<style src="../main.css"></style>
