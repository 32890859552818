import Api from '@/services/Api'

export default {
    getAllCurrency(){
        return Api().get('currency')
    },
    createCurrency(form){
        return Api().post('currency/create', form)
    },
    updateCurrency(form){
        return Api().post('currency/updated', form)
    },
    deleteCurrency(id){
        return Api().post('currency/delete/'+ id)
    }
}