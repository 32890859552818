<template>
    <div class="container">
        <md-dialog :md-active.sync="EditNiifNoContr" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.vigencia}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h5>
                            <select id="selVigencia" name="" v-model="selVigencia" class="form-control">
                                <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                            </select>
                        </h5>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h5>
                            <select id="selNiif" name="" v-model="selNiif" class="form-control">
                                <option v-for="niif in listaNiif" :key="niif.id"> {{ niif.niif }}</option>
                            </select>
                        </h5>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaNiif">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import NiifNoControlante from '../../services/NiifNoControlante';
import PlanConsolidacion from '../../services/PlanConsolidacion';

export default {
    name: "EditaNiifNoControlante",
    props:['EditNiifNoContr','regNiif'],
    data: () => ({
        listVigencia : [],
        selVigencia : "",
        listaNiif : [],
        selNiif : ""
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Editar NIIF",
                    vigencia : "Vigencia",
                    niif : "Niif",
                    save : "Guardar",
                    close : "Cerrar"
                }
            }else{
                return {
                    title : "Edita NIIF",
                    vigencia : "Validity",
                    niif : "Niif",
                    save : "Save",
                    close : "Close"
                }
            }
        }
    },
    methods:{
        guardaNiif(){
            // console.log('guardaNiif')
            if(this.selNiif == ""){
                this.$toast.error('Seleccionar un NIIF')
            }else{
                let infoData = {
                    "niif"      : this.selNiif,
                    "idNiif"    : this.regNiif,
                    "idUser"    : this.$store.state.userId
                }
                // console.log(infoData)
                NiifNoControlante.updateNiifNoControlante(infoData)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    location.reload()
                })
                .catch((err) => err)
            }

        },
        closeModal(){
            this.selVigencia = ""
            this.selNiif = ""
            this.$emit('closeModal',false)
        }
    },
    mounted(){
        PlanConsolidacion.getVigenciaPlanCons()
        .then((res) => {
            this.listVigencia = res.data.getVigencia
        })
        .catch((err) => console.log(err))
    },
    watch:{
        selVigencia(valNew){
            // console.log(valNew,valOld)
            PlanConsolidacion.getPlanConsPorCompVige(valNew)
            .then((res) => {
                this.listaNiif = res.data.planConsNiif
                // console.log(this.listaNiif)
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }
}
</script>

<style>

</style>