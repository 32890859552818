import Api from '@/services/Api'

export default {
  send (form) {
    return Api().post('invoice', form)
  },
  getAllDash(){
    return Api().get('invoice/all')
  },
  getAll(){
    return Api().get('invoice')
  },
  getAllPending(){
    return Api().get('invoice/pending')
  },
  getAllByCompany(id){
    return Api().get('invoice/company/'+ id)
  },
  // getAllByProvider(id){
  //   return Api().get('invoice/provider/' + id)
  // },
  getSingle(number, provider){
    return Api().get('invoice/single/'+provider+ '/'+number)
  },
  updatePaydate(id, date){
    return Api().put('invoice/'+id+'/paydate',{date: date})
  },
  updateComment(id, comment){
    return Api().put('invoice/'+id+'/comment',{comment: comment})
  },
  download(id){
    return Api().get('invoice/download/'+id, {
      responseType: 'blob'
  })
  },
  updateInvoiceById(invoiceId, invoice) {
    return Api().put('invoice/'+invoiceId, invoice)
  },
  deleteInvoiceById(invoiceId){
    return Api().put('invoice/'+invoiceId+'/delete')
  },
  startNewProcess(){
    return Api().get('new-runner')
  },
  getInvoiceByStatus(status){
    return Api().post('invoice/invByStatus/'+status)
  },
  invoiceFilter(info){
    return Api().post('invoice/invoiceFilter', info)
  },
  deleMultipInvoice(info){
    return Api().post('invoice/deleMultipInvoice', info)
  }
}
