import Api from '@/services/Api'

export default {
    getAllNivelUno(){
        return Api().get('nivel')
    },
    getLevTwoByLevOne(nivOne){
        return Api().get('nivel/getAllLevelTwoByLevelOne/'+nivOne)
    },
    getLevThreeByLevOneLevTwo(nivOne,nivDos){
        return Api().get('nivel/getAllLevThreeByLevOneLevTwo/'+nivOne+'/'+nivDos)
    },
    getLevFourByLevOneLevTwoLevThreeNull(nivOne,nivDos,nivTresNull){
        return Api().get('nivel/getAllLevFourByLevOneLevTwoLevThreeNull/'+nivOne+'/'+nivDos+'/'+nivTresNull)
    },
    getLevFourByLevOneLevTwoLevThree(nivOne,nivDos,nivTres){
        return Api().get('nivel/getAllLevFourByLevOneLevTwoLevThree/'+nivOne+'/'+nivDos+'/'+nivTres)
    },
    getLevDetalFourByLevOneLevTwoLevThreeLevFour(nivOne,nivDos,nivTres,nivCuatro,vigencia,compania){
        return Api().get('nivel/getLevDetFourByLevOneLevTwoLevThreeLevFour/'+nivOne+'/'+nivDos+'/'+nivTres+'/'+nivCuatro+'/'+vigencia+'/'+compania)
    },
    getLevDetalFourByLevOneLevTwoLevThreeNullLevFour(nivOne,nivDos,nivTresNull,nivCuatro,vigencia,compania){
        return Api().get('nivel/getLevDetFourByLevOneLevTwoLevThreeNullLevFour/'+nivOne+'/'+nivDos+'/'+nivTresNull+'/'+nivCuatro+'/'+vigencia+'/'+compania)
    },
    createNewCuentaNivelEfe(form){
        return Api().post('nivel/creaCuentaNivelEfe',form)
    },
    deleteCuentaNivelEfe(id){
        return Api().get('nivel/deleteCuentaNivelEfe/'+id)
    },
    deleteCuentaNivelEfeAll(dataForm){
        return Api().post('nivel/deleteCuentaNivelEfeAll',dataForm)
    },
    getCuentaNivelEfeById(id){
        return Api().get('nivel/getCuentaNivelEfeById/'+id)
    },
    updateCuentaNivelEfe(form){
        return Api().put('nivel/updateCuentaNivelEfe',form)
    },
    cuentasNoCreadas(dataForm){
        return Api().post('nivel/getCuentasNoCreadas',dataForm)
    },
    crearNivelUnoEfe(info){
        return Api().post('nivel/creaNivelUnoEfe',info)
    },
    crearNivelDosEfe(info){
        return Api().post('nivel/creaNivelDosEfe',info)
    },
    crearNivelTresEfe(info){
        return Api().post('nivel/creaNivelTresEfe',info)
    },
    crearNivelCuatroEfe(info){
        return Api().post('nivel/creaNivelCuatroEfe',info)
    },

    //INDIRECTO
    getAllNivelUnoInd(){
        return Api().get('nivelInd')
    },
    getLevTwoByLevOneInd(nivOne){
        return Api().get('nivelInd/getAllLevelTwoByLevelOne/'+nivOne)
    },
    getLevThreeByLevOneLevTwoInd(nivOne,nivDos){
        return Api().get('nivelInd/getAllLevThreeByLevOneLevTwo/'+nivOne+'/'+nivDos)
    },
    getLevFourByLevOneLevTwoLevThreeNullInd(nivOne,nivDos,nivTresNull){
        return Api().get('nivelInd/getAllLevFourByLevOneLevTwoLevThreeNull/'+nivOne+'/'+nivDos+'/'+nivTresNull)
    },
    getLevFourByLevOneLevTwoLevThreeInd(nivOne,nivDos,nivTres){
        return Api().get('nivelInd/getAllLevFourByLevOneLevTwoLevThree/'+nivOne+'/'+nivDos+'/'+nivTres)
    },
    getLevDetalFourByLevOneLevTwoLevThreeLevFourInd(nivOne,nivDos,nivTres,nivCuatro,vigencia,compania){
        return Api().get('nivelInd/getLevDetFourByLevOneLevTwoLevThreeLevFour/'+nivOne+'/'+nivDos+'/'+nivTres+'/'+nivCuatro+'/'+vigencia+'/'+compania)
    },
    getLevDetalFourByLevOneLevTwoLevThreeNullLevFourInd(nivOne,nivDos,nivTresNull,nivCuatro,vigencia,compania){
        return Api().get('nivelInd/getLevDetFourByLevOneLevTwoLevThreeNullLevFour/'+nivOne+'/'+nivDos+'/'+nivTresNull+'/'+nivCuatro+'/'+vigencia+'/'+compania)
    },
    createNewCuentaNivelEfeInd(form){
        return Api().post('nivelInd/creaCuentaNivelEfe',form)
    },
    deleteCuentaNivelEfeInd(id){
        return Api().get('nivelInd/deleteCuentaNivelEfe/'+id)
    },
    deleteCuentaNivelEfeIndAll(dataForm){
        return Api().post('nivelInd/deleteCuentaNivelEfeAll/',dataForm)
    },
    getCuentaNivelEfeByIdInd(id){
        return Api().get('nivelInd/getCuentaNivelEfeById/'+id)
    },
    updateCuentaNivelEfeInd(form){
        return Api().put('nivelInd/updateCuentaNivelEfeInd',form)
    },
    cuentasNoCreadasInd(dataForm){
        return Api().post('nivelInd/getCuentasNoCreadas',dataForm)
    },
    crearNivelUnoEfeInd(info){
        return Api().post('nivel/creaNivelUnoEfeInd',info)
    },
    crearNivelDosEfeInd(info){
        return Api().post('nivel/creaNivelDosEfeInd',info)
    },
    crearNivelTresEfeInd(info){
        return Api().post('nivel/creaNivelTresEfeInd',info)
    },
    crearNivelCuatroEfeInd(info){
        return Api().post('nivel/creaNivelCuatroEfeInd',info)
    }
}
