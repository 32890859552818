import Api from '@/services/Api'

export default {
    getAllEcpReporte(){
        return Api().get('ecpReporte')
    },
    getAllFila(){
        return Api().get('ecpReporte/getAllfila')
    },
    insertFila(infor){
        return Api().post('ecpReporte/createdFila',infor)
    },
    insertColumna(inform){
        return Api().post('ecpReporte/createdColumna',inform)
    },
    getAllColumna(){
        return Api().get('ecpReporte/getAllcolumna')
    },
    consDetalleEcp(infoData){
        return Api().post('ecpReporte/consDetallEcp',infoData)
    },
    insertDetalleEcp(informData){
        return Api().post('ecpReporte/createDetalleEcp',informData)
    },
    cuentasNoCreadas(infoCuent){
        return Api().post('ecpReporte/getCuentasNoCreadas',infoCuent)
    },
    deleteDetCuentaEcp(id){
        return Api().get('ecpReporte/delDetCuentaEcp/'+id)
    },
    getDetCuentaEcpById(id){
        return Api().get('ecpReporte/getInfoCuentaEcp/'+id)
    },
    updateDetCuentaEcp(inform){
        return Api().post('ecpReporte/updatDetCuentaEcp',inform)
    },
    deleteAllDetEcp(infor){
        return Api().post('ecpReporte/deleteAllDetEcp',infor)
    },
    updatAllDetalleEcp(inform){
        return Api().put('ecpReporte/updatAllDetEcp',inform)
    }
}