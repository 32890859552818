import Api from '@/services/Api'

export default {
    getAll(){
        return Api().get('approver')
    },
    getApproverById(id){
        return Api().get('approver/'+id)
    },
    getApproverByUserId(id){
        return Api().get('approver/approverByUser/'+id)
    },
    create(form){
        return Api().post('approver',form)
    },
    delete (id) {
        return Api().put('approver/'+ id + '/delete')
      },
    disassociateApprover(form){
        return Api().post('approver/disassociateApprover',form)
    },
  }
  