<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div>
            <b-container>
                <b-row id="home-buttons" v-for="subMenu in listaSubMenu" :key="subMenu.id">
                    <b-col md="4" class="p-2" col="3" @click="goToRoute(subMenu.ruta)">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.nombre }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" col="3">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.descripcion }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
            </b-container>
            <!-- <b-container>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/balanceNiif')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>BAL</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>BALANCE GENERAL - COMPARATIVO - CONSOLIDADO</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/reporteEsf')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>ESF</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>SITUACION FINANCIERA -COMPARATIVO - CONSOLIDADO</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/reporteEri')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>ERI</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>RESULTADO INTEGRAL</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/reporteEfe')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>EFE</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>FLUJO DE EFECTIVO</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/reporteEcp')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>ECP</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>CAMBIO EN EL PATRIMONIO</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/eliminaciones')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>ELIMINACIONES</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>ELIMINACION DE SALDOS</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-2" cols="4" @click="goToRoute('/ajustesTopside')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'list']" />
                                        <span>TOPSIDE</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" cols="4">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>AJUSTES TOPSIDE</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
                <b-row id="home-buttons">
                    <b-col md="4" class="p-1" cols="3" @click="goToRoute('/reclasificaBalance')">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta"><font-awesome-icon :icon="['fas', 'sitemap']" />
                                        <span>{{ labels.reclasifica }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-1" cols="3">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ labels.reclasificad }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
            </b-container> -->
        </div>
    </div>
</template>
<script>
    import SubMenu from '../../services/SubMenu';
    export default {
        name:"Consolidacion",

        data: () => ({
            listaSubMenu : []
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "CONSOLIDACIÓN",
			reclasifica: "RECLASIFICA",
			reclasificad: "RECLASIFICACION DE SALDOS DE BALANCE",
                    }
                }else{
                    return {
                        title: "CONSOLIDATION",
                        reclasifica: "RECLASSIFY",
                        reclasificad: "RECLASSIFY ACCOUNTING STATMENT",
                    }
                }
            }
        },
        methods:{
            goToRoute(page){
                this.$router.push({path: page })
            }
        },
        mounted(){
            /*SubMenu.subMenuByIdMenu(5)
            .then((res) => {
                // console.log(res.data.listSubMenu)
                // console.log(this.$store)
                this.listaSubMenu = res.data.listSubMenu
            })
            .catch((err) => { console.log(err)})*/

            SubMenu.subMenuByMenuByRol(5,this.$store.state.userRole)
            .then((res) => {
                // console.log(res.data.submenus)
                // console.log(this.$store)
                this.listaSubMenu = res.data.submenus
            })
            .catch((err) => { console.log(err)})
        }
    }

</script>
<style src="../main.css"></style>
