<template>
    <div class="container">
        <md-dialog :md-active.sync="modCreaRedFila" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="number" v-model="niif" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.descripcion}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <md-input type="text" v-model="descripcion" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.primerNivel}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <!-- <select v-model="primerNivel" class="form-control">
                            <option v-for="firstLev in listFirstLevel" :key="firstLev.id" :value="firstLev.primer_nivel">{{ firstLev.descripcion }}</option>
                        </select> -->
                        <md-input type="number" v-model="primerNivel" class="form-control"/>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.segundoNivel}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <!-- <select v-model="segundoNivel" class="form-control">
                            <option v-for="secondLev in listSecondLev" :key="secondLev.id" :value="secondLev.segundo_nivel">{{ secondLev.descripcion }}</option>
                        </select> -->
                        <md-input type="number" v-model="segundoNivel" class="form-control"/>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.actual}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <select v-model="actual" class="form-control">
                            <option value="1"> {{ labels.yes }} </option>
                            <option value="0"> {{ labels.not }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.operacion}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <select v-model="operacion" class="form-control">
                            <option value="+"> {{ labels.sumar }} </option>
                            <option value="-"> {{ labels.restar }} </option>
                        </select>
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="5" class="text-left">
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item">
                                <select multiple v-model="niifSelect" class="form-control">
                                    <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{ niif.niif }} {{ niif.nameNiif }}</option>
                                </select>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaRedFila">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import PrimerNivel from '../../../services/PrimerNivel'
import SegundoNivel from '../../../services/SegundoNivel'
import PlanConsolidacion from '../../../services/PlanConsolidacion';
import RedefinicionFila from '../../../services/RedefinicionFila';

export default {
    name: 'CreaRedefinicionFila',
    props:['modCreaRedFila','vigenciaSelected'],
    data: () => ({
        niif : "",
        descripcion : "",
        primerNivel : "",
        listFirstLevel : [],
        segundoNivel : "",
        listSecondLev : [],
        actual : "",
        operacion : "",
        niifSelect : [],
        listNiif : [],
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Crear fila",
                    save : "Guardar",
                    close : "Cerrar",
                    niif : "Niif:",
                    descripcion : "Descripción:",
                    primerNivel : "Primer nivel:",
                    segundoNivel : "Segundo nivel:",
                    actual : "Actual:",
                    yes : "SI",
                    not : "NO",
                    operacion : "Operación:",
                    sumar : "+",
                    restar : "-",
                }
            }else{
                return {
                    title : "Create row",
                    save : "Save",
                    close : "Close",
                    niif : "Niif:",
                    descripcion : "Description:",
                    primerNivel : "First level:",
                    segundoNivel : "Second level:",
                    actual : "Current:",
                    yes : "YES",
                    not : "NOT",
                    operacion : "Operation:",
                    sumar : "+",
                    restar : "-",
                }
            }
        }
    },
    methods:{
        closeModal(){
            this.$emit('cierraModal',false)
            this.niifSelect = []
        },
        guardaRedFila(){
            let formData = {
                'niif' : this.niif,
                'descripcion' : this.descripcion,
                'primerNivel' : this.primerNivel,
                'segundoNivel' : this.segundoNivel,
                'actual' : this.actual,
                'operacion' : this.operacion,
                'niifSelect' : this.niifSelect,
                'idUser' : this.$store.state.userId,
            }
            // console.log(formData)

            RedefinicionFila.createRedefinicionFila(formData)
            .then((res) => {
                // console.log(res.data.message)
                this.$toast.success(res.data.message)
                location.reload()
            })
            .catch((err) => console.log(err)) 
        }
    },
    mounted(){
        PrimerNivel.getAllLevelFirst()
        .then((result) => {
            // console.log(result.data.primerNivel)
            this.listFirstLevel = result.data.primerNivel
        })
        .catch((err) => {
            console.log(err)
        })
    },
    watch:{
        primerNivel(firstLev){
            SegundoNivel.getAllLevelSecondByLevelFirst(firstLev)
            .then((res) => {
                // console.log(res.data)
                this.listSecondLev = res.data.secondLevByFirstLev
            })
            .catch((err) => console.log(err))
        },
        modCreaRedFila(val){
            // console.log(val)
            if(val){
                PlanConsolidacion.getPlanConsPorCompVige(this.vigenciaSelected)
                .then((res) => {
                    // console.log(res.data.planConsNiif)
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => console.log(err))
            }
        }
    }
}
</script>

<style>

</style>