import Api from '@/services/Api'

export default {
    createCompany (form) {
      return Api().post('companies/create', form)
    },
    grabeFormato (form) {
      return Api().post('formatos/update', form)
    },
    getAllCompanies(){
        return Api().get('companies')
    },
    getAllSubsidiarias(){
        return Api().get('subsidiarias')
    },
    getAllFormatos(){
        return Api().get('formatos')
    },
    getCompanyById(id){
      return Api().get('companiById/'+ id)
    },
    updateCompany (form) {
      return Api().put('companies/update', form)
    },
    inactivateCompany (id) {
      return Api().put('companies/inactivate/'+ id)
    },
    deleteCompany (id) {
      return Api().put('companies/delete/'+ id)
    },
  }
  
