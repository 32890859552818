<template>
  <div class="container">
    <md-dialog :md-active.sync="showEdiAjustOrig" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content>
            <!-- <pre>
                {{ dataEdit }}
            </pre> -->
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.cuenta}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <h6>{{ dataEdit.cuenta }}</h6>
                </b-col>
            </b-row>
            <!-- <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.description}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <h6>{{ dataEdit.descripcion }}</h6>
                </b-col>
            </b-row> -->
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.valor}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="dataEdit.valor"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.funcional}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="dataEdit.funcional"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.alterna}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <md-field>
                        <md-input v-model="dataEdit.alterna"></md-input>
                    </md-field>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="guardaAjuste">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import AjustesConsol from '../../services/AjustesConsol'

export default {
    name:"EditAjustesOrigen",
    props:['showEdiAjustOrig','dataEdit'],
    data: () => ({

    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Edita ajuste",
                    save : "Guardar",
                    close : "Cerrar",
                    cuenta : "Cuenta",
                    description : "Descripción",
                    valor : "Valor",
                    funcional : "Funcional",
                    alterna : "Alterna"
                    
                }
            }else{
                return {
                    title : "Edit setting",
                    save : "Save",
                    close : "Close",
                    cuenta : "Account",
                    description : "Description",
                    valor : "Value",
                    funcional : "Functional",
                    alterna : "Alternate"
                    
                }
            }
        }
    },
methods:{
	guardaAjuste(){
		if	(this.dataEdit.valor === '') {
			this.dataEdit.valor = null
		}
		if	(this.dataEdit.funcional === '') {
			this.dataEdit.funcional = null
		}
		if	(this.dataEdit.alterna === '') {
			this.dataEdit.alterna = null
		}
		let form = {
			'id' :  this.dataEdit.id,
			'vigencia' :  this.dataEdit.vigencia,
			'companyId' :  this.dataEdit.id_compania,
			'valor' :  this.dataEdit.valor,
			'funcional' :  this.dataEdit.funcional,
			'alterna' :  this.dataEdit.alterna,
			'idUser' :  this.$store.state.userId,
		}
		if	(form.valor != null &&
			(form.funcional === null ||
			form.alterna === null)) {
			this.$toast.error("Funcional y Alterna no pueden ser nulos")
			return
		}

		if	(form.valor === null &&
			(form.funcional != null ||
			form.alterna != null)) {
			this.$toast.error("Funcional y Alterna deben ser nulos")
			return
		}

		AjustesConsol.updateAjusteOrigen(form)
		.then((res) => {
		// console.log(res.data.message)
			if(res.status == 200){
				this.$toast.success(res.data.message)
			}
		})
		.catch((error) => {
			console.log(error)
		})
		.finally(() => {
			setTimeout(() => {
			this.reloadTbl()
			}, 2000)
		})

	},
	closeModal(){
		this.$emit('closeModal',false)
	},
	reloadTbl(){
		let form = {
			'id' :  this.dataEdit.secuencia,
			'vigencia' :  this.dataEdit.vigencia,
			'idCompany' :  this.dataEdit.id_compania
			}
		this.$emit('reloadTblOrig',form)
	}
}
}
</script>

<style>

</style>
