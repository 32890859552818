<template>
    <div class="container">
        <div class="col-md-12">
            <h2>{{ labels.title }}</h2>
        </div>
        <div>
            <b-container>
                <b-row id="home-buttons" v-for="subMenu in listaSubMenu" :key="subMenu.id">
                    <b-col md="4" class="p-2" col="3" @click="goToRoute(subMenu.ruta)">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.nombre }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" col="3">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.descripcion }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
import SubMenu from '../../services/SubMenu';

    export default {
        name:"Administrador",
        data: () => ({
            niif:"",
            descripcion:"",
            listaSubMenu : []
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Administrador",
                        users: "Usuarios",
                        usersd: "Administración de Usuarios",
                        menu: "Menús",
                        descripMenu: "Administración de Menús",
                        subMenu: "Submenús",
                        descripSubMenu: "Administración de submenus",
                        roles: "Roles",
                        descripRole: "Administración de Roles",
                        cargues: "Cargues",
                        carguesd: "Definición de formatos de cargues",
                    }
                }else{
                    return {
                        title: "Administrator",
                        users: "Users",
                        usersd: "Users Administration",
                        menu: "Menus",
                        descripMenu: "Menus Administration",
                        subMenu: "Submenus management",
                        descripSubMenu: "submenus description",
                        roles: "Role",
                        descripRole: "Role Administration",
                        cargues: "Uploads",
                        carguesd: "Upload Format Definition",
                    }
                }
            }
        },
        methods:{
            goToRoute(page){
                this.$router.push({path: page })
            }
        },
        mounted(){
            /*SubMenu.subMenuByIdMenu(2)
            .then((res) => {
                console.log(res.data.listSubMenu)
                // console.log(this.$store)
                // this.listaSubMenu = res.data.listSubMenu
            })
            .catch((err) => { console.log(err)})*/

            SubMenu.subMenuByMenuByRol(2,this.$store.state.userRole)
            .then((res) => {
                // console.log(res.data)
                // console.log(this.$store)
                this.listaSubMenu = res.data.submenus
            })
            .catch((err) => { console.log(err)})
        }
    }

</script>
<style src="../main.css"></style>
