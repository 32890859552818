import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    _language: navigator.language,
    token: null,
    userName: null,
    userRole: null,
    companyId: null,
    expiration: 0,
    isAuthenticated: false
  },
  mutations: {
    setData(state, val){
        state.token = val.token
        state.userName = val.username
        state.providerId = val.inscriptionId
        state.userRole = val.role
        const now = new Date();
        const expirationDate = new Date(now.getTime() + val.expiresIn * 1000);
        state.userId = val.id
        state.expiration = expirationDate
        state.isAuthenticated = val.isAuthenticated
        state.companyId = val.companyId
    },
    setLang(state, val){
        state._language = val
    }
  },
//   actions: {

//   },
//   getters : {

//   }
})

export default store