import Api from '@/services/Api'

export default {
    getAllLevelThird(){
        return Api().get('getAllLevelThird')
    },
    getLevelThirdByNivUnoAndNivDos(nivUno,nivDos){
        return Api().get('getLevelThirdByNivUnoAndNivDos/'+nivUno+'/'+nivDos)
    },
    createThirdLevel(form){
         return Api().post('thirdLevel/create',form)
    },
}
