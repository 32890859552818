import Api from '@/services/Api'

export default {
    getAllUsers(){
      return Api().get('users')
    },
    getAllUsersById(id){
      return Api().get('users/getAllUsersById/'+id)
    },
    getAllByCompany(id){
      return Api().get('users/company/'+id)
    },
    deleteUser (id) {
      return Api().put('users/delete/'+ id)
    },
    inactivarUser(id){
      return Api().put('users/update/'+ id)
    },
    createUser (form){
      // return Api().post('admin/users', form)
      return Api().post('users', form)
    },
    updateUser(form){
      return Api().post('users/updated', form)
    },
    getAllRoles(){
      return Api().get('roles')
    },
    getAllRolesById(id){
      return Api().get('roles/id/'+id)
    },
    updateRolById(id,nombre,descripcion){
      return Api().put('roles/update/'+ id+ '/' +nombre+ '/'+descripcion)
    },
    createRol(form){
      return Api().post('roles', form)
    },
    inactivarRol(id){
      return Api(id).post('roles/inactivar/'+id)
    }
  }
  