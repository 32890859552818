<template>
    <div class="container">
        <div class="col-md-12">
            <h1>{{ labels.title }}</h1>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-3 h5">
                <label for="selVigencia" class=""> {{ labels.report }}</label>
                <select id="selVigencia" name="" v-model="selReporte" @click="listPrimerNivel=[];fgl_consulta=false">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="reporte in listaReporte" :key="reporte.id" :value="reporte.codigo"> {{ reporte.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-md-12">
                <b-button @click="consultFirstNivel(); fgl_consulta=true" id="consult-btn">{{ labels.consultar }}</b-button>
            </div>
        </div>
        <div class="row col-md-12 mt-4" v-if="fgl_consulta">
            <div class="col-md-2 d-flex justify-content-start">
                <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newLevelOne">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
            <div class="col-md-2 d-flex justify-content-end">
            </div>
            <div class="col-md-2 d-flex justify-content-start" v-if="showNivDos">
                <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newLevelDos">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
            <div class="col-md-2 d-flex justify-content-end" v-if="showNivDos">
            </div>
            <div class="col-md-2 d-flex justify-content-start" v-if="showNivTres">
                <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newLevelTres">
                    <md-icon>add</md-icon>
                </md-button>
            </div>
            <div class="col-md-2 d-flex justify-content-end" v-if="showNivTres">
            </div>
        </div>
        <div class="row col-md-12 mt-2" v-if="listaPrimerNivel.length != 0">
            <div class="col-md-4">
                <div class="headNivUno">{{ labels.levelOne }}</div>
                <div class="bodyNivUno">
                    <select name="" id="" class="form-control" v-model="selPrimerLev">
                        <option value="">{{ labels.select }}</option>
                        <option v-for="nivUno in listaPrimerNivel" :key="nivUno.id" :value="nivUno.primer_nivel">{{ nivUno.primer_nivel }}. {{ nivUno.descripcion }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4" v-if="showNivDos">
                <div class="headNivUno">{{ labels.levelTwo }}</div>
                <div class="bodyNivUno">
                    <select name="" id="" class="form-control" v-model="selSegundoLev" v-if="showSelDos">
                        <option value="">{{ labels.select }}</option>
                        <option v-for="nivDos in listSegundoNivel" :key="nivDos.id" :value="nivDos.segundo_nivel">{{ nivDos.segundo_nivel }}. {{ nivDos.descripcion }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4" v-if="showNivTres">
                <div class="headNivUno">{{ labels.levelThree }}</div>
                <div class="bodyNivUno">
                    <select name="" id="" class="form-control" v-model="selTercerLev" v-if="showSelTres">
                        <option value="">{{ labels.select }}</option>
                        <option v-for="nivTres in listTercerNivel" :key="nivTres.id" :value="nivTres.tercer_nivel">{{ nivTres.tercer_nivel }}. {{ nivTres.descripcion }}</option>
                    </select>
                </div>
            </div>
        </div>
        <NewLevelFirst :showDialog="showDialog" :CodigoReport="selReporte" @clicked="closeModal"></NewLevelFirst>
        <NewLevelSecond :showNewSegundoLev="showNewSegundoLev" :levelUnoSel="selPrimerLev" :CodigoReport="selReporte" @clickedSegundo="closeModalSegundo"></NewLevelSecond>
        <NewLevelThird :showNewTercerLev="showNewTercerLev" :levelUnoSel="selPrimerLev" :levelDosSel="selSegundoLev" @clickedTercero="closeModalTercero"></NewLevelThird>
    </div>
</template>
<script>

import PrimerNivel from "../../services/PrimerNivel"
import SegundoNivel from "../../services/SegundoNivel";
import TercerNivel from "../../services/TercerNivel";
import TipoReporte from "../../services/TipoReporte";
import NewLevelFirst from "./NewLevelFirst.vue";
import NewLevelSecond from "./NewLevelSecond.vue";
import NewLevelThird from "./NewLevelThird.vue";

export default {
    name: "PlanConsolidacion",
    components: {
        NewLevelFirst,
        NewLevelSecond,
        NewLevelThird
    },
    data: () => ({
        listaPrimerNivel : [],
        selPrimerLev : "",
        listSegundoNivel : [],
        selSegundoLev : "",
        listTercerNivel : [],
        selTercerLev : "",
        showDialog: false,
        showDialogEdit: false,
        showNewSegundoLev: false,
        showNewTercerLev: false,
	fgl_consulta: false,
        formEdit: {
            id: '',
            primer_nivel: '',
            descripcion: ''
        },
        showNivDos : false,
        showSelDos : false,
        showNivTres : false,
        showSelTres : false,
        listaReporte : [],
        selReporte : ''
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    informacion: "Modificacion",
                    title : "Esquema de consolidación",
                    select : "Seleccione...",
                    report : "Reporte: ",
                    consultar : "Consultar",
                    colLevelFirst : "Primer nivel",
                    levelFirst : "Descripcion",
                    levelSecond : "Segundo Nivel",
                    editar : "Editar",
                    levelOne : "Nivel Uno",
                    levelTwo : "Nivel Dos",
                    levelThree : "Nivel Tres",
                }
            }else{
                return {
                    informacion: "Update",
                    title : "Consolidation Schedule",
                    select : "Select...",
                    report : "Report: ",
                    consultar : "Consult",
                    colLevelFirst : "First Level",
                    levelFirst : "Description",
                    levelSecond : "Second Level",
                    editar : "Edit",
                    levelOne : "Level One",
                    levelTwo : "Level Two",
                    levelThree : "Level Three",
                }
            }
        }
    },
    methods: {
        consultFirstNivel(){
            if(this.selReporte != ''){

                PrimerNivel.getFirstLevelByReporte(this.selReporte)
                .then((res) => {
                    this.listaPrimerNivel = res.data.firstLevByReport
                })
                .catch((error) => console.log(error))
            }else{
                this.$toast.error('Seleccionar un reporte')
            }
        },
        infoSecondLevel(primer_nivel){
            this.$router.push({path: `planConsolidacion/SegundoNivel/${primer_nivel}`})
        },
        closeModal(codReporte,value){
		if	(codReporte) {
			this.codReporte = codReporte
		}
		this.showDialog = value
		this.showNewPrimerLev = value
		this.showNewSegundoLev = value
		this.showNewTercerLev = value
		this.showNivTres = false
		this.showNivDos = false
		this.listSegundoNivel = []
		this.listTercerNivel = []
		this.selPrimerNivel=''
		this.selSegundoNivel=''
		this.selTercerNivel=''
                PrimerNivel.getFirstLevelByReporte(this.selReporte)
		.then((res) => {
                    this.listaPrimerNivel = res.data.firstLevByReport
                })
                .catch((error) => console.log(error))
        },
        closeModalSegundo(nivUnoSel,codReporte,value){
		this.showNewSegundoLev = value
		this.showNewTercerLev = value
		if	(nivUnoSel) {
			this.selPrimerNivel=nivUnoSel
		}
		if	(codReporte) {
			this.codReporte=codReporte
		}
            SegundoNivel.getAllLevelSecondByLevelFirstByReport(this.selPrimerLev,this.codReporte)
            .then((res) => {
                this.listSegundoNivel = res.data.secondLevByFirstLev

                if(this.listSegundoNivel.length > 0){
                    this.showNivDos = true
                    this.showSelDos = true
                }else{
                    this.showNivDos = true
                    this.showSelDos = false
                    this.$toast.error('Nivel(1) sin Reclasificar.')
                }

                this.showNivTres = false
		this.selSegundoNivel=''
		this.selTercerNivel=''
                this.listTercerNivel = []
            })
            .catch((error) => {
                console.log(error)
                this.showNivDos = false
            })
        },
        closeModalTercero(value){

		this.showNewTercerLev = value
		TercerNivel.getLevelThirdByNivUnoAndNivDos(this.selPrimerLev,this.selSegundoLev)
		.then((res) => {
			this.listTercerNivel = res.data.nivelTres
			this.showNivTres = true
			this.showSelTres = true
			this.selTercerNivel=''
			if	(this.listTercerNivel.length == 0){
				this.showNivTres = true
				this.showSelTres = true
				this.$toast.error('Nivel(2) sin Reclasificar.')
			}
		})
		.catch((error) => {
			console.log(error)
		})
        },
        newLevelOne(){
            this.showDialog = true;
        },
        editLevelOne(){
            console.log("editLevelOne")
        },
        newLevelDos(){
            this.showNewSegundoLev = true
        },
        editLevelDos(){
            console.log("editLevelDos")
        },
        newLevelTres(){
            this.showNewTercerLev = true
        },
        editLevelTres(){
            console.log("editLevelTres")
        },
    },
    mounted(){
        TipoReporte.getAllTipoReporte()
        .then((res) => {
            this.listaReporte = res.data.typeReport
        })
        .catch((error) => console.log(error))
    },
    watch:{
        selPrimerLev(newVal){
	if	(newVal) {
		this.selPrimeLev = newVal
	}
            SegundoNivel.getAllLevelSecondByLevelFirstByReport(this.selPrimerLev,this.selReporte)
            .then((res) => {
                this.listSegundoNivel = res.data.secondLevByFirstLev

                if(this.listSegundoNivel.length > 0){
                    this.showNivDos = true
                    this.showSelDos = true
                }else{
                    this.showNivDos = true
                    this.showSelDos = false
                    this.$toast.error('Nivel(1) sin Reclasificar.')
                }

                this.showNivTres = false
                this.listTercerNivel = []
            })
            .catch((error) => {
                console.log(error)
                this.showNivDos = false
            })
        },
        selSegundoLev(valSegNiv){
		this.showNivTres = false
		this.showSelTres = false
		if	(valSegNiv) {
			this.selSegundoLev = valSegNiv
		}
		console.log("selSegundoLev",this.selPrimerLev,valSegNiv)
/*           TercerNivel.getLevelThirdByNivUnoAndNivDos(this.selPrimerLev,valSegNiv)
            .then((res) => {
			this.listTercerNivel = res.data.nivelTres
			this.showNivTres = true
			this.showSelTres = true
			if	(this.listTercerNivel.length == 0){
				this.showNivTres = true
				this.showSelTres = true
				this.$toast.error('Nivel(2) sin Reclasificar.')
			}
            })
            .catch((error) => {
                console.log(error)
            })
*/
        },
    }
}
</script>
<style src="../main.css"></style>
