<template>
  <div>
    <!-- <Edit/> -->
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{labels.title}}
        </h1>
      </b-container>
    </b-row>
    <b-row>
      <b-container>
        <h4 class="text-center border-bottom">
          {{labels.subtitle}}
        </h4>
        <b-col md="8" class="mx-auto">
          <ul>
            <li v-for="(item, idx) in labels.instructive" :key="idx">{{ item }}</li>
          </ul>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container>
        <b-col md="10" class="mx-auto"> 
          <b-button @click="goToCreateApprover()" id="create-btn">{{labels.associate}}</b-button>&nbsp;&nbsp;
          <b-button @click="disassociateApprover()" id="create-btn">{{labels.disassociate}}</b-button>
        </b-col>
      </b-container>
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{labels.info}}
        </h4>
        <!-- <div class="col-md-4">
          <md-field md-inline>
            <label>{{labels.name}}</label>
            <md-input v-model="searchApprover" @input="searchOnTableApprover"></md-input>
          </md-field>
        </div> -->
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">{{labels.name}}</th>
              <th scope="col">{{labels.informat}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="appro in datosPaginados" :key="appro.id">
              <td>{{getUsername(appro.userId)}}</td>
              <td>
                <md-button @click="showInfo(appro.id)" class = "md-raised md-primary btn-dangerr"><md-icon>menu</md-icon></md-button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <!-- <li v-for="pagina in pages.slice(paginaActual-1, paginaActual+9)" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li> -->
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
        <!-- <b-col md="12" class="mx-auto">
        <md-table
          v-model="searched"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          md-card
        >
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-start">
              <md-input
                :placeholder="labels.search"
                v-model="search"
                @input="searchOnTable"
              />
            </md-field>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showInfo(item.id)">
            
            <md-table-cell :md-label="labels.person" md-sort-by="person">{{
              getUsername(item.userId) 
            }}</md-table-cell>
          </md-table-row>
        </md-table>
  </b-col> -->
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="searched.length == 0 && approvers.length > 0 && !isLoading"
        >
          <h3>{{labels.notFound}}</h3>
          <p>{{labels.notFoundMessage}}: <span>{{ search }}</span></p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{labels.empty}}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{labels.information}}</md-dialog-title>
      <md-dialog-content  md-dynamic-height>
        <b-row>
          <b-col>{{labels.dialog.username}}</b-col>
          <b-col>{{getUsername(dialogItem.userId)}}</b-col>
        </b-row>
        <hr>
        <h6>{{labels.dialog.providers}}</h6>
          <div v-for="(item,idx) in dialogItem.providers" :key="idx" class="provider-row">
          <b-row >
            <b-col>{{labels.dialog.nit}}</b-col>
            <b-col>{{item.nit}}</b-col>
          </b-row>
          <b-row> 
            <b-col>{{labels.dialog.name}}</b-col>
            <b-col>{{item.name}}</b-col>
          </b-row>
          </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-accent" @click="showDialog = false">Cerrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Approver from "@/services/Approver";
import User from "@/services/User";
// import Edit from "./Edit.vue";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByApprover = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.username).includes(toLower(term))
    );
  }

  return items;
};
export default {
  name: "List",
  // components: {
  //   Edit
  // },
  data: () => ({
    currentSort: "nit",
    currentSortOrder: "asc",
    showDialog: false,
    dialogItem: {},
    approvers: [],
    users: [],
    // inscriptions: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    elementosPorPagina:10,
    datosPaginados: [],
    paginaActual: 1,
    searchApprover: null,
    // showDialogEdit: false,
  }),
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Administra los usuarios asignados a cada proveedor",
          subtitle: "Instrucciones",
          instructive: ["Bienvenid@ a la plataforma para la visualización y creación de aprobadores"],
          info: "Información General",
          informat: "Información",
          search: "Buscar por nombre de proveedor",
          information: "Información del Aprobador",
          tapLabel: "Datos",
          createdAt: "Fecha Creación",
          name: "Nombre del Aprobador",
          nit: "NIT",
          person: "Aprobador",
          empty: "Aún no cuentas con usuarios que aprueben facturas",
          create: "Crear",
          associate: "Asociar",
          disassociate: "Desasociar",
          active: "Activo",
          inactive: "Inactivo",
          status: "Estado",
          dialog: {
              username: "Nombre",
              name: "Nombre",
              nit: "NIT",
              providers: "Proveedores"
          }
        }
      }else {
        return {
          title: "Approvers Visualization Platform",
          subtitle: "Instructions",
          instructive: ["Welcome to the platform for the visualization and creation of approvers."],
          info: "General Information",
          search: "Search by provider name",
          information: "Información del Usuario",
          informat: "Information",
          tapLabel: "Datos",
          createdAt: "Created Date",
          name: "Approver Name",
          nit: "NIT",
          person: "Approver",
          empty: "You don't have approvers yet",
          create: "Create",
          associate: "Associate",
          disassociate: "Disassociate",
          active: "Activo",
          inactive: "Inactive",
          status: "Status",
          dialog: {
              username: "Name",
              name: "Name",
              nit: "NIT",
              providers: "Providers"
          }
        }
      }
    }
  },
  methods: {
    goToCreateApprover(){
      this.$router.push({path: '/approver/create'})
    },
    disassociateApprover(){
      this.$router.push({path: '/approver/disassociate'})
    },
    showInfo(id) {
      const currentIdx = this.approvers.findIndex((obj) => obj.id == id);
      this.dialogItem = this.approvers[currentIdx];
      // console.log(this.dialogItem)
      this.showDialog = true
    },
    deleteUser(id) {
      this.isLoading = true;
      Approver.delete(id).then(() => {
        this.isLoading = false;
      });
      this.approvers = this.approvers.filter( user => user.id != id );
      this.searched = this.approvers
    },
    customSort(value) {
      // console.log(value);
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }

        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTableApprover() {
      this.datosPaginados = searchByApprover(this.users, this.searchApprover);
      if(this.searchApprover == ''){
        this.getDataPagina(1)
      }
    },
    getUsername(id){
      let idx = this.users.findIndex(obj => obj.id == id)
      return idx >= 0 ? this.users[idx].username : id
    },
    totalPaginas(){
      // let numberOfPages = Math.ceil(this.searched.length / this.elementosPorPagina);
      // for (let i = 1; i <= numberOfPages; i++) {
      //     this.pages.push(i);
      // }
      // return numberOfPages
      return Math.ceil(this.searched.length / this.elementosPorPagina)
    },
    showPaginas(){
      let inicio = this.paginaActual;
      let cantReg = 9;  
      // let fin = this.paginaActual - 5;
      let result =  inicio  + cantReg;
      return result;
    },
    getDataPagina(noPagina){
      this.datosPaginados = [];
      this.paginaActual = noPagina;
      let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
      let fin = (noPagina * this.elementosPorPagina);

      this.datosPaginados = this.searched.slice(ini,fin);
    },
    getPreviousPage(){
      if(this.paginaActual > 1){
          this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage(){
      if(this.paginaActual < this.totalPaginas()){
          this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    }
  },
  mounted() {
    this.isLoading = true;
    Approver.getAll()
      .then((result) => {
        // console.log(result.data)
        this.approvers = result.data.data;
        this.searched = this.approvers;
        this.getDataPagina(1);
        if (this.approvers.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        // console.log(error);
        this.error = true;
      })
      User.getAllUsers()
      .then((result) => {
        this.users = result.data.users;
        // this.inscriptions = result.data.users;
      }).catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  // watch:{
  //   inscriptions(){
  //     this.totalPaginas()
  //   }
  // },
};
</script>

<style src="../main.css">
</style>
