<template>
  <div class="container">
    <md-table md-card md-fixed-header v-model="detalleEcp">
        <md-table-toolbar>
            <h1 class="md-title">{{ labels.detail }}</h1>
            <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="deleteAllEcp">
                <md-icon>delete</md-icon>
            </md-button>
            <md-button style="background-color: #e5ac00; width: 35px; height: 35px;" class="md-fab md-primary" @click="editAllEcp">
                <md-icon>edit</md-icon>
            </md-button>
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="newEcp">
                <md-icon>add</md-icon>
            </md-button>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="labels.contable">{{ getContable(item.contable) }}</md-table-cell>
            <md-table-cell :md-label="labels.cuenta">{{ item.cuenta }}</md-table-cell>
            <md-table-cell :md-label="labels.actual">{{ getActual(item.actual) }}</md-table-cell>
            <md-table-cell :md-label="labels.operacion">{{ item.operacion }}</md-table-cell>
            <md-table-cell :md-label="labels.formulacion">{{ getFormulacion(item.formulacion, item.contable) }}</md-table-cell>
            <md-table-cell :md-label="labels.editar">
                <md-button style="background-color: #e5ac00; width: 35px; height: 35px;" class="md-fab md-primary" @click="editCuenta(item.id)">
                    <md-icon>edit</md-icon>
                </md-button>
            </md-table-cell>
            <md-table-cell :md-label="labels.eliminar">
                <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="deleteDetaEcp(item.id)">
                    <md-icon>delete</md-icon>
                </md-button>
            </md-table-cell>
        </md-table-row>
    </md-table>
    <NewCuentaEcp :modalCreaEcp="modalCreaEcp" @cierraMod="closeMod" :vigComSelect="ItemVigCom" :cuentasCreadas="listCuentExist" @reloadTable="recargaTbl"/>
    <EditaCuentaEcp :modalEditaEcp="modalEditaEcp" :dataDetallEcp="infoDetEcp" @cierraMod="closeMod" @reloadTable="recargaTbl" />
    <EditMasivoEcp :modalEditAllEcp="modalEditAllEcp" :dataVigComFilCol="consulDetallEcp" @closeModal="closeMod" @reloadTable="recargaTbl"></EditMasivoEcp>
    <ConfirmDialog :showConfirmDialog="showConfirmDialog" @cancelDel="closeMod" @confirmDelete="confirmDelete" />
  </div>
</template>

<script>
import NewCuentaEcp from './NewCuentaEcp.vue'
import EditaCuentaEcp from './EditaCuentaEcp.vue'
import EditMasivoEcp from './EditMasivoEcp.vue'
import EcpReporte from '../../services/EcpReporte'
import ConfirmDialog from '../niveles/ConfirmDialog.vue'

export default {
    name:"EcpDetalle",
    props:['consulDetallEcp','ItemVigCom'],
    components:{
        NewCuentaEcp,
        EditaCuentaEcp,
        EditMasivoEcp,
        ConfirmDialog
    },
    data: () => ({
        detalleEcp : [],
        modalCreaEcp: false,
        modalEditaEcp: false,
        modalEditAllEcp: false,
        listCuentExist : [],
        showConfirmDialog: false,
        infoDetEcp:{},
        deleteAllReg: false,
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    detail : "Detalle",
                    contable: "Contable",
                    cuenta : "Cuenta",
                    actual : "Actual",
                    operacion: "Operación",
                    formulacion: "Formulación",
                    editar: "Editar",
                    eliminar: "Eliminar",
                }
            }else{
                return {
                    detail : "Detail",
                    contable: "Accountant",
                    cuenta : "Account",
                    actual : "Actual",
                    operacion: "Operation",
                    formulacion: "Formulation",
                    editar: "Edit",
                    eliminar: "Delete",
                }
            }
        }
    },
    methods:{
        getContable(valContable){
            return valContable == 1 ? 'SI' : 'NO'
        },
        getActual(valActual){
            return valActual == 1 ? 'SI' : 'NO'
        },
        getFormulacion(valFormulacion, valContable){
            
            if(valContable == 1){
                return ""
            }else{
                return valFormulacion
            }
        },
        newEcp(){
            this.modalCreaEcp = true
        },
        async editCuenta(item){
            // console.log(item)
            let info = await EcpReporte.getDetCuentaEcpById(item)
            // console.log(info)
            this.infoDetEcp = info.data.detCuentEcp
            this.modalEditaEcp = true
        },
        editAllEcp(){
            // console.log("editAllEcp")
            this.modalEditAllEcp = true
        },
        deleteDetaEcp(item){
            this.regDel = item
            this.deleteAllReg = false
            this.showConfirmDialog = true
        },
        deleteAllEcp(){
            // console.log("deleteAllEcp")
            this.deleteAllReg = true
            this.showConfirmDialog = true;
        },
        confirmDelete(){

            if(this.deleteAllReg){
                // console.log("DELETE ALL",this.ItemVigCom)
                EcpReporte.deleteAllDetEcp(this.ItemVigCom)
                .then((res) => {
                    if(res.status == 200){
                        this.$toast.success(res.data.message)
                        this.showConfirmDialog = false
                        this.recargaTbl(this.ItemVigCom)
                    
                    }else if(res.status == 404){
                        this.$toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }else{
                // console.log("DELETE ONE")
                EcpReporte.deleteDetCuentaEcp(this.regDel)
                .then((res) => {
                    if(res.status == 200){
                        this.$toast.success("Se ha eliminado el registro.")
                        this.showConfirmDialog = false
                        this.recargaTbl(this.ItemVigCom)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        },
        closeMod(val){
            this.modalCreaEcp = false
            this.modalEditaEcp = false
            this.modalEditAllEcp = val
            this.showConfirmDialog = val
        },
        recargaTbl(itemRef){
            EcpReporte.consDetalleEcp(itemRef)
            .then((res) => {
                if(res.status == 200){
                    this.detalleEcp = res.data.detEcp
                
                }else if(res.status == 204){
                    this.detalleEcp = []
                    this.$toast.error("No hay datos")
                }
            })
            .catch((erro) => {
                console.log(erro)
            })
            this.modalCreaEcp = false // Cierra modal
            this.showConfirmDialog = false // Cierra modal
            this.modalEditaEcp = false // Cierra modal
            this.modalEditAllEcp = false // Cierra modal
        }
    },
    watch:{
        consulDetallEcp(newVal){
            // console.log(newVal)
            this.detalleEcp = newVal
            let exitCuenta = {}
            if	(this.detalleEcp === undefined) {
			this.detalleEcp = []
            }

            this.detalleEcp.forEach((elem) => {
                // console.log(elem.cuenta)
                if(elem.cuenta != null){
                    // this.listCuentExist.push(elem.cuenta)
                    exitCuenta = {
                        'id_compania' : elem.id_compania,
                        'vigencia' : elem.vigencia,
                        'fila' : elem.fila,
                        'columna' : elem.columna,
                        'cuenta' : elem.cuenta,
                        // 'actual' : elem.actual,
                        // 'operacion' : elem.operacion,
                    }
                    this.listCuentExist.push(exitCuenta)
                }
            })
        }
    }
}
</script>

<style>

</style>
