<template>
  <div class="container">
    <Edit :showDialogEdit="showDialogEdit" @showDialogEdit="showDialogEdit = $event" :ItemProva="dataProva"/>
    <b-row>
      <b-container>
        <h1 class="text-center py-2">
          {{ labels.title }}
        </h1>
      </b-container>
    </b-row>
    <b-row>
      <b-container>
        <h4 class="text-center border-bottom">
          {{ labels.subtitle }}
        </h4>
        <b-col md="8" class="mx-auto">
          <ul>
            <li v-for="(item, idx) in labels.instructive" :key="idx">
              {{ item }}
            </li>
          </ul>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-if="!isLoading">
      <b-container v-if="!isEmpty" class="pb-5">
        <h4 class="text-center">
          {{ labels.info }}
        </h4>
        <b-container>
            <b-col md="10" class="mx-auto"> 
              <b-button @click="goToCreateProvider()" id="create-btn">{{
                  labels.create
                }}</b-button>
            </b-col>
        </b-container>
        <div class="row p-1 justify-content-center">
          <div class="col-md-3">
            <md-field md-inline>
              <label>NII</label>
              <md-input v-model="searchNii" @input="searchOnTableNii"></md-input>
            </md-field>
          </div>
          <div class="col-md-3">
            <md-field md-inline>
              <label>{{labels.name}}</label>
              <md-input v-model="search" @input="searchOnTable"></md-input>
            </md-field>
          </div>
          <div class="col-md-3">
            <md-field md-inline>
              <label>{{labels.departmentName}}</label>
              <md-input v-model="searchDep" @input="searchOnTableDep"></md-input>
            </md-field>
          </div>
        </div>
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col">NII</th>
              <th scope="col">{{labels.name}}</th>
              <th scope="col">{{labels.departmentName}}</th>
              <th scope="col">{{labels.information}}</th>
              <th scope="col">{{labels.edit}}</th>
              <th scope="col">{{labels.inactivate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pro in datosPaginados" :key="pro.id">
              <!-- <th scope="row">{{ pro.id }}</th> -->
              <td>{{ pro.nii }}</td>
              <td>{{ pro.name }}</td>
              <td>{{ pro.departmentName }}</td>
              <td>
                <md-button @click="showInfo(pro.id)" class = "md-raised md-primary btn-info"><md-icon>menu</md-icon></md-button>
              </td>
              <td>
                <md-button @click="editProvider(pro.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
              </td>
              <td>
                <md-button @click="inactivaProvider(pro.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <!-- <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li> -->
            <li v-for="pagina in pages.slice(paginaActual-1, paginaActual+9)" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
        <b-col
          md="6"
          class="mx-auto empty-table pt-4"
          v-if="datosPaginados.length == 0 && inscriptions.length > 0 && !isLoading"
        >
          <h3>{{ labels.notFound }}</h3>
          <p>
            {{ labels.notFoundMessage }}:
            <span>{{ search }}</span>
          </p>
        </b-col>
      </b-container>
      <b-container v-else>
        <b-col md="2" class="mx-auto">
          <div class="success-svg mb-4" :class="{ active: isEmpty }">
            <img src="../../assets/imgs/file-2.svg" alt="warning" />
          </div>
        </b-col>
        <b-col md="12" class="justify-content-around">
          <span id="message" v-if="isEmpty">{{ labels.empty }}</span>
        </b-col>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-col md="12" lg="12" class="p-4 mx-audo">
        <div class="text-center">
          <b-spinner></b-spinner>
        </div>
      </b-col>
    </b-row>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>{{labels.dialog.title}}</md-dialog-title>
      <md-dialog-content  md-dynamic-height>
          <b-row v-for="(entry,idx) in Object.values(dialogItem).slice(1)" :key="idx">
            <b-col>{{labels.dialog.labels[idx]}}</b-col>
            <b-col>{{entry}}</b-col>
          </b-row>
      </md-dialog-content>

      <md-dialog-actions>
        <!-- <md-button class="md-primary" @click="updatePolicy(dialogItem.id)"
          >{{labels.dialog.send}}</md-button
        > -->
        <md-button class="md-accent" @click="showDialog = false"
          >{{labels.dialog.close}}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Provider from "@/services/Provider";
import Edit from "./Edit.vue";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.social + item.name).includes(toLower(term))
    );
  }
  return items;
};
const searchByNii = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.nii).includes(toLower(term))
    );
  }
  return items;
};

const searchByDep = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.departmentName).includes(toLower(term))
    );
  }
  return items;
};
export default {
  name: "List",
  components: {
    Edit
  },
  data: () => ({
    currentSort: "createdAt",
    currentSortOrder: "asc",
    showDialog: false,
    dialogItem: {},
    dataProva: {},
    inscriptions: [],
    searched: [],
    search: null,
    isEmpty: false,
    isUpdating: false,
    isLoading: false,
    success: false,
    error: false,
    filename: "Providers.xls",
    elementosPorPagina:10,
    datosPaginados: [],
    pages:[],
    paginaActual: 1,
    searchNii: null,
    searchDep: null,
    showDialogEdit: false,
  }),
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          title: "Plataforma Para La Visualización De Proveedores",
          subtitle: "Instrucciones",
          instructive: [
            "Bienvenidos a la plataforma para la visualización de proveedores, lo invitamos a revisar la información de cada uno.",
          ],
          info: "Información de Proveedores",
          create: "Crear",
          search: "Buscar por Nombre",
          signupDate: "Fecha Inscripción",
          name: "Nombre / Razón Social",
          information: "Información",
          edit: "Editar",
          inactivate: "Inactivar",
          status: "Estado",
          empty: "Aún no cuentas con Proveedores",
          download: "Descargar",
          notFound: "No Hay Proveedores",
          notFoundMessage: "No hay proveedores con esos datos",
          dialog: {
            title: "Información del proveedor",
            labels: [
              "Concepto",
              "Tipo documento",
              "Número identificación del informado",
              "Razón social informado",
              "Dirección",
              "Código dpto. ",
              "Nombre dpto ",
              "Código mcp ",
              "npmbre mcp ",
              "País de residencia o domicilio ",
              "FECHA SARLAFT No DE CONSULTA",
              "RETEFUENTE (1) ",
              "NOMBRE RETEFUENTE (1) ",
              "RETEFUENTE (2) ",
              "NOMBRE RETEFUENTE (2) ",
              "RETEIVA ",
              "RETEICA (1) ",
              "NOMBRE RETEICA (1) ",
              "RETEICA (2) ",
              "NOMBRE RETEICA (2) ",
              "COD. TRIBUTA(1) ",
              "NOMBRE COD. TRIBUTA (1) ",
              "COD. TRIBUTA(2) ",
              "NOMBRE COD. TRIBUTA (2) ",
              "COD. TRIBUTA(3) ",
              "NOMBRE COD. TRIBUTA (3) ",
              "COD. TRIBUTA(4) ",
              "NOMBRE COD. TRIBUTA (4) ",
              "COD. TRIBUTA(5) ",
              "NOMBRE COD. TRIBUTA (5) ",
              "COD. TRIBUTA(6) ",
              "NOMBRE COD. TRIBUTA (6) ",
              "RTEFTE",
              "RTEFTE",
              "ICA",
              "ICA"],
            send: "Aceptar y enviar correo",
            close: "Cerrar"
          },
          nii: "NII",
          departmentName: "Departamento"
        };
      } else {
        return {
          title: "Providers Platform",
          subtitle: "Instructions",
          instructive: [
            "Welcome to the providers visualization platform. Please check the information for each one",
          ],
          info: "Providers Information",
          create: "CREATE",
          search: "Search by name",
          signupDate: "Registration Date",
          name: "Name / Business Name",
          information: "Information",
          edit: "Edit",
          inactivate: "Inactivate",
          status: "Status",
          empty: "You don't have any providers yet",
          download: "Download",
          notFound: "Not Found",
          notFoundMessage: "There are no providers with that data",
          dialog: {
            title: "Provider Information",
            labels: [
              "Concept",
              "Document Type",
              "Identification number of the informed person",
              "Informed Business Name",
              "Address",
              "Department Code",
              "Department Name",
              "MCP code",
              "MCP name",
              "Country",
              "Date Inquiry SARLAFT",
              "RETEFUENTE (1) ",
              "RETEFUENTE Name (1) ",
              "RETEFUENTE (2) ",
              "RETEFUENTE Name(2) ",
              "RETEIVA ",
              "RETEICA (1) ",
              "RETEICA Name(1) ",
              "RETEICA (2) ",
              "RETEICA Name(2) ",
              "TRIBUTA Code(1) ",
              "TRIBUTA Code Name(1) ",
              "TRIBUTA Code(2) ",
              "TRIBUTA Code Name(2) ",
              "TRIBUTA Code(3) ",
              "TRIBUTA Code Name(3) ",
              "TRIBUTA Code(4) ",
              "TRIBUTA Code Name(4) ",
              "TRIBUTA Code(5) ",
              "TRIBUTA Code Name(5) ",
              "TRIBUTA Code(6) ",
              "TRIBUTA Code Name(6) ",
              "RTEFTE",
              "RTEFTE",
              "ICA",
              "ICA"],
            send: "Accept and send Email",
            close: "Close"
          },
          nii: "NII",
          departmentName: "Departament"
        };
      }
    },
  },
  methods: {
    goToCreateProvider(){
      this.$router.push({path: '/provider/create'})
    },
    showInfo(id) {
      Provider.getProviderById(id)
      .then((result) => {
        this.dialogItem = result.data.data;
        // console.log(this.dialogItem)
        if (!this.dialogItem) {
          this.showDialog = false;
        }else{
          this.showDialog = true;
        }
      })
      .catch(() => {
        this.showDialog = false;
      })
    },
    editProvider(id){
      // console.log("ID"+id)
      this.showDialogEdit = true;
      // const dataProvider = this.inscriptions.findIndex((obj) => obj.id == id);
      // this.dataProva = this.inscriptions[dataProvider];
      // console.log(this.dataProva);

      Provider.getProviderById(id)
      .then((result) => {
        this.dataProva = result.data.data;
        // console.log(this.dataProva)
      })
      .catch((err) => {
        console.log("Error: "+err)
      })

    },
    inactivaProvider(id){
      this.isLoading = true;
      Provider.deleteProviderById(id)
      .then(() => {
        this.isLoading = false;
        this.$toast.success("Poveedor inactivado correctamente")
      })
      .catch((err) => {
        console.log("Error: "+err)
      })
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    searchOnTable() {
      this.searchNii = '';
      this.searchDep = '';
      this.datosPaginados = searchByName(this.inscriptions, this.search);
      if(this.search == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableNii() {
      this.search = '';
      this.searchDep = '';
      this.datosPaginados = searchByNii(this.inscriptions, this.searchNii);
      if(this.searchNii == ''){
        this.getDataPagina(1)
      }
    },
    searchOnTableDep() {
      this.search = '';
      this.searchNii = '';
      this.datosPaginados = searchByDep(this.inscriptions, this.searchDep);
      if(this.searchDep == ''){
        this.getDataPagina(1)
      }
    },
    totalPaginas(){

      let numberOfPages = Math.ceil(this.searched.length / this.elementosPorPagina);
      for (let i = 1; i <= numberOfPages; i++) {
        this.pages.push(i);
      }
      return numberOfPages
      // return Math.ceil(this.searched.length / this.elementosPorPagina)
    },
    showPaginas(){
      let inicio = this.paginaActual;
      let cantReg = 9;  
      // let fin = this.paginaActual - 5;
      let result =  inicio  + cantReg;
      return result;
    },
    getDataPagina(noPagina){
      this.datosPaginados = [];
      this.paginaActual = noPagina;
      let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
      let fin = (noPagina * this.elementosPorPagina);

      this.datosPaginados = this.searched.slice(ini,fin);
    },
    getPreviousPage(){
      if(this.paginaActual > 1){
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage(){
      if(this.paginaActual < this.totalPaginas()){
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active':'';
    }
  },
  mounted() {
    this.isLoading = true;
    Provider.getAll()
      .then((result) => {
        this.inscriptions = result.data.data;
        this.searched = this.inscriptions;
        this.getDataPagina(1);
        if (this.inscriptions.length == 0) {
          this.isEmpty = true;
        }
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  watch:{
    inscriptions(){
      this.totalPaginas()
    }
  },
};
</script>

<style src="../main.css"></style>
