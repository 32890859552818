import Api from '@/services/Api'

export default {
	login (credentials) {
		return Api().post('signin', credentials)
	},
	validate(token){
		return Api().get('validate?t='+token)
	},
	setPassword(form){
		return Api().put('set-account', form)
	},
	resetPassword(form){
		return Api().put('reset-password', form)
	},
	leaParametros(){
		return Api().get('leaParametros')
	},

}
