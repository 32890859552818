<template>
<div class="container">
    <div class="row col-md-12">
        <div class="col-md-1"></div>
        <div class="col-md-4">
            <div class="headNivUno">{{ labels.fila }}</div>
            <div class="bodyNivUno">
                <select name="" id="" class="form-control" v-model="optSelFila">
                    <option value="">Seleccione...</option>
                    <option v-for="fila in listFila" :key="fila.id" :value="fila.fila">{{ fila.descripcion }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="headNivUno">{{ labels.columna }}</div>
            <div class="bodyNivUno">
                <select name="" id="" class="form-control" v-model="optSelCol">
                    <option value="">Seleccione...</option>
                    <option v-for="columna in listColumna" :key="columna.id" :value="columna.columna">{{ columna.descripcion }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            <b-button @click="detailECP()" id="consult-btn">{{ labels.detail }}</b-button>
        </div>
    </div>
    <div class="row mt-4">
        <EcpDetalle v-if="showDetalle" :consulDetallEcp="listDetalleEcp" :ItemVigCom="compVigSelect"/>
    </div>
</div>
</template>
  
<script>

import EcpReporte from '../../services/EcpReporte'
import EcpDetalle from './EcpDetalle.vue'

export default {
    name:"EcpFilaColumna",
    props:['vigCompSel'],
    components:{EcpDetalle},
    data: () => ({
        fila : "",
        columna : "",
        listFila : [],
        optSelFila : "",
        listColumna : [],
        optSelCol : "",
        showDetalle : false,
        listDetalleEcp : [],
        compVigSelect : {}
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    fila : "FILA",
                    columna : "COLUMNA",
                    detail: "Detalle"
                }
            }else{
                return {
                    fila : "ROW",
                    columna : "COLUMN",
                    detail: "Detail"
                }
            }
        }
    },
    methods:{
        async detailECP(){
            if(this.optSelFila == "" && this.optSelCol == ""){
                // console.log(this.optSelFila,this.optSelCol)
                this.$toast.error("Hay campos pendientes por diligenciar")

            }else{
                this.showDetalle = true
                // console.log(this.optSelFila,this.optSelCol)
                // console.log(this.vigCompSel)
                let infoSel = {
                    'vigencia' : this.vigCompSel.vigencia,
                    'companyId' : this.vigCompSel.companyId,
                    'fila' : this.optSelFila,
                    'columna' : this.optSelCol
                }

                
                // let consDet = await EcpReporte.consDetalleEcp(infoSel)
                // console.log(consDet,"ACA")
                EcpReporte.consDetalleEcp(infoSel)
                .then((res) => {
                    // console.log(res)
                    this.listDetalleEcp = res.data.detEcp
                })
                .catch((err) => {
                    if(err.response.status == 404){
                        this.$toast.error(err.response.data.message)
                    }
                })
            }
        }
    },
    mounted(){
        EcpReporte.getAllFila()
        .then((res) => {
            this.listFila = res.data.allFila
        })
        .catch((err) => {
            console.log(err.message)
            this.listFila = []
        })

        EcpReporte.getAllColumna()
        .then((res) => {
            this.listColumna = res.data.allColumn
        })
        .catch((err) => {
            console.log(err.message)
            this.listColumna = []
        })

        this.compVigSelect = {
            'vigencia' : this.vigCompSel.vigencia,
            'companyId' : this.vigCompSel.companyId
        }
    },
    watch:{
        optSelFila(newVal){
            // console.log(newVal)
            this.compVigSelect.fila = newVal
        },
        optSelCol(newVal){
            this.compVigSelect.columna = newVal
        }
    }
}
</script>  
<style src="../main.css"></style>
