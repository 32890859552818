import Api from '@/services/Api'

export default {
    getAllRedefinicionFilas(){
        return Api().get('/redefinicionFila/getAllFilas')
    },
    getAllFilaById(id){
        return Api().get('/redefinicionFila/getAllFilaById/'+id)
    },
    createRedefinicionFila(infoData){
        return Api().post('/redefinicionFila/createRedefinicionFila',infoData)
    },
    updateRedefinicionFila(infoData){
        return Api().put('/redefinicionFila/updateRedefinicionFila',infoData)
    },
    deleteRedefinicionFila(id){
        return Api().get('/redefinicionFila/deleteRedefinicionFila/'+id)
    }
}