<template>
  <div class="container">
    <md-dialog :md-active.sync="modAsociaDesasociaRol" :md-click-outside-to-close="false">
        <!-- <pre>{{ infoMenu[0] }}</pre> -->
        <md-dialog-title class="text-center">
           <b> {{ infoMenu[0].nombre }} </b>
        </md-dialog-title>
        <md-dialog-content>
            <b-row>
                <b-col cols="4"></b-col>
                <b-col cols="6">
                    <h6><md-checkbox v-for="rol in listRoles" :key="rol.id" v-model="rolSelected" :value="rol.id" class="md-primary">{{ rol.nombre }}</md-checkbox></h6>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>
            <!-- {{ rolSelected }} -->
            <md-dialog-actions class="mt-1">
                <md-button class="md-primary" @click="guardarMenu">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="cierraModal">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import Menu from '../../../services/Menu';
import Roles from '../../../services/Roles';

export default {
    name:"AsociaDesasociaRol",
    props:['modAsociaDesasociaRol','infoMenu'],
    data: () => ({
        rolAsociados : [],
        listRoles : [],
        rolSelected: []
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Menu:",
                    save : "Guardar",
                    close : "Cerrar",
                }
            }else{
                return {
                    title: "Menu:",
                    save : "Save",
                    close : "Close",
                }
            }
        }
    },
    mounted(){

        Roles.getAllRoles()
        .then((res) => {
            // console.log(res.data.roles)
            this.listRoles = res.data.roles
        })
        .catch((err) => {
            console.log(err)
        })
        // this.rolSelected = this.rolAsociados.filter(valor => this.listRoles.some(option => option.id === valor));
    },
    methods:{
        cierraModal(){
            // console.log("CierraModal")
            this.$emit('closeModal',false)
        },
        guardarMenu(){
            // console.log("guardarMenu")
            let datos = {
                'idMenu' : this.infoMenu[0].id,
                'rolesAsociados' : this.rolSelected,
            }
            // console.log(datos)

            Menu.updateRolesByMenu(datos)
            .then((res) => {
                // console.log(res)
                if(res.status == 200){
                    this.$toast.success(res.data.message)
                    location.reload();
                }
            })
            .catch((err) =>{console.log(err)})
        }
    },
    watch:{
        infoMenu(dataMenu){
            // console.log(dataMenu)
            let rol = []
            dataMenu.forEach(elem => {
                // console.log(elem)
                rol = elem.roles
            });

            const numbers = [];
            const arrayRol = rol.split(',')
            arrayRol.forEach(element => numbers.push(Number(element)));
            
            numbers.forEach(element => {
                this.rolAsociados.push(element)
            })
            this.rolSelected = numbers.filter(valor => this.listRoles.some(option => option.id === valor));
        }
    }
}
</script>

<style>

</style>