import Api from '@/services/Api'

export default {
    getAllRoles(){
        return Api().get('roles')
    },
    getAllRolesById(id){
        return Api().get('roles/getAllRolById/'+id)
    },
    updateRolById(infoRol){
        return Api().put('roles/update',infoRol)
    },
    createRol(infoRol){
        return Api().post('roles/create',infoRol)
    },
    inactivarRol(id){
        return Api(id).post('roles/inactivar/'+id)
    }
}