<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.newTasaCamb }}
            </md-dialog-title>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="month"
                                name="validity"
                                v-model="form.validity"
                                :placeholder="labels.validity"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <md-select name="currency" v-model="form.idCurrency">
                                <md-option :key="currency.id" :value="currency.id" v-for="currency in listCurrency">{{ currency.nombre }}</md-option>
                            </md-select>
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="closingRate"
                                v-model="form.closingRate"
                                :placeholder="labels.closingRate"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="averageRate"
                                v-model="form.averageRate"
                                :placeholder="labels.averageRate"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <!-- <label>{{ labels.validity }}</label> -->
                            <md-input
                                type="text"
                                name="internalRate"
                                v-model="form.internalRate"
                                :placeholder="labels.internalRate"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="saveTasaCamb">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
    import TasaCambio from "@/services/TasaCambio"
    import Currency from "@/services/Currency"

    export default {
        name: "NewTasaCambio",
        props: ['showDialog'],

        data: () => ({
            form : {
                validity : "",
                idCurrency : "",
                closingRate : "",
                averageRate : "",
                internalRate : "",
            },
            listCurrency : "",
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        newTasaCamb : "Nueva tasa cambio",
                        validity : "Vigencia",
                        closingRate : "Tasa cierre",
                        averageRate : "Tasa promedio",
                        internalRate : "Tasa interna",
                        save : "Guardar",
                        close : "Cerrar"
                    }
                }else{
                    return {
                        newTasaCamb : "New exchange rate",
                        validity : "Validity",
                        closingRate : "Closing rate",
                        averageRate : "Average rate",
                        internalRate : "Internal rate",
                        save : "Save",
                        close : "Close"
                    }
                }
            }
        },
        methods : {
            clicked(){
                this.$emit('clicked', false);
            },
            saveTasaCamb(){
                // console.log(this.form)

                if(this.form.validity == ""){
                    this.$toast.error("Diligenciar el campo Vigencia")

                }else if(this.form.idCurrency == ""){
                    this.$toast.error("Seleccionar una moneda")

                }else if(this.form.closingRate == ""){
                    this.$toast.error("Diligenciar el campo tasa cierre")

                }else if(this.form.averageRate == ""){
                    this.$toast.error("Diligenciar el campo tasa promedio")

                }else if(this.form.internalRate == ""){
                    this.$toast.error("Diligenciar el campo tasa interna")

                }else{
                    this.form.userId = this.$store.state.userId;
                    TasaCambio.createTasaCambio(this.form)
                    .then((res) => {
                        // console.log(res)
                        this.$toast.success(res.data.message)
                        location.reload()
                    }).catch((e) => {
                        console.log(e)
                    })
                }
            }
        },

        mounted(){
            Currency.getAllCurrency()
            .then((res) => {
                this.listCurrency = res.data.currency;
            }).catch((error) => {
                console.log(error)
            })
        }
    }
</script>
<style src="../main.css"></style>