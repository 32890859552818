import Api from '@/services/Api'

export default {
    getAll(){
        return Api().get('provider')
    },
    getProviderById(id){
        return Api().get('provider/'+id)
    },
    createProvider(form){
        return Api().post('provider', form)
    },
    updateProvider(form){
        return Api().post('provider/updated', form)
    },
    deleteProviderById(id){
        return Api().put('provider/'+id+'/delete');
    }
  }
  