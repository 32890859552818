import Api from '@/services/Api'

export default {
    getVigenciaCargue(){
        return Api().get('cargue/vigenciaCargue')
    },
    cargaBalace(form){
        return Api().post('cargue/cargaBalance', form)
    },
    cargaBalaceF2(form){
        return Api().post('cargue/cargaBalanceF2', form)
    },
    cargaBalaceF3(form){
        return Api().post('cargue/cargaBalanceF3', form)
    },
    cargaBalaceF4(form){
        return Api().post('cargue/cargaBalanceF4', form)
    },
    cargaBalaceF5(form){
        return Api().post('cargue/cargaBalanceF5', form)
    },
    cargaBalaceF6(form){
        return Api().post('cargue/cargaBalanceF6', form)
    },
    cargaBalaceF7(form){
        return Api().post('cargue/cargaBalanceF7', form)
    },
    cargaBalaceF8(form){
        return Api().post('cargue/cargaBalanceF8', form)
    },
    cargaBalaceF9(form){
        return Api().post('cargue/cargaBalanceF9', form)
    },
    actualizaNiif(p_vigencia,p_compania){
        return Api().get('actualizaNiif/'+p_vigencia+'/'+p_compania)
    },
}
