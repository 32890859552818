<template>
  <div class="dashboard">
    <!-- <b-container>
      <b-row class="summary">
      </b-row>
    </b-container> -->
  </div>
</template>

<script>

export default {
  name: "List",

  data: () => ({

  }),
  computed: {
    labels() {
      if (this.$store.state._language == "es-ES") {
        return {
          summary: "Resumen"
        };
      } else {
        return {
          summary: "Summary"
        };
      }
    }
  },
  methods: {
    
  },
  mounted() {

  },
};
</script>

<style src="../main.css"></style>
