<template>
  <div>
    <md-dialog :md-active.sync="showDialogEdit">
        <md-dialog-title>{{labels.information}}</md-dialog-title>
        <md-dialog-content>
            <b-col md="8" lg="12" class="p-4 mx-auto">
            <b-form-group @submit.prevent="editInfoProvider">
                <label>{{labels.concept}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.concept"></md-input>
                </md-field><br>

                <label>{{labels.documentType}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.documentType"></md-input>
                </md-field><br>

                <label>{{labels.identificationNumber}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.nii"></md-input>
                </md-field><br>

                <label>{{labels.name}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.name"></md-input>
                </md-field><br>

                <label>{{labels.address}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.address"></md-input>
                </md-field><br>

                <label>{{labels.departmentCode}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.departmentCode"></md-input>
                </md-field><br>

                <label>{{labels.nameDepartment}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.departmentName"></md-input>
                </md-field><br>

                <label>{{labels.mcpCode}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.mcpCode"></md-input>
                </md-field><br>

                <label>{{labels.mcpName}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.mcpName"></md-input>
                </md-field><br>

                <label>{{labels.country}} </label>
                <md-field>
                <md-input type="text" v-model="ItemProva.country"></md-input>
                </md-field><br>

                <label>{{labels.dateSarlaf}}</label>
                <md-field>
                    <md-input type="date" v-model="fechaSarlaf"></md-input>
                </md-field><br>

                <label>{{labels.retefuenteOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.retefuente1"></md-input>
                </md-field><br>

                <label>{{labels.nameRetefuenteOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameRetefuente1"></md-input>
                </md-field><br>

                <label>{{labels.retefuenteTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.retefuente2"></md-input>
                </md-field><br>

                <label>{{labels.nameRetefuenteTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameRetefuente2"></md-input>
                </md-field><br>

                <label>{{labels.reteiva}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.reteiva"></md-input>
                </md-field><br>

                <label>{{labels.reteicaOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.reteica1"></md-input>
                </md-field><br>

                <label>{{labels.nameReteicaOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameReteica1"></md-input>
                </md-field><br>

                <label>{{labels.reteicaTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.reteica2"></md-input>
                </md-field><br>

                <label>{{labels.nameReteicaTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameReteica2"></md-input>
                </md-field><br>

                <label>{{labels.codTributaOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa1"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa1"></md-input>
                </md-field><br>

                <label>{{labels.codTributaTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa2"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa2"></md-input>
                </md-field><br>

                <label>{{labels.codTributaThree}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa3"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaThree}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa3"></md-input>
                </md-field><br>

                <label>{{labels.codTributaFour}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa4"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaFour}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa4"></md-input>
                </md-field><br>

                <label>{{labels.codTributaFive}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa5"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaFive}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa5"></md-input>
                </md-field><br>

                <label>{{labels.codTributaSix}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.tributa6"></md-input>
                </md-field><br>

                <label>{{labels.nameTributaSix}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.nameTributa6"></md-input>
                </md-field><br>

                <label>{{labels.rtefteOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.rtefte1"></md-input>
                </md-field><br>

                <label>{{labels.rtefteTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.rtefte2"></md-input>
                </md-field><br>

                <label>{{labels.icaOne}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.ica1"></md-input>
                </md-field><br>

                <label>{{labels.icaTwo}}</label>
                <md-field>
                    <md-input type="text" v-model="ItemProva.ica2"></md-input>
                </md-field><br>
                
            </b-form-group>
            <md-dialog-actions>
                <md-button class="md-primary" @click="editInfoProvider(ItemProva)">Guardar</md-button>
                <md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
            </md-dialog-actions>
            </b-col>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>

import Provider from "@/services/Provider"; 

export default {
    name: 'Edita',
    props:['showDialogEdit','ItemProva'],
    data(){
        return{
            isLoading:false,
            concepto: '',
            tipoDocumento: '',
            razonSocial: '',
            direccion: '',
            codDepto: '',
            nombreDepto: '',
            codmcp: '',
            nombremcp: '',
            pais: '',
            fechaSarlaf: '',
            retefuenteUno: '',
            nombreRetefuenteUno: '',
            retefuenteDos: '',
            nombreRetefuenteDos: '',
            reteiva: '',
            reteicaUno: '',
            nombreReteicaUno: '',
            reteicaDos: '',
            nombreReteicaDos: '',
            codTributaUno: '',
            nombreTributaUno: '',
            codTributaDos: '',
            nombreTributaDos: '',
            codTributaTres: '',
            nombreTributaTres: '',
            codTributaCuatro: '',
            nombreTributaCuatro: '',
            codTributaCinco: '',
            nombreTributaCinco: '',
            codTributaSeis: '',
            nombreTributaSeis: '',
            rtefteUno: '',
            rtefteDos: '',
            icaUno: '',
            icaDos: '',
        }
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                information: "Información del Proveedor",
                concept: "Concepto",
                documentType: "Tipo de documento",
                identificationNumber: "Número identificación",
                name: "Razón social",
                address: "Dirección",
                departmentCode: "Código del departamento",
                nameDepartment: "Nombre del departamento",
                mcpCode: "Código mcp",
                mcpName: "Nombre mcp",
                country: "País de residencia o domicilio",
                dateSarlaf: "Fecha Sarlaft",
                retefuenteOne: "Retefuente (1)",
                nameRetefuenteOne: "Nombre retefuente (1)",
                retefuenteTwo: "Retefuente (2)",
                nameRetefuenteTwo: "Nombre retefuente (2)",
                reteiva: "Reteiva",
                reteicaOne: "Reteica (1)",
                nameReteicaOne: "Nombre reteica (1)",
                reteicaTwo: "Reteica (2)",
                nameReteicaTwo: "Nombre reteica (2)",
                codTributaOne: "Cod. tributa (1)",
                nameTributaOne: "Nombre cod. tributa (1)",
                codTributaTwo: "Cod. tributa (2)",
                nameTributaTwo: "Nombre cod. tributa (2)",
                codTributaThree: "Cod. tributa (3)",
                nameTributaThree: "Nombre cod. tributa (3)",
                codTributaFour: "Cod. tributa (4)",
                nameTributaFour: "Nombre cod. tributa (4)",
                codTributaFive: "Cod. tributa (5)",
                nameTributaFive: "Nombre cod. tributa (5)",
                codTributaSix: "Cod. tributa (6)",
                nameTributaSix: "Nombre cod. tributa (6)",
                rtefteOne: "Rtefte (1)",
                rtefteTwo: "Rtefte (2)",
                icaOne: "Ica (1)",
                icaTwo: "Ica (2)",
                }
            }else {
                return {
                information: "Provider information",
                concept: "Concept",
                documentType: "Document type",
                identificationNumber: "Identification number",
                name: "Business name",
                address: "Address",
                departmentCode: "Department code",
                nameDepartment: "Department name",
                mcpCode: "mcp code",
                mcpName: "mcp name",
                country: "Country of residence or domicile",
                dateSarlaf: "Date Sarlaft",
                retefuenteOne: "Retefuente (1)",
                nameRetefuenteOne: "Retefuente name (1)",
                retefuenteTwo: "Retefuente (2)",
                nameRetefuenteTwo: "Retefuente name (2)",
                reteiva: "Reteiva",
                reteicaOne: "Reteica (1)",
                nameReteicaOne: "Reteica name (1)",
                reteicaTwo: "Reteica (2)",
                nameReteicaTwo: "Reteica name (2)",
                codTributaOne: "Tributa code (1)",
                nameTributaOne: "Tributa name (1)",
                codTributaTwo: "Tributa code (2)",
                nameTributaTwo: "Tributa name (2)",
                codTributaThree: "Tributa code (3)",
                nameTributaThree: "Tributa name (3)",
                codTributaFour: "Tributa code (4)",
                nameTributaFour: "Tributa name (4)",
                codTributaFive: "Tributa code (5)",
                nameTributaFive: "Tributa name (5)",
                codTributaSix: "Tributa code (6)",
                nameTributaSix: "Tributa name (6)",
                rtefteOne: "Rtefte (1)",
                rtefteTwo: "Rtefte (2)",
                icaOne: "Ica (1)",
                icaTwo: "Ica (2)",
                }
            }
        }
    },
    methods: {
        editInfoProvider(ItemProva){
            // console.log(ItemProva)
            
            Provider.updateProvider(ItemProva)
            .then(() => {
                this.isLoading = true;
                this.$toast.success("Proveedor editado correctamente")
                location. reload()
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.isLoading = false;
            })
        }
    }
}
</script>

<style src="../main.css">
</style>