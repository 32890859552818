<template>
    <div class="container">
        <md-dialog :md-active.sync="showColumna" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.newColumn }}
            </md-dialog-title>
            <md-dialog-content>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <md-input
                                type="number"
                                name="columna"
                                v-model="columna"
                                :placeholder="labels.columna"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="8" lg="7" class="mt-2 mx-auto">
                        <md-field>
                            <md-input
                                type="text"
                                name="columnaName"
                                v-model="columnaName"
                                :placeholder="labels.columnaName"                                
                            />
                        </md-field>
                    </b-col>
                </b-row>
            </md-dialog-content>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="saveColumn">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clickClose">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
  
  <script>

  import EcpReporte from '../../services/EcpReporte'

  export default {
    name:"NewColumna",
    props: ['showColumna'],
    data: () => ({
        columna : '',
        columnaName : '',
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    newColumn: "Agregar Columna",
                    columna: "Columna (Numeros)",
                    columnaName: "Nombre",
                    save: "Guardar",
                    close: "Cerrar"
                }
            }else{
                return {
                    newColumn: "Add Column",
                    columna: "Column (Numbers)",
                    columnaName: "Name",
                    save: "Save",
                    close: "Close"
                }
            }
        }
    },
    methods:{
        clickClose(){
            this.$emit('closeModal',false)
        },
        saveColumn(){
            const dataForm = {
                'columna' : this.columna,
                'nombreColumna' : this.columnaName,
                'idUser' : this.$store.state.userId
            }
            if(this.columna != "" && this.columnaName != ""){

                EcpReporte.insertColumna(dataForm)
                .then((res) =>{
                    // console.log(res)
                    this.$toast.success(res.data.message)
                })
                .catch((err) => {
                    console.log(err)
                    this.$toast.error(err)
                })
                .finally(() => {
                    setTimeout(() => {                        
                        this.reloadTblColumna()
                        this.columna = ""
                        this.columnaName = ""
                    }, 3000);
                })
                
            }else{
                this.$toast.error("Hay campos pendientes por diligenciar")
            }
        },
        reloadTblColumna(){
            this.$emit('reloadTblColumna',false)
        }
    }
  }
  </script>
  
  <style>
  
  </style>