<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div>
            <b-container>
                <b-row id="home-buttons" v-for="subMenu in listaSubMenu" :key="subMenu.id">
                    <b-col md="4" class="p-2" col="3" @click="goToRoute(subMenu.ruta)">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.nombre }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                    <b-col md="8" class="p-2" col="3">
                        <md-card md-with-hover>
                            <md-ripple>
                                <md-card-header>
                                    <div class="md-title-tarjeta">
                                        <span>{{ subMenu.descripcion }}</span>
                                    </div>
                                </md-card-header>
                            </md-ripple>
                        </md-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
import SubMenu from '../../services/SubMenu';
    export default {
        name:"Parametrizacion",

        data: () => ({
            listaSubMenu : []
        }),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "PARAMETRIZACIÓN",
                        coins: "Monedas",
                        exchangeRates: "Tasas de cambio",
                        tasasPatrimonio: "Patrimonio",
                        tasasPatrimoniod: "Tasas de Cambio del Patrimonio",
                        niif: "Niif",
                        chartAccounts: "Plan de cuentas",
                        schemes: "Esquemas",
                        company: "Compañías",
                        levels: "EFE Directo",
                        levelsDirectEfe: "Consolidacion EFE Método Directo ",
                        indirectLevel: "EFE Indirecto",
                        levelIndirectEfe: "Consolidacion EFE Método Indirecto",
                        reporteECP: "Reporte ECP",
                        ajustes: "Ajustes",
                        ajustesd: "Ajustes de Consolidacion",
                        reclasifica: "Reclasifica",
                        reclasificad: "Reclasificacion de Saldos",
                    }
                }else{
                    return {
                        title: "PARAMETRIZATION",
                        coins: "Coins",
                        exchangeRates: "Exchange rates",
                        tasasPatrimonio: "Heritage",
                        tasasPatrimoniod: "Heritage Exchange Rates",
                        niif: "Niif",
                        chartAccounts: "Chart of accounts",
                        schemes: "Schemes",
                        company: "Companies",
                        levels: "Direct Method EFE",
                        levelsDirectEfe: "Direct Method EFE Consolidation",
                        indirectLevel: "Indirect Method EFE",
                        levelIndirectEfe: "Indirect Method EFE Consolidation", 
                        reporteECP: "ECP Report", 
                        ajustes: "Adjustments",
                        ajustesd: "Consolidation Adjustments",
                        reclasifica: "Reclassify",
                        reclasificad: "Reclassify Accounting Statment",
                    }
                }
            }
        },
        methods:{
            goToRoute(page){
                this.$router.push({path: page })
            }
        },
        mounted(){
            /*SubMenu.subMenuByIdMenu(3)
            .then((res) => {
                // console.log(res.data.listSubMenu)
                // console.log(this.$store)
                this.listaSubMenu = res.data.listSubMenu
            })
            .catch((err) => { console.log(err)})*/

            SubMenu.subMenuByMenuByRol(3,this.$store.state.userRole)
            .then((res) => {
                // console.log(res.data.submenus)
                // console.log(this.$store)
                this.listaSubMenu = res.data.submenus
            })
            .catch((err) => { console.log(err)})
        }
    }

</script>
<style src="../main.css"></style>
