<template>
    <div class="container">
        <b-row>
            <b-container>
                <h2 class="text-center">{{labels.title}}</h2>
            </b-container>
        </b-row>
        <b-row>
            <b-container>
                <div class="col-md-12 d-flex justify-content-end">
                    <td>
                        <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="crearSubMenu()">
                            <md-icon>add</md-icon>
                        </md-button>
                    </td>
                </div>
                <div class="col-md-12">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <th scope="col">{{ labels.nombre }}</th>
                            <th scope="col">{{ labels.descripcion }}</th>
                            <th scope="col">{{ labels.menu }}</th>
                            <th scope="col">{{ labels.orden }}</th>
                            <th scope="col">{{ labels.editar }}</th>
                            <th scope="col">{{ labels.inactivar }}</th>
                        </thead>
                        <tbody>
                            <tr v-for="subMenu in listaSubMenu" :key="subMenu.id">
                                <td>{{ subMenu.nombre }}</td>
                                <td>{{ subMenu.descripcion }}</td>
                                <td>{{ nameMenu(subMenu.id_menu) }}</td>
                                <td>{{ subMenu.orden }}</td>
                                <td>
                                    <md-button @click="editSubMenu(subMenu.id)" style="width: 30px; height: 30px;" class = "md-raised md-primary btn-editt">
                                        <md-icon>edit</md-icon>
                                    </md-button>
                                </td>
                                <td>
                                    <md-button style="background-color: #912710; width: 30px; height: 30px;" class="md-fab md-primary" @click="inactivar(subMenu.id)">
                                        <md-icon><font-awesome-icon :icon="['fas', 'trash']" /></md-icon>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-container>
        </b-row>
        <CrearSubmenu :modCrearSubmenu="modCrearSubMenu" @cierraModal="cierraModal" />
        <EditarSubmenu :modEditarSubmenu="modEditarSubMenu" :item="infoSubmenu" @cierraModal="cierraModal" />
        <InacticaItem :InactivaItem="modInactivaSubMenu" @cierraModal="cierraModal" @confirmDelete="inactivaSubMenu"></InacticaItem>
    </div>    
</template>

<script>
import Menu from '../../../services/Menu';
import SubMenu from '../../../services/SubMenu';
import CrearSubmenu from './CrearSubmenu.vue';
import EditarSubmenu from './EditarSubmenu.vue';
import InacticaItem from '../../utils/InacticaItem.vue';

export default {
    name:'submenus',
    data: () => ({
        listaMenu: [],
        listaSubMenu: [],
        modCrearSubMenu: false,
        modEditarSubMenu: false,
        modInactivaSubMenu: false,
        infoSubmenu: [],
        idMenuInac: ""
    }),
    components:{
        CrearSubmenu,
        EditarSubmenu,
        InacticaItem
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : 'Submenus',
                    nombre  : "Nombre",
                    descripcion : "Descripción",
                    menu : "Menú",
                    editar : "Editar",
                    inactivar : "Inactivar",
                    orden : "Orden",
                }
            }else{
                return {
                    title : 'Submenus',
                    nombre  : "Name",
                    descripcion : "Description",
                    menu : "Menu",
                    editar : "Edit",
                    inactivar : "Inactivate",
                    orden : "Order",
                }
            }
        }
    },
    methods:{
        cierraModal(val){
            this.modCrearSubMenu = val
            this.modEditarSubMenu = val
            this.modInactivaSubMenu = val
        },
        nameMenu(idMenu){
            // console.log(idMenu)
            // console.log(this.listaMenu)
            let nombreMenu = this.listaMenu.find((menu) => menu.id == idMenu )
            // console.log(nombreMenu.nombre)
            return nombreMenu.nombre
        },
        crearSubMenu(){
            this.modCrearSubMenu = true
        },
        editSubMenu(id){
            // console.log(id)
            SubMenu.subMenuById(id)
            .then((res) => {
                // console.log(res.data.dataSubMenu)
                this.infoSubmenu = res.data.dataSubMenu
                this.modEditarSubMenu = true
            })
            .catch((err) => console.log(err))
        },
        inactivar(id){
            // console.log(id)
            this.idMenuInac = id
            this.modInactivaSubMenu = true
        },
        inactivaSubMenu(){
            // console.log(this.idMenuInac)
            let info = {'id' : this.idMenuInac}
            SubMenu.inactivaSubmenu(info)
            .then((res) => {
                if(res.status == 200){
                    this.$toast.success(res.data.message)
                    this.modInactivaSubMenu = false
                    location.reload();
                }
            })
            .catch((err) => console.log(err))
        }

    },
    mounted(){
        Menu.getAllMenu()
        .then((res) => {
            this.listaMenu = res.data.listMenu
        })
        .catch((err) => console.log(err))

        SubMenu.getAllSubMenu()
        .then((res) => {
            this.listaSubMenu = res.data.listSubMenu
        })
        .catch((err) => console.log(err))
    }
}
</script>

<style>

</style>
