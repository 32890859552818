<template>
    <div class="container">
        <b-row>
            <b-container>
                <h3 class="text-center py-2" v-if="!generado">
                    <b><i>{{f_titulo()}}</i></b>
		</h3>
                <h4 class="text-center py-2">
			<label>{{labels.codReporte}}</label> &nbsp;&nbsp;
			<select v-model="dialogReportes" @click="isEmpty=true;">
			<option
				v-for="(reporte, idx) in reportes"
				:key="'reporte-'+idx"
				:value="reporte.codigo">
				{{reporte.nombre}}
			</option>
			</select> &nbsp;&nbsp;
			<label>{{labels.fecha_reporte}}</label> &nbsp;&nbsp;
			<select v-model="dialogFechas" @click="isEmpty=true;">
			<option
				v-for="(item, idx) in fechas"
				:key="'fecha-'+idx"
				:value="item.fecha">
				{{item.fecha}}
			</option>
			</select> &nbsp;&nbsp;
			<b-button v-if="dialogFechas" variant="outline-primary" @click="f_generar(0)">
				<h4><b><i>{{labels.generar}}</i></b></h4>
			</b-button> &nbsp;&nbsp;
			<b-button v-if="dialogFechas" variant="outline-primary" @click="f_generar(1)">
				<h4><b><i>{{labels.consultar}}</i></b></h4>
			</b-button> &nbsp;&nbsp;
			<b-button v-if="dialogFechas" variant="outline-primary" @click="f_generar(2)">
				<h4><b><i>{{labels.modificar}}</i></b></h4>
			</b-button>
                </h4>
            </b-container>
        </b-row>
        <b-row>
            <b-container v-if="!isEmpty" class="pb-5">
                <md-table v-model="eliminaciones" md-card md-fixed-header>
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b></h1>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell :md-label="labels.secuencia" class="text-center">{{item.secuencia}}</md-table-cell>
				<md-table-cell :md-label="labels.tipo_ajuste" class="text-left" >{{ f_tipo_ajuste(item.tipo_ajuste) }}</md-table-cell>
				<md-table-cell :md-label="labels.origen_nombre" class="text-left" >{{ item.origen_nombre }}</md-table-cell>
				<md-table-cell :md-label="labels.tipo_origen" class="text-left" >{{ item.tipo_origen }}</md-table-cell>
				<md-table-cell :md-label="labels.destino_nombre" class="text-left" >{{ item.destino_nombre }}</md-table-cell>
				<md-table-cell :md-label="labels.tipo_destino" class="text-left" >{{ item.tipo_destino }}</md-table-cell>
				<md-table-cell v-if="opcion!=2" :md-label="labels.opcion" class="text-center" >
					<md-button @click="f_muestra(item.secuencia,0)" class = "md-raised md-primary btn-info"><md-icon>info</md-icon></md-button>
				</md-table-cell>
				<md-table-cell v-if="opcion==2" :md-label="labels.opcion" class="text-center" >
					<md-button @click="f_muestra(item.secuencia,1)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
				</md-table-cell>
			</md-table-row>
                </md-table>
            </b-container>
        </b-row>
        <b-row>
            <b-container v-if="!isEmpty && muestra">
                  <div class="row mt-12">
			<div class="col-md-12">
				<Origen v-if="listOrigen.length>0"
					@envOrigen="recOrigen" 
					:infoOrigen="listOrigen" 
					:infoOpcion="opcion" 
					:infoEmpresa="empresa_origen" 
					:infoIdCompania="idCompania" 
					:infoEmpresa1="empresa_destino" 
					:infoIdCompania1="idCompania1" 
					:infoTipoAjuste="tipo_ajuste" 
					:infoTipo="tipo_origen" 
					:infoVigencia="vigencia" 
					:infoSecuencia="secuencia"
					@cierraDetalle="cierraDetalle"
					/>
			</div>
			<div class="col-md-12">
				<Destino v-if="listDestino.length>0"
					@envDestino="recDestino" 
					:infoDestino="listDestino" 
					:infoOpcion="opcion" 
					:infoEmpresa="empresa_destino" 
					:infoTipo="tipo_destino" 
					:infoVigencia="vigencia" 
					:infoSecuencia="secuencia"
					@cierraDetalle="cierraDetalle"
					/>
			</div>
                  </div>
            </b-container>
            <b-container v-if="isEmpty">
                <b-col md="2" class="mx-auto">
                    <div class="success-svg mb-4" :class="{ active: isEmpty }">
                        <img src="../../assets/imgs/file-2.svg" alt="warning" />
                    </div>
                </b-col>
                <b-col md="12" class="justify-content-around">
                    <span id="message" v-if="isEmpty">{{labels.empty}}</span>
                </b-col>
            </b-container>
        </b-row>

        <md-dialog :md-active.sync="showDialogEdit">
            <md-dialog-title>{{labels.information}}</md-dialog-title>
            <md-dialog-content>
                <b-col md="8" lg="12" class="p-4 mx-auto">
                    <b-form-group @submit.prevent="editaInfoBalance">
                        <label>{{labels.name}} </label>
                        <md-field>
                            <!-- <label>{{labels.name}} </label> -->
                            <md-input v-model="formEdit.nameBalance" type="text"></md-input>
                        </md-field><br>
                        <label>{{labels.acronym}} </label>
                        <md-field>
                            <!-- <label>{{labels.name}} </label> -->
                            <md-input v-model="formEdit.acronym" type="text"></md-input>
                        </md-field><br>
                        <label>{{labels.url}} </label>
                        <md-field>
                            <!-- <label>{{labels.name}} </label> -->
                            <md-input v-model="formEdit.url" type="text"></md-input>
                        </md-field><br>
                    </b-form-group>
                    <md-dialog-actions>
                        <md-button class="md-primary" @click="editaInfoBalance()">Guardar</md-button>
                        <md-button class="md-accent" @click="showDialogEdit = false">Cerrar</md-button>
                    </md-dialog-actions>
                </b-col>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
import Origen from "./EliminaOrigen.vue"
import Destino from "./EliminaDestino.vue"
import Balance from "@/services/BalanceNiif"


export default {
  name: "BalanceNiif",

  data: () => ({
    rolSeleccionado: {},//<-- el seleccionado estará aquí
    myOptions: [],
    rolSelected: '',
    showDialog: false,
    showDialogEdit: false,
    dialogItem: {},
    eliminaciones: [],
    listOrigen: [],
    listDestino: [],
    empresa_origen: '',
    tipo_ajuste: '',
    tipo_origen: '',
    idCompania: '',
    empresa_destino: '',
    idCompania1: '',
    tipo_destino: '',
    searched: [],
    isEmpty: true,
    isLoading: false,
    error: false,
    formEdit: {
      nameBalance: '',
      acronym: '',
      url: ''
    },
    moneda : "",
    vigencia:'',
    opcion: '',
    modifica: 0,
    muestra: false,
    directo:false,
    indirecto:false,
    companias : [],
    dialogCompanias : [],
    fechas : [],
    reportes : [],
    dialogFechas : [],
    dialogReportes : [],
    columnas: 0,
    titulo_columna: [],
    empresa: 0,
    generado: false,
    nivel: 1,
    titulo: 3,
    valor_ajustar_actual: 0,
    valor_ajustar_funcional1: 0

  }),
  components:{
	Origen,
	Destino
  },
  computed:{
    labels(){
      if (this.$store.state._language == "es-ES") {
        return {
          title: "BALANCE",
          codigo: "Codigo",
          descripcion: "Descripcion",
          saldo_anterior: "Saldo Periodo Anterior",
          saldo_actual: "Saldo Periodo Actual",
          diferencia: "Diferencia",
          monto: "Monto",
          empty: "Sin Seleccion",
          notFoundMessage: "Sin Informacion",
          generar : "Generar",
          modificar : "Modificar",
          consultar : "Consultar",
          ajustes : "Ajustes",
          consolidado : "Consolidado",
          moneda : "Moneda",
          fecha_reporte : "Vigencia",
          secuencia : "Secuencia",
          origen_nombre : "Empresa",
          tipo_ajuste : "Tipo Ajuste",
          tipo_origen : "Tipo",
          destino_nombre : "Empresa",
          tipo_destino : "Tipo",
          opcion : "Opcion",
          codReporte : "Reporte",
        }
      }else {
        return {
          title: "NIIF CHECKING BALANCE",
          codigo: "Code",
          descripcion: "Description",
          saldo_anterior: "Previous Period Balance",
          saldo_actual: "Current Period Balance",
          diferencia: "Difference",
          monto: "Amount",
          compania: "Company",
          empty: "Without Information",
          notFoundMessage: "Without Information",
          generar : "Trigger",
          modificar : "Modify",
          consultar : "Consult",
          ajustes : "Settings",
          consolidado : "Consolidated",
          moneda : "Currency",
          fecha_reporte : "Effective Date",
          secuencia : "Sequence",
          origen_nombre : "Company",
          tipo_ajuste : "Setting type",
          tipo_origen : "Class",
          destino_nombre : "Company",
          tipo_destino : "Class",
          opcion : "Choice",
          codReporte : "Report",
        }
      }
    }
  },
  methods: {
	f_titulo() {
		let titulo = ""
		let tituloEN = ""
		let tituloConsolidado = ""
		titulo = "ELIMINACION DE SALDOS "
		tituloEN = "ELIMINATION OF ACCOUTING ITEMS"
		if	(this.$store.state._language == "es-ES") {
			tituloConsolidado = titulo
		} else	{
			tituloConsolidado = tituloEN
		}
		switch ( this.titulo ) {
		case	0 : tituloConsolidado = tituloConsolidado+
				" ["+this.labels.generar.toUpperCase()+" ]";break
		case	1 : tituloConsolidado = tituloConsolidado+
				" ["+this.labels.consultar.toUpperCase()+" ]";break
		case	2 : tituloConsolidado = tituloConsolidado
				+" ["+this.labels.modificar.toUpperCase()+" ]";break
		}
		return tituloConsolidado
	},
	f_muestra(secuencia,modifica) {
		this.muestra=true
		this.secuencia=secuencia
		let objEliminaciones = this.eliminaciones.find(
			(obj) => obj.secuencia === secuencia)
		this.empresa_origen=objEliminaciones.origen_nombre
		this.idCompania=objEliminaciones.origen
		this.tipo_ajuste=objEliminaciones.tipo_ajuste
		this.tipo_origen=objEliminaciones.tipo_origen
		this.empresa_destino=objEliminaciones.destino_nombre
		this.idCompania1=objEliminaciones.destino
		this.tipo_destino=objEliminaciones.tipo_destino
		this.modifica=modifica
		this.recOrigen()
		this.recDestino()
	},
	f_generar(opcion) {
		this.opcion=''
		if	(this.dialogFechas) {
			this.muestra=false
			this.vigencia=this.dialogFechas
			this.isEmpty=true
			this.generado=false
			Balance.getEliminaciones(this.dialogReportes,this.vigencia,opcion)
			.then((result) => {
				this.eliminaciones = result.data.eliminaciones[0];
				this.searched = this.eliminaciones;
				if (this.eliminaciones.length != 0) {
					this.isEmpty = false;
					this.generado=true
					this.opcion=opcion
				}
			})
			.catch(() => {
				this.error = true;
			});
		}
	},
	seleccionaCompania() {
		this.isEmpty=true;
		let buscaMoneda = this.companias.filter((item) =>
			item.id==this.dialogCompanias)
			this.moneda = buscaMoneda[0].acronimo
	},
	formato(numero) {
		return  new Intl.NumberFormat('en-US',{ maximumFractionDigits: 0}).format(numero)
	},
	recOrigen(){
		Balance.getEliminaOrigen(this.vigencia,this.secuencia)
		.then((res) => {
			this.listOrigen = res.data.listEliminaOrigen[0]
		})
		.catch((err) => {
			console.log(err)
		})
	},
	recDestino(){
		Balance.getEliminaDestino(this.vigencia,this.secuencia)
		.then((res) => {
			this.listDestino = res.data.listEliminaDestino[0]
		})
		.catch((err) => {
			console.log(err)
		})
	},
	cierraDetalle() {
		this.muestra = false
	},
	f_tipo_ajuste(tipo) {
		let tipo_ajuste = ""
		switch (tipo) {
		case	1 : tipo_ajuste = "Interes no Controlante";break;
		case	2 : tipo_ajuste = "Eliminacion en Inversion";break;
		case	3 : tipo_ajuste = "Eliminacion Saldos Reciprocos";break;
		default	: tipo_ajuste = "Tipo sin identificar";break;
		}
		return tipo_ajuste
	}
  },
    
mounted() {
    Balance.getFechas()
    .then ((result) => {
	this.fechas = result.data.fechas
	this.dialogFechas = []
	result.data.fechas.forEach(element => {
		this.dialogFechas.push(element.fecha);
	})
    })
    .catch(() => {
	this.error = true
    });
	Balance.getReportes()
	.then ((result) => {
		this.reportes = result.data.reportes
	})
	.catch(() => {
		this.error = true
	});
    this.isLoading = true;
  },

};
</script>

<style src="../main.css"></style>
