import Api from '@/services/Api'

export default {
    getAllLevelSecond(codReporte){
        return Api().get('getAllLevelSecond'+'/'+codReporte)
    },
    getAllLevelSecondByLevelFirstByReport(primer_nivel,reporte){
        return Api().get('getAllLevelSecondByLevelFirstByReport/'+primer_nivel+'/'+reporte)
    },
    createSecondLevel(form){
        return Api().post('secondLevel/create',form)
    },
    updateSegundoNivel(form){
        return Api().post('secondLevel/update',form)
    },

    getSecondLevelById(primer_nivel,segundo_nivel){
        return Api().get('getSecondLevelById/'+ primer_nivel+'/'+segundo_nivel)
    },
}
