<template>
    <div class="container">
        <div class="d-flex justify-content-end">
            <!-- <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="editVariasCuentas()">
                <md-icon>edit</md-icon>
            </md-button> -->
            <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaNivUno()">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="headNivUno">{{ labels.levelOne }}</div>
        <div class="bodyNivUno">
            <select name="" id="" class="form-control" v-model="optSelected">
                <option value="">{{ labels.select }}</option>
                <option v-for="nivel in listNivelUno" :key="nivel.id" :value="nivel.nivel_1">{{ nivel.nivel_1 }}. {{ nivel.descripcion }}</option>
            </select>
        </div>
        <CreaNivelUnoVue :showCreaNivUno="showCreaNivUno" :optSelNivUno="optSelected" @closeModal="cierraModal" @refresNivelUno="refrescaSel"></CreaNivelUnoVue>
    </div>
</template>

<script>

import Nivel from '../../services/Nivel'
import CreaNivelUnoVue from './CreaNivelUno.vue'

export default {
    name:"NivelUno",
    data: () => ({
        listNivelUno : [],
        optSelected : "",
        showCreaNivUno : false
    }),
    components:{
        CreaNivelUnoVue
    },
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Niveles EFE",
                    levelOne: "Nivel I",
                    select: "Seleccione..."
                }
            }else{
                return {
                    title: "Levels EFE",
                    levelOne: "Level I",
                    select: "Select..."
                }
            }
        }
    },
    methods:{
        goToRoute(page){
            this.$router.push({path: page })
        },
        creaNivUno(){
            this.showCreaNivUno = true
        },
        cierraModal(val){
            this.showCreaNivUno = val
        },
        refrescaSel(valRuta){

            if(valRuta == "directo"){

                this.showCreaNivUno = false
    
                Nivel.getAllNivelUno()
                .then((res)=>{
                    // console.log(res.data.nivelUnoEfe)
                    this.listNivelUno = res.data.nivelUnoEfe
                })
                .catch((err) => {
                    console.log(err)
                })
            
            }else{

                this.showCreaNivUno = false
    
                Nivel.getAllNivelUnoInd()
                .then((res)=>{
                    // console.log(res.data.nivelUnoEfe)
                    this.listNivelUno = res.data.nivelUnoEfe
                })
                .catch((err) => {
                    console.log(err)
                })
            }

        }
    },
    mounted(){
        // console.log(this.$route.params.tipo)

        if(this.$route.params.tipo == 'directo'){

            Nivel.getAllNivelUno()
            .then((res)=>{
                // console.log(res.data.nivelUnoEfe)
                this.listNivelUno = res.data.nivelUnoEfe
            })
            .catch((err) => {
                console.log(err)
            })
        
        }else{

            Nivel.getAllNivelUnoInd()
            .then((res)=>{
                // console.log(res.data.nivelUnoEfe)
                this.listNivelUno = res.data.nivelUnoEfe
            })
            .catch((err) => {
                console.log(err)
            })
        }
    },
    watch:{
        optSelected(levelUno){
            // console.log(val)
            this.$emit('envLevUno',levelUno)
        }
    }
}
</script>

<style src="../main.css">

</style>
